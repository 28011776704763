import { Box } from "@mui/material";
import { IIconsProps } from "./icons.model";

const MoneyPlus = (props: IIconsProps) => {
    return (
        <Box sx={{ position: 'relative', left: props.left ? props.left : '-20px' }}>
            <i id={`${props.id}_0`} className="fa-solid fa-money-bill-1-wave" style={{ fontSize: 50, height: '70px', color: props.color }} />
            <i id={`${props.id}_1`} className="fa-solid fa-circle-plus" style={{ fontSize: 25, color: props.color, position: 'absolute', right: '-10px', bottom: '10px' }} />
        </Box>
    );
};

export default MoneyPlus;

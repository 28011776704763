import { AccountCircle, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, CircularProgress, TextField, Typography } from '@mui/material';
import axios, { AxiosHeaders } from 'axios';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { IToken } from 'model/token.model';
import { ConfiguracaoDescontoTO } from 'pages/Configuracoes/Configuracoes.model';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setCaixa } from 'redux/features/caixaSlice';
import { setConfiguracaoDesconto } from 'redux/features/configuracoes';
import { setEsSelecionado } from 'redux/features/esSelecionadoSlice';
import { setToken } from 'redux/features/tokenSlice';
import { useAppDispatch } from 'redux/hooks';
import StatusServicoService from 'service/status-servico.service';
import { toNexus } from '../../../src/axios/axios.config';
import LoginService from './login.service';
import './login.style.css';

const verde = 'rgb(190, 255, 196)';

const inputLabelProps = {
    sx: {
        color: '#dfdfdf',
        '&.MuiInputLabel-root': {
            '&.Mui-focused': {
                color: verde
            },
            '&.Mui-disabled': {
                color: '#adadad'
            }
        }
    }
};

const inputProps = {
    sx: {
        color: '#e3e3e3',
        '.Mui-disabled': {
            '-webkit-text-fill-color': '#adadad !important',
        },
        '&:before': {
            borderBottomColor: '#dfdfdf',
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottomColor: '#dfdfdf',
        },
    },
    spellCheck: false
};

const Login = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { addError } = useSnackbarCustom();

    const loginService = LoginService();
    const statusServicoService = StatusServicoService();
    const userRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const [login, setLogin] = useState({
        user: '',
        password: '',
    });

    const [currentVersion, setCurrentVersion] = useState('');

    useEffect(() => {
        userRef.current?.focus();
        statusServicoService.getCurrentVersion()
            .then(({ data }) => {
                return setCurrentVersion('v' + data.replace('-SNAPSHOT', ''));
            })
            .catch(() => { });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appDispatch = useAppDispatch();

    const getConfiguracaoDescontoUser = useCallback((username: string, token: IToken) => {
        const headers = new AxiosHeaders();
        headers.set('access_token', token.access_token);
        headers.set('es', token.ESTABELECIMENTOS[0].uuid);

        const baseURL = `${toNexus(window.location.host, window.location.hostname)}`;

        axios.get(`${baseURL}/configuracaodesconto/nome/${username}`, { headers: headers })
            .then(({ data }: { data: ConfiguracaoDescontoTO; }) => {
                dispatch(setConfiguracaoDesconto(data));
            })
            .catch((err) => {
                console.log(err);
            });
        //eslint-disable-next-line
    }, []);



    const getSaldoCaixa = (token: IToken) => {
        const headers = new AxiosHeaders();
        headers.set('access_token', token.access_token);
        headers.set('es', token.ESTABELECIMENTOS[0].uuid);

        const baseURL = `${toNexus(window.location.host, window.location.hostname)}`;
        let caixaGlobal = {
            uuid: ''
        };

        axios.get(`${baseURL}/flow/caixadisponivel`, { headers: headers })
            .then(({ data }) => {
                const caixa = data.find((e: any) => e.caixaAberto && e.operadorAtual === token.USER_INFO?.username);
                if (!!caixa) {
                    caixaGlobal = caixa;
                    axios.get(`${baseURL}/flow/saldocaixa/${caixa.uuid}`, { headers: headers })
                        .then(({ data }) => {
                            appDispatch(setCaixa({
                                caixa: data.caixa,
                                contaSelecionada: null,
                                saldo: data.saldo,
                                dataAbertura: data.dataAbertura,
                                caixaAberto: data.caixaAberto
                            }));
                        })
                        .catch(error => console.log(error));
                }
            })
            .catch(error => {
                console.log(error);
                if (error.response.data.code === "ProibidoException") {
                    addError({
                        message: 'Não foi possível fazer login. Tente novamente.',
                        closeable: true,
                        preventDuplicate: false,
                    });
                }
            })
            .finally(() => {
                if (caixaGlobal.uuid) {
                    return navigate('/registra-venda');
                }
                return navigate('/caixa');
            });
    };

    const onSubmitLogin = (e: any) => {
        e.preventDefault();
        setShowPassword(false);
        setLoading(true);
        loginService.loga(login)
            .then(
                ({ data }) => {
                    dispatch(setToken(data));
                    getSaldoCaixa(data);
                    dispatch(setEsSelecionado(data.ESTABELECIMENTOS[0]));
                    getConfiguracaoDescontoUser(login.user, data);
                })
            .catch(error => {
                addError({
                    message: 'Não foi possível fazer login. Tente novamente.',
                    closeable: true,
                    preventDuplicate: false,
                });
            })
            .finally(() => {
                setLoading(false);
                setTimeout(() => {
                    userRef.current?.focus();
                },);
            });
    };

    const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setLogin({ ...login, [target.name]: target.value });
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        if (!loading) {
            setShowPassword(!showPassword);
        }
    };

    return (
        <Box sx={{
            height: '100vh',
            width: '100%',
            overflow: 'hidden',
            display: 'grid',
            placeItems: 'center',
        }}>
            <img id='bg' src="assets/bg.png" alt="Background" />

            <Box id='wraper'
                sx={{
                    zIndex: '2',
                    display: 'grid',
                    gridTemplateColumns: { md: '1fr', lg: '5fr 3fr' },
                    width: { sm: '65%', xs: '100%' },
                    height: { sm: '50%', xs: '100%' },
                    backgroundColor: { xs: 'rgba(255, 255, 255, 0.05)', sm: 'transparent' },
                    backdropFilter: { xs: 'blur(15px)', sm: 'none' },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignSelf: { xs: 'flex-end', sm: 'center' },
                    justifySelf: { lg: 'flex-start', xs: 'center' }
                }}>
                    <Typography
                        variant='h1'
                        id='nome'
                        fontFamily={'Work Sans, sans-serif'}
                        sx={{
                            fontSize: 'clamp(3.5rem, 6vw, 6.5rem)',
                            fontWeight: 300,
                            color: verde,
                        }}
                    >
                        peōn<span>Flow</span>
                    </Typography>
                </Box>

                <Box
                    sx={{
                        background: { xs: 'transparent', sm: 'rgba(255, 255, 255, 0.05)' },
                        boxShadow: { xs: 'none', sm: '0 8px 32px 0 rgba(0, 0, 0, 0.015)' },
                        backdropFilter: { xs: 'none', sm: 'blur(15px)' },
                        borderRadius: '15px',
                        padding: '50px',
                        maxWidth: '370px',
                        width: { xs: '80%', sm: '100%' },
                        minWidth: 'px',
                        justifySelf: { lg: 'flex-start', xs: 'center' },
                        alignSelf: { xs: 'flex-start', sm: 'center' },
                    }}>
                    <form onSubmit={onSubmitLogin}>
                        <Typography
                            variant="h3"
                            color={verde}
                            fontWeight={'500'}
                            width={'100%'}
                            fontSize={'clamp(2rem, 3.6vw, 3rem)'}
                            textAlign={{ xs: 'center', sm: 'left' }}
                        >
                            Bem-vindo!
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <AccountCircle sx={{ color: 'var(--laranja)', mr: 1, my: 1.5 }} />
                            <TextField
                                id="input-user"
                                label="Nome de usuário"
                                InputLabelProps={inputLabelProps}
                                name='user'
                                variant="standard"
                                color='warning'
                                margin="normal"
                                fullWidth
                                value={login.user}
                                disabled={loading}
                                onChange={onChange}
                                inputRef={userRef}
                                InputProps={inputProps}
                                autoComplete='username'
                            />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            {
                                showPassword
                                    ? <Visibility
                                        sx={{ color: 'var(--laranja)', mr: 1, my: 1.5, cursor: 'pointer' }}
                                        onClick={togglePasswordVisibility}
                                    />
                                    : <VisibilityOff
                                        sx={{ color: 'var(--laranja)', mr: 1, my: 1.5, cursor: 'pointer' }}
                                        onClick={togglePasswordVisibility} />
                            }
                            <TextField
                                id="input-password"
                                label="Senha"
                                InputLabelProps={inputLabelProps}
                                name='password'
                                variant="standard"
                                color='warning'
                                margin="normal"
                                InputProps={{ ...inputProps, sx: { ...inputProps.sx, color: showPassword ? '#e3e3e3' : verde, } }}
                                fullWidth
                                value={login.password}
                                onChange={onChange}
                                disabled={loading}
                                type={showPassword ? 'text' : 'password'}
                                autoComplete='current-password'
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-end',
                            marginBottom: '4px',
                            position: 'relative',
                            marginTop: { xs: '50px', sm: 0 }
                        }}>
                            <CustomButton
                                variant="contained"
                                color="warning"
                                disabled={loading}
                                type='submit'
                                id='login-btn'
                            >
                                <img id='logo' src="flow-icon-white-512.png" style={{ filter: loading ? 'invert(1) contrast(0.7) opacity(0.3)' : 'none' }} alt='flow-logo-icon-entrar' />
                                Entrar
                            </CustomButton>
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    color='warning'
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </form>
                </Box>
            </Box>

            <Typography
                sx={{
                    color: '#999',
                    userSelect: 'none',
                    fontSize: '12px',
                    position: 'absolute',
                    bottom: '0',
                    right: { sx: '50%', sm: '5px' },
                    transform: { sx: 'translateX(50%)', md: 'none' },
                    zIndex: 1000,
                }}
            >
                {currentVersion}
            </Typography>
        </Box >
    );
};

export default Login;

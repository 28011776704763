import CampaignIcon from '@mui/icons-material/Campaign';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import React from 'react';

export type StateRejeicaoNotaDialog = {
    open: boolean;
    info: {
        entradaSaida: 'ENTRADA' | 'SAIDA';
        eventoDescricao: string;
        serie: string;
        numero: string;
        emissao: string;
        situacaoSefaz: string;
        chaveAcesso: string;
    };
};
type Props = {
    state: StateRejeicaoNotaDialog;
    setState: React.Dispatch<React.SetStateAction<StateRejeicaoNotaDialog>>;
    onClose: (navigate: boolean) => void;
};

const RejeicaoNotaFiscal = (props: Props) => {

    const onClose = () => {
        props.onClose(true);
        props.setState(prev => ({ ...prev, open: false }));
    };

    return (
        <CustomDialog
            id="desconto-fixo-dialog"
            open={props.state.open}
            fullWidth
            maxWidth={'lg'}
            onClose={onClose}
        >

            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#e06665',
                    gap: '15px',
                    borderRadius: '15px',
                    marginBottom: '30px'
                }}
            >
                <CampaignIcon sx={{ fontSize: '64px', color: '#fff' }} />
                <Typography
                    component={'h1'}
                    sx={{
                        fontSize: 'clamp(20px, 3vw, 32px)',
                    }}
                    textAlign={'center'}
                    color={'#fff'}
                    fontWeight={700}
                >
                    Venda concluída com rejeição do documento fiscal de {props.state.info.entradaSaida === 'ENTRADA' ? 'entrada' : 'saída'}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ height: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'flex-start' }}>
                <Typography fontWeight={700} color={'#555'}>Rejeicao: {props.state.info.eventoDescricao}</Typography>
                <Typography>Serie: {props.state.info.serie}</Typography>
                <Typography>Número: {props.state.info.numero}</Typography>
                <Typography>Data de emissão: {new Date(props.state.info.emissao).toLocaleString('pt-BR')}</Typography>
                <Typography>Situação na SEFAZ: {props.state.info.situacaoSefaz}</Typography>
                <Typography>Chave de acesso: {props.state.info.chaveAcesso}</Typography>
            </DialogContent>
        </CustomDialog>
    );
};

export default RejeicaoNotaFiscal;

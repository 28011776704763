import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material"
import { useCallback } from "react"
import { IStatusProps } from "../vendas.model"
import { useSnackbarCustom } from "hooks/useSnackCustom"


const Status = (props: IStatusProps) => {
    const { addError } = useSnackbarCustom();

    const handleChange = useCallback((e: any) => {
        const target = e.target.value
        if (!target.length) {
            addError({ message: "O campo status deve ter pelo menos 1 valor selecionado", closeable: true })
            return;
        }
        props.onChangeStatus(target)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FormControl variant="outlined"
            sx={{
                width: '30%', height: '55px'
            }}>
            <InputLabel variant="standard" id="labelTipo" >Status do pedido</InputLabel>
            <Select id="selectStatusVendas"
                multiple
                sx={{
                    width: '100%',
                    height: '100%'
                }}
                variant="standard"
                label="Tipo"
                value={props.statusValues}
                renderValue={(selected) => selected.join(', ')}
                onChange={handleChange}
            >
                {props.statusOptions.map((e, i) => {
                    return (
                        <MenuItem
                            key={i}
                            value={e}
                            id={`menu_item_status_vendas_${e}`}
                        >
                            <ListItemText primary={e} />
                            <Checkbox
                                sx={{ color: 'var(--laranja)' }}
                                checked={props.statusValues.indexOf(e) > -1}
                                color="warning"
                            />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
}
export default Status
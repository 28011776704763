import { IAbstractModel } from "model/abstract.model";

export interface IModalCaixa {
    icon: React.ReactElement | React.ReactElement[],
    title: string,
    value?: number,
    open: boolean,
    dataFechamento?: boolean,
    caixaTitle: string,
    caixaItens: String[],
    onCancel?: () => void,
    onConfirm?: () => void,
}

export interface ICaixasDisponivel extends IAbstractModel {
    caixaAberto?: boolean,
    operadorAtual?: string | null;
    saldo: number;
}

export interface IMovimentoCaixa {
    saldo: number,
    caixaAberto?: boolean,
    planoConta: IAbstractModel | null,
    caixa: ICaixasDisponivel,
    observacao?: string,
    tipoOperacao: Operacao,
    dataAbertura?: string;
}

export enum Operacao {
    ABERTURA = 'ABERTURA',
    REFORCAR = 'REFORCAR',
    SANGRAR = 'SANGRAR',
    FECHAMENTO = 'FECHAMENTO'
}

export enum TipoDialog {
    ABERTURA = 'Abertura de caixa',
    REFORCAR = 'Reforçar caixa',
    SANGRAR = 'Sangrar caixa',
    FECHAMENTO = 'Fechamento de caixa'

}

export enum TipoGrupoConta {
    RECEITA = 'RECEITA',
    DESPESA = 'DESPESA',
    OUTRAS = 'OUTRAS'
}

export type TResgateRelatorioTO = {
    caixa: Array<string>;
    dataInicial: string;
    dataFechamento: string;
    tipo: TResgateTipo;

};

export enum TResgateTipo {
    ABERTURA = "ABERTURA",
    FECHAMENTO = "FECHAMENTO"
}

export enum TFechamentoStatus {
    ABERTO = "ABERTO",
    FECHADO = "FECHADO"
}

export type TRelatorioFechamentoCaixa = {
    caixa: string;
    usuario: string;
    dataAbertura: string;
    dataFechamento: string;
    status: TFechamentoStatus;
    uuidAbertura: string;
    uuidFechamento: string | null;
    id: number;
};


export type ModalCaixaProps = Array<IModalCaixa>;

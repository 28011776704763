import { AxiosResponse } from "axios";
import { axiosRequest } from "../../axios/axios.config";
import { IAbstractModel } from "model/abstract.model";
import LoginService from "pages/login/login.service";


const PagamentoService = () => {
    const path = '/flow';
    const loginService = LoginService();

    return {
        getFormasPagamento(): Promise<AxiosResponse<Array<IAbstractModel>>> {
            return axiosRequest.get(`${path}/formaspagamento`, { headers: loginService.getHeaders() });
        },
        getBancos(): Promise<AxiosResponse<Array<IAbstractModel>>> {
            return axiosRequest.get(`${path}/bancos`, { headers: loginService.getHeaders() });
        }
    };
};

export default PagamentoService;

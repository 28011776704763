import { Button, ButtonProps, styled } from "@mui/material";
import { CSSProperties } from "react";

export const StyledCustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    borderRadius: '5px',
    padding: '10px 20px',
    width: 'fit-content',
}));

type Props = {
    animated?: boolean;
    containerStyle?: CSSProperties;
};

export const CustomButton = (props: Props & ButtonProps) => {

    const getButtonProps = () => {
        const buttonProps = { ...props };
        delete buttonProps.animated;
        return buttonProps;
    };

    return (
        <>
            {props.animated ?
                <div className="animated-button" style={props.containerStyle}>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                    <StyledCustomButton {...getButtonProps()} />
                </div>
                :
                <StyledCustomButton {...getButtonProps()} />
            }
        </>
    );
};

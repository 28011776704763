import { DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, Icon, TextField, Typography } from '@mui/material';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useFormValidator, useValidators } from 'hooks/useFormValidator';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import React, { FormEvent, useEffect, useState } from 'react';
import { Tipo, completeLoading, loadingWithOpt } from 'redux/features/blockuiSlice';
import { useAppDispatch } from 'redux/hooks';
import VendaService from '../venda.service';
import { ItemRelatorioVendas } from '../vendas.model';

type Props = {
    onClose: () => void;
    itemRef: React.MutableRefObject<ItemRelatorioVendas | null>,
    onAtualizarRelatorio: () => void;
};

const initialDialogState = {
    open: false,
    submitted: false,
    data: {
        serie: '',
        numeroNotaFiscal: 0,
        cliente: '',
    }
};

const CancelaDFeDialog = (props: Props) => {

    const service = VendaService();
    const dispatch = useAppDispatch();
    const { addSuccess, addStack } = useSnackbarCustom();
    const [justificativaStateDialog, setJustificativaStateDialog] = useState(initialDialogState);
    const { minSize } = useValidators();
    const { hasError, getRawValue, formIsValid, resetControl, setControlValue, } =
        useFormValidator<{ justificativa: string | null; }>({
            controls: [{ inputName: 'justificativa', value: '', nonNullable: true, validators: [minSize(15)] },]
        });

    useEffect(() => {
        onInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onInit = () => {
        const current = props.itemRef.current;
        if (!current) throw new Error('nenhum item selecionado');
        const { serie, numeroNotaFiscal, cliente } = current;
        setJustificativaStateDialog({
            open: true,
            submitted: false,
            data: { serie, numeroNotaFiscal, cliente }
        });
    };

    const onCloseDialog = () => {
        props.onClose();
        setJustificativaStateDialog(initialDialogState);
        resetControl('justificativa');
    };

    const onConfirmDialog = (e: FormEvent) => {
        e.preventDefault();
        setJustificativaStateDialog(prev => ({ ...prev, submitted: true }));
        if (!formIsValid()) return;

        dispatch(loadingWithOpt({
            loading: true,
            tipo: Tipo.CIRCULAR,
            zIndexCustom: 1000
        }));

        service.cancelarDFe(props.itemRef.current!.chaveAcesso, getRawValue('justificativa'), 'VENDA')
            .then(({ data }) => {
                addSuccess({ message: data, persist: false, });
                onCloseDialog();
            })
            .catch(err => {
                const erros = err.response.data.extra.map((s: string) => ({
                    message: s,
                    persist: false,
                    hideDuration: 5000,
                    closeable: true,
                    preventDuplicate: true
                }));
                addStack(erros);
            })
            .finally(() => {
                props.onAtualizarRelatorio();
                dispatch(completeLoading());
            });
    };

    return (
        <CustomDialog
            id="justificativa-dialog"
            open={justificativaStateDialog.open}
            onClose={onCloseDialog}
            fullWidth={true}
            maxWidth={'sm'}
        >

            <form onSubmit={onConfirmDialog} style={{ gap: 0 }}>
                <DialogTitle id="dialog-title" textAlign={'center'}>
                    <Typography fontSize={30} paddingTop={0}>Cancelar {props.itemRef.current?.modelo}</Typography>
                </DialogTitle>

                <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <Typography>Série:</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography>{justificativaStateDialog.data.serie}</Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography>Número:</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography>{justificativaStateDialog.data.numeroNotaFiscal}</Typography>

                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Typography>Cliente:</Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography>{justificativaStateDialog.data.cliente}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth variant="standard">
                                <TextField
                                    label="Justificativa *"
                                    id={`justificativa-input`}
                                    fullWidth
                                    variant="standard"
                                    value={getRawValue('justificativa')}
                                    onChange={(e) => {
                                        setControlValue('justificativa', e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                        e.stopPropagation();
                                    }}
                                    error={justificativaStateDialog.submitted && hasError('minSize15', 'justificativa')}
                                />

                                {justificativaStateDialog.submitted && hasError('minSize15', 'justificativa') &&
                                    <FormHelperText
                                        className='Mui-error'
                                        id={`justificativa-helper-text`}
                                        color={'error'}
                                    >
                                        Valor inválido
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <CustomButton onClick={onCloseDialog} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Fechar
                    </CustomButton>
                    <CustomButton type="submit" disabled={false} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                        Confirmar
                    </CustomButton>
                </DialogActions>
            </form>
        </CustomDialog >
    );
};

export default CancelaDFeDialog;

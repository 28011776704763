import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs";
import { memo, useCallback } from "react";
import { TDataVendaProps } from "../vendas.model";

const Data = (props: TDataVendaProps) => {
    const handleMaxDate = () => {
        if (props.title === "Data Inicio") {
            if (new Date(props.dataFim) > new Date(props.dataInicio)) { return dayjs(new Date(props.dataFim)) }
        }
        return dayjs(new Date().toString())
    }

    const handleMinDate = () => {
        if (props.title === "Data Fim") {
            return dayjs(new Date(props.dataInicio).toString())
        }
    }

    const onChangeTextField = useCallback((e: any) => {
        if (!e) return;

        if (e.$D > 0 && e.$y > 0 && e.$d > 0) {
            props.onChangeData(props.state, e.$d)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <FormControl sx={{
            width: '15%', height: '50px', marginTop: '5px'
        }}>
            <DatePicker
                maxDate={handleMaxDate()}
                minDate={handleMinDate()}
                slotProps={{
                    textField: {
                        id: `${props.title}_vendas`,
                        variant: 'standard',
                        onChange: onChangeTextField,
                    }
                }}
                label={props.title} format='DD/MM/YYYY'
                sx={{
                    width: '100%', height: '100%'
                }}
                value={dayjs(props.dataValue)}
                onChange={(e: any) => e?.$d ? props.onChangeData(props.state, e.$d.toString()) : new Date().toString()}
            />
        </FormControl >
    )
}
export default memo(Data)
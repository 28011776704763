import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../../axios/axios.config";
import { ConfiguracaoValePresente } from "./configuracaovalepresente.model";

const ConfiguracaoValePresenteService = () => {
    const path = '/configuracoes/valepresente';
    const loginService = LoginService();

    return {
        getConfiguracao(): Promise<AxiosResponse<ConfiguracaoValePresente>> {
            return axiosRequest.get(path, { headers: loginService.getHeaders() });
        },
        update(config: ConfiguracaoValePresente): Promise<AxiosResponse<ConfiguracaoValePresente>> {
            return axiosRequest.put(path, config, { headers: loginService.getHeaders() });
        }
    };
};

export default ConfiguracaoValePresenteService;

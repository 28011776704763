import { useLocation } from "react-router-dom";

const useCustomLocation = () => {
    const location = useLocation();
    const pathName = location.pathname;
    return {
        location,
        pathName,
        isTelaRegistraVenda: () => {
            return pathName === '/registra-venda';
        },
        isTelaPagamento: () => {
            return pathName === '/pagamento';
        },
        isTelaVenda: () => {
            return pathName === '/venda';
        },
        isTelaConfiguracoes: () => {
            return pathName === '/configuracoes';
        },
        isTelaCaixa: () => {
            return pathName === '/caixa';
        },
        isTelaValePresente: () => {
            return pathName === '/vale-presente';
        },
        isTelaBuscaAvancadaValePresente: () => {
            return pathName === '/vale-presente/busca-avancada';
        },
        isTelaRelatorioFechamentoCaixa: () => {
            return pathName === "/relatorio/caixa/fechamento";
        },
        isTelaPreVenda: () => {
            return pathName === "/pre-venda";
        },
    };
};

export default useCustomLocation;

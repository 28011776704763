import { CircularProgress } from "@mui/material"
import { Box } from "@mui/system"


const CustomLoading = () => {
    return (
        <Box
            sx={{
                zIndex: 100000,
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100%',
                bgcolor: 'rgba(0,0,0,0.5)',
                boxSizing: "border-box",
            }}
        >
            <CircularProgress
                size={48}
                color='warning'
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                }}
            />
        </Box>
    )
}

export default CustomLoading
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToken } from "model/token.model";
import { deepReset } from "utils/util";

const initialState: IToken = {
    ESTABELECIMENTOS: [],
    KEY_CLOAK_ROLES: [],
    NEXUS_ROLES: [],
};

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<IToken>) => {
            Object.assign(state, action.payload);
        },
        resetToken: (state) => {
            deepReset(state, initialState);
        }
    }
});

export const { setToken, resetToken } = tokenSlice.actions;
export const tokenReducer = tokenSlice.reducer;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContaPagarValePresenteTO, ValePresenteDialogFormState } from "pages/vale-presente/vale-presente.model";
import { deepReset } from "utils/util";

const initialState: {
    valePresente: ValePresenteDialogFormState | null;
    dadosValePresente: Array<ContaPagarValePresenteTO>,
    uuidsValesAdicionados: {
        total: number;
        uuids: Array<string>;
    },
} = {
    valePresente: null,
    dadosValePresente: [],
    uuidsValesAdicionados: {
        total: 0,
        uuids: []
    },
};

const valePresente = createSlice({
    name: 'valePresente',
    initialState,
    reducers: {
        setValePresente: (s, { payload }: PayloadAction<ValePresenteDialogFormState>) => {
            s.valePresente = payload;
        },
        resetValePresente: (s) => {
            s.valePresente = initialState.valePresente;
        },
        setDadosValePresente: (s, { payload }: PayloadAction<Array<ContaPagarValePresenteTO>>) => {
            s.dadosValePresente = payload;
        },
        resetDadosValePresente: (s) => {
            s.dadosValePresente = initialState.dadosValePresente;
        },
        setUUIDsValesAdicionados: (s, { payload }: PayloadAction<typeof initialState.uuidsValesAdicionados>) => {
            s.uuidsValesAdicionados = payload;
        },
        resetValePresenteState: (s) => {
            deepReset(s, initialState);
        }
    },
});

export const {
    setValePresente,
    resetValePresente,
    setDadosValePresente,
    resetDadosValePresente,
    setUUIDsValesAdicionados,
    resetValePresenteState,
} = valePresente.actions;

export const valePresenteReducer = valePresente.reducer;

import { DialogActions, DialogContent, DialogTitle, Icon } from "@mui/material";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from "components/roundedbutton/CustomButton";
import { FormEvent, useEffect, useRef } from 'react';
import { FormaPagamentoDialogProps } from "./formapagamento.model";


export const FormaPagamentoDialog = (props: FormaPagamentoDialogProps) => {
    const canClickRef = useRef<boolean>(true);

    const onConfirm = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (canClickRef.current) {
            canClickRef.current = false;
            props.setSubmitted?.(true);
            if (!props.preventConfirmar?.value) {
                props.confirmButton?.onConfirm?.(props.id);
            }
        }
        setTimeout(() => {
            if (!canClickRef.current) {
                canClickRef.current = true;
            }
        }, 1000);
    };


    useEffect(() => {
        if (!props.open) {
            props.preventConfirmar?.setPreventConfirmar(false);
            props.setSubmitted?.(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open]);



    return (
        <CustomDialog
            id={props.id ? props.id.toLowerCase() + '-dialog' : undefined}
            open={props.open ?? false}
            onClose={() => props.onClose?.(props.id)}
            fullWidth={props.fullWidth}
            maxWidth={props.maxWidth ?? 'xs'}
        >

            <form onSubmit={onConfirm}>
                {!props.disableTitle && 
                    <DialogTitle id="alert-dialog-title" textAlign={'center'}>
                    {props.title}
                </DialogTitle>
                }

                <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>
                    {props.children}
                </DialogContent>

               {!props.disableDefaultButtons && 
                <DialogActions
                sx={{
                    justifyContent: 'space-between'
                }}>
                <CustomButton onClick={() => props.onClose?.(props.id)} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                    Cancelar
                </CustomButton>
                <CustomButton type="submit" disabled={props.confirmButton?.disabled} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                    Confirmar
                </CustomButton>
            </DialogActions>
               }
            </form>
        </CustomDialog>
    );
};

import { Delete } from "@mui/icons-material";
import { TableRow, TableCell, TextField, IconButton } from "@mui/material";
import DescontoInput from "pages/registra-venda/DescontoInput";
import { IProdutoRow } from "pages/registra-venda/registra-venda.model";
import { memo } from "react";
import { currencyOf } from "utils/util";

type ItemRegistraVendaTrocaProps = {
    row: IProdutoRow;
    index: number;
    onChangeQuantidadeTroca: (index: number, value: string) => void;
    changeDescontoTroca: (value: number, index: number) => void;
    toggleTipoDescontoTroca: () => void;
    onBlurDescontoItemTroca: (value: number, index: number) => void;
    saveTempDescontoTroca: (prevDesconto: number, index: number) => void;
    onClickDeleteTroca: (index: number, nomeProduto: string) => void;
    isVisualizar: boolean;
};


const ItemRegistraVendaTroca = (props: ItemRegistraVendaTrocaProps) => {
    return (
        <TableRow
            key={props.row.produto.codigoSku + props.index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, bgcolor: '#FF9F9F' }}
        >
            <TableCell sx={props.isVisualizar ? { color: '#7F7F7F' } : {}}>
                {`${props.row.produto.codigoSku} - ${props.row.produto.nome} / ${props.row.produto.cor} / ${props.row.produto.tamanho}`}
            </TableCell>
            <TableCell align="center" ></TableCell>
            <TableCell align="center">
                <TextField
                    disabled={props.isVisualizar}
                    inputProps={{ inputMode: 'numeric', min: 1, style: { textAlign: 'center' } }}
                    value={props.row.quantidade}
                    onKeyDown={e => e.key === '.' && e.preventDefault()}
                    onChange={q => { props.onChangeQuantidadeTroca(props.index, q.target.value); }
                    }
                    variant="standard"
                    type="number"
                />
            </TableCell>
            <TableCell align="center" sx={props.isVisualizar ? { color: '#7F7F7F' } : {}}   >
                {currencyOf(props.row.produto.preco)}
            </TableCell>
            <TableCell align="center">
                <DescontoInput
                    isVisualizar={props.isVisualizar}
                    item={{
                        tipoDesconto: props.row.itemDesconto.tipoDesconto,
                        valorDesconto: props.row.itemDesconto.valorDesconto,
                        percentualDesconto: props.row.itemDesconto.percentualDesconto,
                        valorTotalBruto: props.row.produto.preco * props.row.quantidade,
                        index: props.index,
                        onChangeDesconto: props.changeDescontoTroca,
                    }}
                    inputProps={{ style: { textAlign: 'center', } }}
                    iconButtonProps={{
                        onToggleTipoDesconto: props.toggleTipoDescontoTroca,
                        percentoProps: {
                            color: '#404040',
                        },
                        valorProps: {
                            color: '#404040',
                        }
                    }}
                    onBlur={props.onBlurDescontoItemTroca}
                    onSaveTempDesconto={props.saveTempDescontoTroca}
                />
            </TableCell>
            <TableCell align="center" sx={props.isVisualizar ? { color: '#7F7F7F' } : {}}>
                {currencyOf(props.row.valorTotal)}
            </TableCell>
            <TableCell align="right">
                <IconButton
                    disabled={props.isVisualizar}
                    onClick={() => props.onClickDeleteTroca(props.index, props.row.produto.nome)}
                    style={{ color: props.isVisualizar ? "#9F9F9F" : "#404040", }}
                >
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default memo(ItemRegistraVendaTroca);

import { Box, DialogActions, DialogContent, DialogTitle, Grid, Icon, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowsProp, GridToolbarQuickFilter, ptBR } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import dayjs from 'dayjs';
import { BaseValePresenteTO } from 'pages/vale-presente/vale-presente.model';
import ValePresenteService from 'pages/vale-presente/vale-presente.service';
import { useEffect, useState } from 'react';
import { currencyOf } from 'utils/util';
import ExcluirDialog from './ExcluirDialog';
import VisualizarDialog from './VisualizarDialog';


type CancelamentoValePresenteDialogProps = {
    open: boolean,
    onClose: () => void;
};
const initialState = {
    isLoading: false,
    rows: [] as GridRowsProp,
    emissaoInicio: new Date(),
    emissaoFim: new Date(),
    openDialogVisualizar: false,
};

const dialogsInitialState = {
    openVisualizar: false,
    openExcluir: false,
    row: {} as BaseValePresenteTO,
};

const CancelamentoValePresenteDialog = (props: CancelamentoValePresenteDialogProps) => {
    const [localState, setLocalState] = useState(initialState);
    const [dialogsState, setDialogsState] = useState(dialogsInitialState);
    const service = ValePresenteService();

    const onCloseLocal = () => {
        props.onClose();
    };

    const getAllVales = (emissaoInicio: Date, emissaoFim: Date) => {
        setLocalState(prev => ({ ...prev, isLoading: true }));
        service.getAllVales(emissaoInicio.toISOString(), emissaoFim.toISOString())
            .then(({ data }) => {
                setLocalState(prev => ({ ...prev, rows: data }));
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setLocalState(prev => ({ ...prev, isLoading: false }));
            });
    };

    useEffect(() => {
        if (props.open && !dialogsState.openExcluir) {
            getAllVales(localState.emissaoInicio, localState.emissaoFim);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, localState.emissaoInicio, localState.emissaoFim, dialogsState.openExcluir]);

    const columns: GridColDef[] = [
        { field: 'descricao', headerName: 'Descrição', type: 'string', flex: 2.5, },
        { field: 'emissao', headerName: 'Data emissão', type: 'string', flex: 1, valueFormatter: (value) => value.value },
        { field: 'vencimento', headerName: 'Data vencimento', type: 'string', flex: 1, valueFormatter: (value) => value.value },
        { field: 'valorAberto', headerName: 'Valor', type: 'number', flex: .6, valueFormatter: (value) => currencyOf(value.value) },
        {
            field: 'options',
            type: 'actions',
            headerName: 'Ações',
            flex: .5,
            getActions: (item) => {
                return [
                    <GridActionsCellItem
                        icon={
                            <Icon className='fa-solid fa-file-lines' sx={{ color: '#79ace0' }}></Icon>
                        }
                        label={"Visualizar"}
                        onClick={() => setDialogsState({ openVisualizar: true, openExcluir: false, row: item.row })}
                    />,
                    <GridActionsCellItem
                        icon={
                            <Icon className='fa-solid fa-xmark' sx={{ color: 'var(--error)' }}></Icon>
                        }
                        label={"Excluir"}
                        onClick={() => setDialogsState({ openVisualizar: false, openExcluir: true, row: item.row })}
                    />
                ];
            }
        },
    ];

    const onChangeEmissao = (inicioFim: 'emissaoInicio' | 'emissaoFim', date: Date) => {
        setLocalState(prev => ({ ...prev, [inicioFim]: date }));
    };

    return (
        <>
            <CustomDialog
                open={props.open}
                onClose={onCloseLocal}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle>
                    <Typography textAlign="center" fontSize={38}>Cancelar vale presente</Typography>
                </DialogTitle>

                <DialogContent sx={{ paddingX: 0 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2} >
                            <DatePicker
                                maxDate={dayjs(localState.emissaoFim)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        id: `emissao-inicio-input`,
                                        variant: 'standard',
                                        onKeyDown: (e: any) => { e.preventDefault(); },
                                    }
                                }}
                                label='Emissão de'
                                format='DD/MM/YYYY'
                                sx={{
                                    width: '100%',
                                }}
                                value={dayjs(localState.emissaoInicio)}
                                onChange={(e: any) => onChangeEmissao('emissaoInicio', e.$d)}
                            />
                        </Grid>

                        <Grid item xs={12} md={2} >
                            <DatePicker
                                minDate={dayjs(localState.emissaoInicio)}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        id: `emissao-fim-input`,
                                        variant: 'standard',
                                        onKeyDown: (e: any) => { e.preventDefault(); },
                                    }
                                }}
                                label='Emissão até'
                                format='DD/MM/YYYY'
                                sx={{
                                    width: '100%',
                                }}
                                value={dayjs(localState.emissaoFim)}
                                onChange={(e: any) => onChangeEmissao('emissaoFim', e.$d)}
                            />
                        </Grid>

                        <Grid item xs={0} md={8} />

                        <Grid item xs={12} sx={{ marginTop: { xs: '40px', md: '0' } }}>
                            <DataGrid
                                localeText={{
                                    ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                                    noRowsLabel: "Nenhum vale presente encontrado"
                                }}
                                rows={localState.rows}
                                columns={columns}
                                getRowId={(row) => row.uuid}
                                disableColumnSelector
                                disableColumnFilter
                                disableDensitySelector
                                disableRowSelectionOnClick
                                disableColumnMenu
                                hideFooter
                                loading={localState.isLoading}
                                sx={{
                                    minHeight: '300px',
                                    maxHeight: '500px'
                                }}
                                slots={{ toolbar: CustomDataGridToolbar }}
                                ignoreDiacritics
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ paddingX: '0' }}>
                    <CustomButton
                        onClick={onCloseLocal}
                        variant="contained"
                        color="error"
                        startIcon={
                            <Icon className={`fa-solid fa-xmark`} />}
                    >
                        Voltar
                    </CustomButton>
                </DialogActions>
            </CustomDialog>

            <VisualizarDialog
                open={dialogsState.openVisualizar}
                row={dialogsState.row}
                onClose={() => setDialogsState(prev => ({ ...prev, openVisualizar: false, uuid: '' }))}
            />

            <ExcluirDialog
                open={dialogsState.openExcluir}
                row={dialogsState.row}
                onClose={() => setDialogsState(prev => ({ ...prev, openExcluir: false, uuid: '' }))}
            />
        </>

    );
};

export default CancelamentoValePresenteDialog;

function CustomDataGridToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                position: 'absolute',
                top: '-52px',
                right: '0',
                width: { xs: '100%', md: '33%' },
                padding: { xs: '0', md: 'auto' },
            }}
        >
            <GridToolbarQuickFilter sx={{ width: '100%' }} />
        </Box>
    );
}

import { IAbstractModel } from "./abstract.model";

export interface ICliente {
    consumidorFinal: string,
    dataCadastro: Date,
    dataNascimento: Date,
    identificacao: string,
    inscricaoEstadual: string,
    nomeFantasia: string,
    nomeRazaoSocial: string,
    status: string;
    tipoContribuinte: string,
    tipoPessoa: string,
    uuid: string;
}

export enum TipoPessoa {
    FISICA = 'F',
    JURIDICA = 'J',
    ESTRANGEIRA = 'E'
}

export interface IConsumidor extends IAbstractModel {
    identificacao?: string,
    nome?: string,
    email?: string,
    tipoPessoa?: TipoPessoa;
}

import { AxiosResponse } from "axios";
import { axiosRequest } from "../axios/axios.config";

const StatusServicoService = () => {
    return {
        getCurrentVersion(): Promise<AxiosResponse<string>> {
            return axiosRequest.get(`/statusservico/currentversion`);
        },
    };
};

export default StatusServicoService;

import { AbstractControl, useValidators } from "hooks/useFormValidator";

export const usePessoaValidator = () => {

    const baseValidators = useValidators();

    const cnpfCnpj = ({ value }: AbstractControl): {} | null => {
        const tipoPessoa = value?.tipoPessoa;
        const identify = value?.identificacao ?? '';
        const numberDigits = identify.split('').map(Number);
        const error = { cpfCnpjInvalid: true };
        const regexAllEqual = /^(\d)\1+$/;

        const calculateDigit = (numberPlace: number) => {
            const total = numberDigits
                .slice(0, numberPlace - 1)
                .map((digit: number, index: number) => {
                    let multiplier = numberPlace - index;
                    if (numberPlace > 11 && index < numberPlace - 9) {
                        multiplier -= 8;
                    }
                    return digit * multiplier;
                })
                .reduce((acc: number, value: number) => acc + value, 0);
            const remainder = total % 11;
            return remainder < 2 ? 0 : 11 - remainder;
        };

        if (tipoPessoa === 'FISICA' && identify.length === 11) { // cpf
            if (regexAllEqual.test(identify)) {
                return error;
            }
            const first = calculateDigit(10);
            const second = calculateDigit(11);

            if (first !== numberDigits[9] || second !== numberDigits[10]) {
                return error;
            }
        } else if (tipoPessoa === 'JURIDICA' && identify.length === 14) { // cnpj
            if (regexAllEqual.test(identify)) {
                return { cpfCnpjInvalid: true };
            }
            const first = calculateDigit(13);
            const second = calculateDigit(14);

            if (first !== numberDigits[12] || second !== numberDigits[13]) {
                return error;
            }
        }
        return null;
    };

    const contribuinteInscricaoEstadualValidate = ({ value }: AbstractControl): {} | null => {
        if (value.tipoContribuinte === 'CONTRIBUINTE_ICMS') {
            const inscricao = value.inscricaoEstadual;
            const regexInscricaoEstadual = /^\d{2,14}$/;
            const inscricaoValida = regexInscricaoEstadual.test(inscricao);
            const filled = inscricao?.length;

            let errors = {};
            if (!inscricaoValida && inscricao?.length > 0) errors = { ...errors, invalidInscricaoEstadual: true };
            if (!filled) errors = { ...errors, inscricaoEstadualrequired: true };

            return inscricaoValida && filled ? null : errors;
        }
        return null;
    };

    return { baseValidators, cnpfCnpj, contribuinteInscricaoEstadualValidate };
};

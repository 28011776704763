import { Box, DialogActions, DialogContent, DialogTitle, Icon, Skeleton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import PdfTemplate from 'pages/pdf/PdfTemplate';
import { useAppSelector } from 'redux/hooks';
import { currencyOf } from 'utils/util';
import { ColumnsRelatorioPDF, ItemRelatorioCaixa, PropsRelatorioCaixa, TotalizadoresRelatorioCaixa } from './relatorio-caixa.model';

const relatorioColumnsLabels: ColumnsRelatorioPDF<ItemRelatorioCaixa> = [
    { sizePercent: '5', key: 'pedido', label: 'Pedido', },
    { sizePercent: '4', key: 'serie', label: 'Serie', },
    { sizePercent: '3', key: 'numeroNota', label: 'Nota', },
    { sizePercent: '5', key: 'modelo', label: 'Modelo', },
    { sizePercent: '12', key: 'cliente', label: 'Cliente', },
    { sizePercent: '12', key: 'vendedor', label: 'Vendedor', },
    { sizePercent: '8', key: 'emissao', label: 'Emissão', type: 'date' },
    { sizePercent: '7', key: 'valorBruto', label: 'Bruto', type: 'currency' },
    { sizePercent: '7', key: 'troca', label: 'Troca', type: 'currency' },
    { sizePercent: '7', key: 'descontoPercentual', label: '%Desc', type: 'percentage' },
    { sizePercent: '7', key: 'descontoValor', label: '$Desc', type: 'currency' },
    { sizePercent: '7', key: 'valorLiquido', label: 'Líquido', type: 'currency' },
    { sizePercent: '7', key: 'formaPagamento', label: 'Forma pgto', },
];

const RealtorioCaixa = (props: PropsRelatorioCaixa) => {
    const isLoading = () => {
        return props.state.loading;
    };

    const userName = useAppSelector(s => s.token.USER_INFO?.username);
    const caixaDescritivo = useAppSelector(s => s.caixa.caixaAtual.caixa?.descritivo);

    return (
        <CustomDialog
            id='relatorio-caixa-dialog'
            open={props.state.open}
            fullWidth
            maxWidth={'xl'}
            onClose={() => { props.setState(prev => ({ ...prev, open: false })); }}
        >

            <DialogTitle
                id="alert-dialog-title"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'var(--laranja)',
                    gap: '15px',
                    borderRadius: '15px',
                    marginBottom: '30px'
                }}
            >
                <Typography
                    sx={{
                        fontSize: 'clamp(20px, 3vw, 32px)',
                    }}
                    textAlign={'center'}
                    color={'#fff'}
                    fontWeight={700}
                >
                    Relatório de fechamento de caixa
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ height: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'flex-start' }}>
                <Box sx={{
                    width: '100%',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: '8px'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1px', flexDirection: 'column' }}>
                        <Typography fontWeight={700}>Estabelecimento:</Typography>
                        {
                            isLoading()
                                ?
                                <Skeleton width={100} height={30} variant='text' animation='wave'></Skeleton>
                                :
                                <Typography>{props.state.info.estabelecimento}</Typography>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1px', flexDirection: 'column' }}>
                        <Typography fontWeight={700}>Usuário:</Typography>
                        {
                            isLoading()
                                ?
                                <Skeleton width={100} height={30} variant='text' animation='wave'></Skeleton>
                                :
                                <Typography>{userName}</Typography>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1px', flexDirection: 'column' }}>
                        <Typography fontWeight={700}>Data/Hora:</Typography>
                        {
                            isLoading()
                                ?
                                <Skeleton width={100} height={30} variant='text' animation='wave'></Skeleton>
                                :
                                <Typography>{new Date(props.state.info.hora).toLocaleString('pt-BR')}</Typography>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '1px', flexDirection: 'column' }}>
                        <Typography fontWeight={700}>Caixa:</Typography>
                        {
                            isLoading()
                                ?
                                <Skeleton width={100} height={30} variant='text' animation='wave'></Skeleton>
                                :
                                <Typography>{caixaDescritivo}</Typography>
                        }
                    </Box>
                </Box>

                <Box sx={{
                    width: '100%',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
                        <Typography sx={{ marginBottom: '4px' }} fontWeight={700}>Saldo inicial:</Typography>
                        {
                            isLoading()
                                ?
                                <Skeleton width={100} height={50} variant='text' animation='wave'></Skeleton>
                                :
                                <Typography fontSize={25}>{currencyOf(props.state.info.caixa.saldoInicial)}</Typography>
                        }
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
                        <Typography sx={{ marginBottom: '4px' }} fontWeight={700}>Saldo final:</Typography>
                        {
                            isLoading()
                                ?
                                <Skeleton width={100} height={50} variant='text' animation='wave'></Skeleton>
                                :
                                <Typography fontSize={25}>{currencyOf(props.state.info.caixa.saldoFinalDinheiro)}</Typography>
                        }
                    </Box>
                </Box>

                <TableContainer
                    sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 1,
                        position: 'relative',
                        boxSizing: 'border-box',
                        height: '100%'
                    }}
                    className="color-orange"
                >

                    <Table
                        id="content-table"
                        stickyHeader
                        size="small"
                    >
                        <TableHead>
                            <TableRow >
                                {relatorioColumnsLabels.map((header, index) => (
                                    <TableCell
                                        sx={{ whiteSpace: 'nowrap' }}
                                        key={index}
                                    >
                                        {header.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                isLoading()
                                    ? Array.from([].constructor(7)).map((unknown, indexA) => (
                                        <TableRow key={indexA}>
                                            {relatorioColumnsLabels.map((a, indexB) => {
                                                return <TableCell
                                                    key={indexB}
                                                >
                                                    <Skeleton
                                                        animation="wave"
                                                        variant="rounded"
                                                        width={'100%'}
                                                        height={30}
                                                    />
                                                </TableCell>;
                                            })}

                                        </TableRow>
                                    ))
                                    : props.state.info.itens.map((item, indexA) => (
                                        <TableRow key={indexA}>
                                            {relatorioColumnsLabels.map((a, indexB) => {
                                                let valor = item[a.key];
                                                if (a.type === 'date') {
                                                    valor = new Date(valor).toLocaleDateString('pt-BR');
                                                }
                                                if (a.type === 'currency') {
                                                    valor = currencyOf(valor);
                                                }
                                                if (a.type === 'percentage') {
                                                    valor = `${String(valor.toFixed(2))}%`;
                                                }
                                                return <TableCell
                                                    key={indexB}
                                                >
                                                    {valor}
                                                </TableCell>;
                                            })}
                                        </TableRow>
                                    ))
                            }
                        </TableBody>

                        <TableFooter sx={{
                            width: '100%',
                            position: 'sticky',
                            bottom: 0,
                            height: '4  0px',
                        }} className='glassMorphism'>
                            <TableRow >
                                {relatorioColumnsLabels.map((header, index) => {
                                    const totalizadores = props.state.info.totalizadores;
                                    const total = totalizadores[header.key as unknown as keyof TotalizadoresRelatorioCaixa];
                                    return <TableCell
                                        sx={{ fontWeight: 'bold', color: '#000' }}
                                        key={index}
                                    >
                                        {isLoading()
                                            ?
                                            total !== undefined && <Skeleton width={'100%'} height={30} variant='text' animation='wave'></Skeleton>
                                            :
                                            total !== undefined ? currencyOf(total) : ''}
                                    </TableCell>;
                                })}
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>


                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '10px',
                        }}>
                            <Typography
                                fontWeight={700}
                            >
                                Abertura:
                            </Typography>

                            {isLoading()
                                ?
                                <Skeleton animation='wave' width={100} height={25}></Skeleton>
                                : <Typography>
                                    {currencyOf(props.state.info.caixa.abertura)}
                                </Typography>
                            }
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '10px',
                        }}>
                            <Typography
                                fontWeight={700}
                            >
                                Reforço:
                            </Typography>

                            {isLoading()
                                ?
                                <Skeleton animation='wave' width={100} height={25}></Skeleton>
                                : <Typography>
                                    {currencyOf(props.state.info.caixa.reforco)}
                                </Typography>
                            }
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '10px',
                        }}>
                            <Typography
                                fontWeight={700}
                            >
                                Sangria:
                            </Typography>

                            {isLoading()
                                ?
                                <Skeleton animation='wave' width={100} height={25}></Skeleton>
                                : <Typography>
                                    {currencyOf(props.state.info.caixa.sangria)}
                                </Typography>
                            }
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '10px',
                        }}>
                            <Typography
                                fontWeight={700}
                            >
                                {props.state.info.caixa.valePresente ? "Vale Presente:" : ""}
                            </Typography>

                            {isLoading()
                                ?
                                <Skeleton animation='wave' width={100} height={25}></Skeleton>
                                : <Typography>
                                    {props.state.info.caixa.valePresente ? currencyOf(props.state.info.caixa.valePresente) : ""}
                                </Typography>
                            }
                        </Box>

                    </Box>

                    <Box>
                        {isLoading()
                            ?
                            Array.from([].constructor(3)).map((unknow, index) => (
                                <Skeleton key={index} animation='wave' width={200} height={25}></Skeleton>
                            ))
                            :
                            props.state.info.totalizadoresFormaPagamento.map((item, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: '10px',
                                    }}>
                                    <Typography
                                        fontWeight={700}
                                    >
                                        {item.nome}:
                                    </Typography>
                                    <Typography>
                                        {currencyOf(item.valor)}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    justifyContent: 'space-between'
                }}>
                <CustomButton onClick={() => { props.setState(prev => ({ ...prev, open: false })); }} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                    Cancelar
                </CustomButton>

                <PDFDownloadLink
                    document={
                        <PdfTemplate
                            headers={relatorioColumnsLabels}
                            relatorioCaixa={props.state.info}
                            caixaName={caixaDescritivo!}
                            dadosUser={{
                                estabelecimento: props.state.info.estabelecimento,
                                username: userName!,
                                hora: props.state.info.hora
                            }}
                        />
                    }
                    fileName="relatorio_caixa.pdf"
                >
                    {({ blob, url, loading, error }) => {
                        return <CustomButton disabled={isLoading() || loading} variant="contained" color="info" startIcon={<Icon className='fa-solid fa-print' />}>
                            Imprimir
                        </CustomButton>;
                    }}
                </PDFDownloadLink>

            </DialogActions>
        </CustomDialog >
    );
};

export default RealtorioCaixa;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ConfiguracaoPedidoBalcaoTO } from "layout/topbar/topBar.model";
import { IAbstractModel } from "model/abstract.model";
import { ConfiguracaoDescontoTO } from "pages/Configuracoes/Configuracoes.model";
import { deepReset } from "utils/util";

type configuracoesType = {
    configuracaoPedidoBalcao: ConfiguracaoPedidoBalcaoTO;
    configuracaoDesconto: ConfiguracaoDescontoTO;
    configuracaoDescontoAutorizador: { token: string, configuracaoDesconto: ConfiguracaoDescontoTO; };
};

const initialState: configuracoesType = {
    configuracaoPedidoBalcao: {
        deposito: { uuid: '', descritivo: '' },
        depositos: [],
        modeloDocumentoFiscal: { name: '', descritivo: '' },
        serieFiscalNFCe: null,
        serieFiscalNFCeOutros: null,
        serieFiscalNFe: null,
        serieFiscalNFeOutros: null,
        tabelaPreco: { uuid: '', descritivo: '' },
        tabelasPreco: [],
        tabelaPrecoPadrao: { uuid: '', descritivo: '' },
        depositoPadrao: { uuid: '', descritivo: '' },
        tmfDevolucao: null,
        tmfVenda: null,
        caixasObrigaVendedor: [],
        tmfBrinde: null
    },
    configuracaoDesconto: {
        uuid: null,
        usuario: "",
        descontoMaximoItem: 0,
        descontoMaximoPedido: 0,
        autorizaDesconto: false
    },
    configuracaoDescontoAutorizador: {
        token: "",
        configuracaoDesconto: {
            uuid: null,
            usuario: "",
            descontoMaximoItem: 0,
            descontoMaximoPedido: 0,
            autorizaDesconto: false
        }
    }
};

const configuracoes = createSlice({
    name: 'configuracoes',
    initialState,
    reducers: {
        setConfiguracaoPedidoBalcao: (s, { payload }: PayloadAction<ConfiguracaoPedidoBalcaoTO>) => {
            const configuracaoPedidoBalcao = s.configuracaoPedidoBalcao;
            const configuracaoPedidoBalcaoInicial = initialState.configuracaoPedidoBalcao;

            configuracaoPedidoBalcao.deposito = payload.deposito ?? configuracaoPedidoBalcaoInicial.deposito;
            configuracaoPedidoBalcao.depositos = payload.depositos ?? configuracaoPedidoBalcaoInicial.depositos;
            configuracaoPedidoBalcao.modeloDocumentoFiscal = payload.modeloDocumentoFiscal ?? configuracaoPedidoBalcaoInicial.modeloDocumentoFiscal;
            configuracaoPedidoBalcao.serieFiscalNFCe = payload.serieFiscalNFCe ?? configuracaoPedidoBalcaoInicial.serieFiscalNFCe;
            configuracaoPedidoBalcao.serieFiscalNFCeOutros = payload.serieFiscalNFCeOutros ?? configuracaoPedidoBalcaoInicial.serieFiscalNFCeOutros;
            configuracaoPedidoBalcao.serieFiscalNFe = payload.serieFiscalNFe ?? configuracaoPedidoBalcaoInicial.serieFiscalNFe;
            configuracaoPedidoBalcao.serieFiscalNFeOutros = payload.serieFiscalNFeOutros ?? configuracaoPedidoBalcaoInicial.serieFiscalNFeOutros;
            configuracaoPedidoBalcao.tabelaPreco = payload.tabelaPreco ?? configuracaoPedidoBalcaoInicial.tabelaPreco;
            configuracaoPedidoBalcao.tabelasPreco = payload.tabelasPreco ?? configuracaoPedidoBalcaoInicial.tabelasPreco;
            configuracaoPedidoBalcao.tabelaPrecoPadrao = payload.tabelaPrecoPadrao ?? configuracaoPedidoBalcaoInicial.tabelaPrecoPadrao;
            configuracaoPedidoBalcao.depositoPadrao = payload.depositoPadrao ?? configuracaoPedidoBalcaoInicial.depositoPadrao;
            configuracaoPedidoBalcao.tmfDevolucao = payload.tmfDevolucao ?? configuracaoPedidoBalcaoInicial.tmfDevolucao;
            configuracaoPedidoBalcao.tmfVenda = payload.tmfVenda ?? configuracaoPedidoBalcaoInicial.tmfVenda;
            configuracaoPedidoBalcao.caixasObrigaVendedor = payload.caixasObrigaVendedor ?? configuracaoPedidoBalcao.caixasObrigaVendedor;
            configuracaoPedidoBalcao.tmfBrinde = payload.tmfBrinde ?? configuracaoPedidoBalcao.tmfBrinde;
        },
        setTabelaPreco: (s, { payload }: PayloadAction<IAbstractModel>) => {
            s.configuracaoPedidoBalcao.tabelaPreco = payload;
        },
        setTabelaPrecoPadrao: (s, { payload }: PayloadAction<IAbstractModel>) => {
            s.configuracaoPedidoBalcao.tabelaPrecoPadrao = payload;
        },
        setDeposito: (s, { payload }: PayloadAction<IAbstractModel>) => {
            s.configuracaoPedidoBalcao.deposito = payload;
        },
        setDepositoPadrao: (s, { payload }: PayloadAction<IAbstractModel>) => {
            s.configuracaoPedidoBalcao.depositoPadrao = payload;
        },
        setConfiguracaoDesconto: (s, { payload }: PayloadAction<ConfiguracaoDescontoTO>) => {
            s.configuracaoDesconto = payload;
        },
        setConfiguracaoDescontoAutorizador: (s, { payload }: PayloadAction<{ token: string, configuracaoDesconto: ConfiguracaoDescontoTO; }>) => {
            s.configuracaoDescontoAutorizador.configuracaoDesconto = payload.configuracaoDesconto;
            s.configuracaoDescontoAutorizador.token = payload.token;
        },
        resetDescontoAutorizador: (s) => {
            s.configuracaoDescontoAutorizador = initialState.configuracaoDescontoAutorizador;
        },
        resetConfiguracoes: (s) => {
            deepReset(s, initialState);
        }

    },
});

export const {
    setConfiguracaoDesconto,
    setConfiguracaoDescontoAutorizador,
    setConfiguracaoPedidoBalcao,
    setDeposito,
    setDepositoPadrao,
    setTabelaPreco,
    setTabelaPrecoPadrao,
    resetConfiguracoes,
    resetDescontoAutorizador,
} = configuracoes.actions;

export const configuracoesReducer = configuracoes.reducer;

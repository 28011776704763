

import { MenuList } from "@mui/material"
import { TMenuItemRelatorioProps } from "../vendas.model"


const MenuItemCancelado = (props: TMenuItemRelatorioProps) => {
    return (
        <MenuList>
            {/* <MenuItem key="menu-item-cancelado-visualizar" onClick={() => { }}>
                <ListItemIcon>
                    <Icon className="fa-solid fa-eye" sx={{ fontSize: "18px" }} />
                </ListItemIcon>
                <ListItemText>Visualizar</ListItemText>
            </MenuItem>

            <MenuItem key="menu-item-cancelado-imprimir" onClick={() => { }}>
                <ListItemIcon>
                    <Icon className="fa-solid fa-print" sx={{ fontSize: "18px" }} />
                </ListItemIcon>
                <ListItemText>Imprimir</ListItemText>
            </MenuItem> */}
        </MenuList>
    )
}

export default MenuItemCancelado


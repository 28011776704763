import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

interface Props {
    children?: JSX.Element;
    allowedRoles?: Array<string>;
};

const Guard = ({ children, allowedRoles }: Props) => {
    const token = useAppSelector((state) => state.token);

    // *** Descomenta pra ter o tokenProvider *** //
    // if (!token.access_token) {
    //     dispatch(setToken(tokenLocal));
    //     dispatch(setEsSelecionado(tokenLocal.ESTABELECIMENTOS[0]));
    // }

    const isAdmin = token.KEY_CLOAK_ROLES.includes("ROLE_ADMIN");
    const isSuper = token.KEY_CLOAK_ROLES.includes("ROLE_SUPER");

    let containsRole;

    if (isAdmin && isSuper) {
        containsRole = true;
    } else {
        containsRole = token.NEXUS_ROLES.some(role => allowedRoles?.includes(role));
    };

    const isAuthenticated = token.access_token && containsRole;
    if (!isAuthenticated) {
        return (
            <Navigate to={'/login'}></Navigate>
        );
    }

    return children ? <>{children}</> : <Outlet />;
};

export default Guard;

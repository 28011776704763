import { v4 as uuidV4 } from 'uuid';
type ParamUuid = {
    prefix?: string | number;
    sufix?: string | number;
};

export const useGenerateUuid = () => {
    const randomUuid = (param?: ParamUuid) => {
        let toReturn = uuidV4();
        if (param?.prefix) {
            toReturn = param.prefix + toReturn;
        }

        if (param?.sufix) {
            toReturn += param.sufix;
        }
        return toReturn;
    };

    return [randomUuid];
};

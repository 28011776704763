import { Line, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import { ColumnsRelatorioPDF } from 'pages/Caixa/relatorio-caixa/relatorio-caixa.model';
import { currencyOf } from 'utils/util';

const s = StyleSheet.create({
    tableWrapper: {
        width: '97%',
    },
    tableRow: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: '3px',
        paddingVertical: '5px'
    },
    tableText: {
        width: '100%',
        fontSize: '8px',
        textAlign: 'center',
    },
    info: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '95%',
        marginBottom: '10px',
        gap: '5px'
    },
    sectionHeader: {
        fontSize: '13px'
    },
});

export type TablePdf = {
    headerSectionText?: string;
    itens: Array<any>;
    strippedRow?: boolean;
    headers: ColumnsRelatorioPDF<any>;
    totalizadores?: {};
    emptyMessage?: string;
    marginTop?: number;
};

export const TablePDF = (props: TablePdf) => {
    const { headerSectionText, itens, strippedRow, totalizadores, headers } = props;
    let { emptyMessage, marginTop } = props;
    emptyMessage = emptyMessage ?? 'Nenhum resultado encontrado';

    const hasItens = itens.length > 0;

    return <>
        <ItemSeparator height={marginTop ?? 0} />

        {headerSectionText && <SectionHeader text={headerSectionText} />}

        {hasItens ?
            <View style={{ ...s.tableWrapper, }}>
                <View style={{ ...s.tableRow, }}>
                    {headers.map((header, idx) => {
                        return <Text style={{ ...s.tableText, width: header.sizePercent + '%' }} key={idx}>{header.label}</Text>;
                    })}
                </View >

                <Svg height={3}>
                    <Line x1={0} x2={585} y1={0} y2={0} strokeWidth={1}
                        stroke="#999" />
                    <Line x1={0} x2={585} y1={3} y2={3} strokeWidth={1}
                        stroke="#999" />
                </Svg>;

                {itens.map((item, idx) => {
                    return (
                        <div key={idx}>
                            {!strippedRow && idx !== 0 &&
                                <Svg height={1}>
                                    <Line x1={10} x2={585} y1={0} y2={0} strokeWidth={1}
                                        stroke="#999" />
                                </Svg>
                            }
                            <View style={{ ...s.tableRow, backgroundColor: (strippedRow && (idx % 2 === 0 ? '#fff' : '#e8e8e8')) as string }} wrap={false} >

                                {headers.map((header, idx) => { //coluna
                                    let valor: any = item[header.key as unknown as keyof typeof item];
                                    if (header.type === 'currency') {
                                        valor = currencyOf(valor as number);
                                    }
                                    if (header.type === 'date') {
                                        valor = new Date(valor).toLocaleDateString('pt-BR');
                                    }
                                    if (header.type === "percentage") {
                                        valor = `${String(valor.toFixed(2))}%`;
                                    }
                                    return <Text style={{ ...s.tableText, width: header.sizePercent + '%' }} key={idx}>{valor}</Text>;
                                })}
                            </View>
                        </div>
                    );
                })}

                {totalizadores && <>
                    <Svg height={3}>
                        <Line x1={0} x2={585} y1={0} y2={0} strokeWidth={1}
                            stroke="#999" />
                        <Line x1={0} x2={585} y1={3} y2={3} strokeWidth={1}
                            stroke="#999" />
                    </Svg >
                    <View style={s.tableRow}>
                        {props.headers.map((header, idx) => {
                            const total = totalizadores[header.key as unknown as keyof typeof totalizadores];

                            if (idx === 0) {
                                return <Text style={{ ...s.tableText, width: header.sizePercent + '%', textAlign: 'left' }} key={idx}>Total</Text>;
                            }

                            return <Text style={{ ...s.tableText, width: header.sizePercent + '%' }} key={idx}>{total !== undefined ? currencyOf(total) : ''}</Text>;
                        })}
                    </View>
                </>
                }
            </View>
            :
            <View style={s.tableRow}>
                <Text style={{ ...s.tableText, width: '100%' }}>{emptyMessage}</Text>;
            </View>
        }
    </>;
};

export const SectionHeader = (props: { text: string; }) => {
    return <>
        <Svg height={1}>
            <Line x1={10} x2={585} y1={0} y2={0} strokeWidth={1}
                stroke="#999" />
        </Svg>

        <Text style={s.sectionHeader}>{props.text}</Text>

        <Svg height={1}>
            <Line x1={10} x2={585} y1={0} y2={0} strokeWidth={1}
                stroke="#999" />
        </Svg>
    </>;
};

export const ItemSeparator = ({ height }: { height?: number; }) => {
    return <View style={{ marginTop: `${height ? height : 10}px` }} />;
};

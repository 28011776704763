import CloseIcon from '@mui/icons-material/Close';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { Autocomplete, Box, Container, Grid, Icon, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRowId, GridRowParams, GridRowSelectionModel, GridRowsProp, GridSlotsComponentsProps, ptBR } from '@mui/x-data-grid';
import { DatePicker } from "@mui/x-date-pickers";
import { CustomButton } from "components/roundedbutton/CustomButton";
import dayjs from 'dayjs';
import { IAbstractModel } from 'model/abstract.model';
import RegistraVendaService from 'pages/registra-venda/registra-venda.service';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NumericFormat } from "react-number-format";
import { useNavigate } from 'react-router-dom';
import { setDadosValePresente } from 'redux/features/valePresenteSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { currencyOf, isBeforeDate, roundHalfToEven } from 'utils/util';
import { ContaPagarValePresenteTO, ValePresenteFiltroAvancadoTO } from './vale-presente.model';
import ValePresenteService from './vale-presente.service';
import ConfiguracaoValePresenteService from 'pages/Configuracoes/configuracaovalepresente/configuracaovalepresente.service';
import { ConfiguracaoValePresente } from 'pages/Configuracoes/configuracaovalepresente/configuracaovalepresente.model';

declare module '@mui/x-data-grid' {
    interface FooterPropsOverrides {
        totalSelecionado: number;
    }
}

const initialFiltroState = {
    pessoa: null as IAbstractModel | null,
    pessoasAc: [] as Array<IAbstractModel>,
    destinatario: null as string | null,
    vencimentoInicio: new Date(),
    vencimentoFim: new Date(),
    valorInicial: null as number | null,
    valorFinal: null as number | null,
};

const initialGridState = {
    isLoading: false,
    rows: [] as GridRowsProp,
    selectedRowsUUIDs: [] as GridRowSelectionModel,
    selectedRows: [] as Array<ContaPagarValePresenteTO>,
    totalSelecionado: 0,
};

const ValePresenteBuscaAvancada = () => {
    const [filtroState, setFiltroState] = useState(initialFiltroState);
    const [gridState, setGridState] = useState(initialGridState);
    const service = ValePresenteService();
    const registraVendaService = RegistraVendaService();
    const configuracaoValePresente = ConfiguracaoValePresenteService();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { dadosValePresente: dadosValePresenteGlobal, uuidsValesAdicionados } = useAppSelector(s => s.valePresente);

    const isFirstRenderRef = useRef(true);

    useEffect(() => {
        if (isFirstRenderRef.current) {
            configuracaoValePresente.getConfiguracao()
                .then(({ data }: { data: ConfiguracaoValePresente; }) => {
                    const vencimentoFim = new Date();
                    const validadeDias = data.validadeDias ?? 90;
                    vencimentoFim.setDate(vencimentoFim.getDate() + validadeDias);
                    return setFiltroState(prevState => ({ ...prevState, vencimentoFim }));
                })
                .catch(() => { })
                .finally(() => {
                    isFirstRenderRef.current = false;
                });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!isFirstRenderRef.current) {
            getAllVales();
        }
        // eslint-disable-next-line
    }, [filtroState]);

    const pessoaRef = useRef<HTMLInputElement>(null);
    const valorInicialRef = useRef<HTMLInputElement>(null);
    const valorFinalRef = useRef<HTMLInputElement>(null);

    const columns: GridColDef[] = [
        { field: 'descricao', headerName: 'Descrição', type: 'string', flex: 2, },
        { field: 'emissao', headerName: 'Data emissão', type: 'string', headerAlign: 'center', align: 'center', flex: 1, valueFormatter: (value) => value.value },
        {
            field: 'vencimento',
            headerName: 'Data vencimento',
            type: 'string',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: ({ value }) => {
                return <Tooltip
                    arrow
                    disableHoverListener={!isBeforeDate(value)}
                    title={
                        <Typography
                            fontSize={15}
                            textAlign={'left'}
                        >
                            Crédito expirado
                        </Typography>}
                >
                    <Box
                        sx={{
                            color: isBeforeDate(value)
                                ? 'var(--error)'
                                : 'inherit'
                        }}
                    >
                        {value}
                    </Box>
                </Tooltip>;

            },
        },
        { field: 'valorAberto', headerName: 'Valor aberto', type: 'number', headerAlign: 'center', align: 'center', flex: 1, valueFormatter: (value) => currencyOf(value.value) },
    ];

    const getAllVales = () => {
        const { pessoa, valorFinal, valorInicial, vencimentoFim, vencimentoInicio, destinatario } = filtroState;

        setGridState(prev => ({ ...prev, isLoading: true }));

        const filtro: ValePresenteFiltroAvancadoTO = {
            uuidPessoa: pessoa?.uuid ?? null,
            destinatario,
            vencimentoInicio,
            vencimentoFim,
            valorInicial,
            valorFinal,
        };

        service.getAllValesBuscaAvancada(filtro)
            .then(({ data }) => {
                setGridState(prev => {
                    return { ...prev, rows: data };
                });
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => {
                setGridState(prev => ({ ...prev, isLoading: false }));
            });
    };

    const getConsumidoresAc = (toSearch: string = '') => {
        registraVendaService.getConsumidoresAc(toSearch)
            .then(s => {
                setFiltroState(prev => ({ ...prev, pessoasAc: s.data }));
            }).catch(err => {
                console.error(err);
            });
    };

    const onInputChangePessoa = (e: unknown, value: string = '') => {
        getConsumidoresAc(value);
    };

    const onSelectPessoa = (e: unknown, pessoa: IAbstractModel | null) => {
        setFiltroState(prev => ({ ...prev, pessoa }));
    };


    const getPreviousUUID = () => {
        return [...uuidsValesAdicionados.uuids, ...dadosValePresenteGlobal.map((item: { uuid: string; }) => item.uuid)];
    };

    const isAdicinadoPrev = (uuidToCompare: GridRowId) => {
        return uuidsValesAdicionados.uuids.some(uuid => uuid === uuidToCompare)
            || dadosValePresenteGlobal.some(prev => prev.uuid === uuidToCompare);
    };

    const firstRender = useRef(true);
    useEffect(() => {
        if (!firstRender.current) return;
        getConsumidoresAc();
        setGridState(prev => ({
            ...prev,
            selectedRowsUUIDs: getPreviousUUID(),
        }));
        firstRender.current! = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const selectedRows: Array<ContaPagarValePresenteTO> = [];
        let totalSelecionado = dadosValePresenteGlobal
            .map(s => s.valorAberto)
            .reduce((acc, curr) => acc + curr, 0);

        gridState.selectedRowsUUIDs.forEach(uuid => {
            if (isAdicinadoPrev(uuid)) return;

            const find = gridState.rows.find(row => row.uuid === uuid);
            if (find) {
                totalSelecionado += find.valorAberto;
                selectedRows.push(find as ContaPagarValePresenteTO);
            }
        });

        setGridState(prev => {
            const prevUUIDs = getPreviousUUID();
            const novosUUIDs = prev.selectedRowsUUIDs
                .filter(row => !prevUUIDs.some(uuid => uuid === row));

            return {
                ...prev,
                selectedRows,
                selectedRowsUUIDs: [...prevUUIDs, ...novosUUIDs],
                totalSelecionado: roundHalfToEven(totalSelecionado + uuidsValesAdicionados.total, 2),
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(gridState.selectedRowsUUIDs)]);

    const onCancelar = () => {
        navigate('/pagamento', { state: { openDialog: 'VALE_PRESENTE' } });
    };

    const onAvancar = () => {
        dispatch(setDadosValePresente([...dadosValePresenteGlobal, ...gridState.selectedRows]));
        navigate('/pagamento', { state: { openDialog: 'VALE_PRESENTE' } });
    };

    const onChangeDestinatario = useCallback((destinatario: string) => {
        setFiltroState(prevState => ({ ...prevState, destinatario }));
    }, []);

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: '20px',
                height: 'calc(100vh - 64px - 76px - 20px - 20px)',
            }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3.1} >
                    <Autocomplete
                        value={filtroState.pessoa}
                        id="cliente-ac"
                        options={filtroState.pessoasAc}
                        getOptionLabel={(option) => option.descritivo}
                        filterOptions={(x) => x}
                        noOptionsText={'Nenhum resultado encontrado'}
                        onInputChange={onInputChangePessoa}
                        onChange={onSelectPessoa}
                        isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.uuid}>
                                    {option.descritivo}
                                </li>
                            );
                        }}
                        renderInput={
                            (params) => <TextField {...params}
                                label="Cliente"
                                variant="standard"
                                inputRef={pessoaRef}
                            />}
                    />
                </Grid>

                <Grid item xs={12} md={3} >
                    <TextField
                        label="Para"
                        inputProps={{ id: 'autoriza-desconto-item-usuario' }}
                        sx={{ width: '100%' }}
                        variant="standard"
                        value={filtroState.destinatario ?? ""}
                        id="autoriza-desconto-item-usuario"
                        onChange={(e) => onChangeDestinatario(e.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={1.2} >
                    <DatePicker
                        maxDate={dayjs(filtroState.vencimentoFim)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                id: `vencimento-inicio-input`,
                                variant: 'standard',
                                onKeyDown: (e: any) => { e.preventDefault(); },
                            }
                        }}
                        label='Vencimento inicial'
                        format='DD/MM/YYYY'
                        sx={{
                            width: '100%',
                        }}
                        value={dayjs(filtroState.vencimentoInicio)}
                        onChange={(e: any) => setFiltroState(prev => ({ ...prev, vencimentoInicio: e.$d }))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={1.2} >
                    <DatePicker
                        minDate={dayjs(filtroState.vencimentoInicio)}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                id: `vencimento-fim-input`,
                                variant: 'standard',
                                onKeyDown: (e: any) => { e.preventDefault(); },
                            }
                        }}
                        label='Vencimento final'
                        format='DD/MM/YYYY'
                        sx={{
                            width: '100%',
                        }}
                        value={dayjs(filtroState.vencimentoFim)}
                        onChange={(e: any) => setFiltroState(prev => ({ ...prev, vencimentoFim: e.$d }))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={1.5} >
                    <NumericFormat
                        fullWidth
                        id="valor-inicial-input"
                        onFocus={() => {
                            if (filtroState.valorInicial === 0) {
                                valorInicialRef.current?.setSelectionRange(4, 4);
                            }
                        }}
                        decimalScale={2}
                        label='Valor inicial'
                        inputRef={valorInicialRef}
                        fixedDecimalScale
                        allowedDecimalSeparators={[',']}
                        customInput={TextField}
                        decimalSeparator=","
                        prefix={'R$ '}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        variant="standard"
                        value={filtroState.valorInicial ?? ''}
                        allowNegative={false}
                        onValueChange={({ floatValue }) => {
                            setFiltroState(prev => ({ ...prev, valorInicial: floatValue ?? null }));
                        }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {filtroState.valorInicial && <IconButton
                                        onClick={() => {
                                            setFiltroState(prev => ({ ...prev, valorInicial: null }));
                                        }}
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                        }}>
                                        <CloseIcon sx={{ fontSize: '20px' }}></CloseIcon>
                                    </IconButton>}
                                </>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs={10.5} sm={5.3} md={1.5} >
                    <NumericFormat
                        fullWidth
                        id="valor-final-input"
                        onFocus={() => {
                            if (filtroState.valorInicial === 0) {
                                valorInicialRef.current?.setSelectionRange(4, 4);
                            }
                        }}
                        decimalScale={2}
                        label='Valor final'
                        inputRef={valorFinalRef}
                        fixedDecimalScale
                        allowedDecimalSeparators={[',']}
                        customInput={TextField}
                        decimalSeparator=","
                        prefix={'R$ '}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        variant="standard"
                        value={filtroState.valorFinal ?? ''}
                        allowNegative={false}
                        onValueChange={({ floatValue }) => {
                            setFiltroState(prev => ({ ...prev, valorFinal: floatValue ?? null }));
                        }}
                        InputProps={{
                            endAdornment: (
                                <>
                                    {filtroState.valorFinal && <IconButton
                                        onClick={() => {
                                            setFiltroState(prev => ({ ...prev, valorFinal: null }));
                                        }}
                                        sx={{
                                            width: '30px',
                                            height: '30px',
                                        }}>
                                        <CloseIcon sx={{ fontSize: '20px' }}></CloseIcon>
                                    </IconButton>}
                                </>
                            )
                        }}
                    />
                </Grid>

                <Grid item xs={1.5} sm={0.7} md={0.5} sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                }} >
                    <CustomButton
                        title='Consultar'
                        onClick={getAllVales}
                        sx={{
                            minWidth: 'unset',
                            padding: 0,
                            paddingLeft: '5px',
                            color: 'var(--laranja)'
                        }}
                    >
                        <PersonSearchIcon sx={{ color: 'var(--laranja)', fontSize: '35px' }} />
                    </CustomButton>
                </Grid>

                <Grid item xs={12} sx={{ height: 'calc(100vh - 307px)', minHeight: '350px' }}>
                    <DataGrid
                        localeText={{
                            ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                            noRowsLabel: "Nenhum vale presente encontrado"
                        }}
                        rows={gridState.rows}
                        columns={columns}
                        getRowId={(row) => row.uuid}
                        disableColumnSelector
                        loading={gridState.isLoading}
                        checkboxSelection
                        slots={{ footer: CustomDataGridFooter }}
                        slotProps={{
                            footer: { totalSelecionado: gridState.totalSelecionado },
                        }}
                        isRowSelectable={(params: GridRowParams) => {
                            return !isBeforeDate(params.row.vencimento) && !isAdicinadoPrev(params.row.uuid);
                        }}
                        onRowSelectionModelChange={selectedRowsUUIDs => setGridState(prev => ({ ...prev, selectedRowsUUIDs }))}
                        rowSelectionModel={gridState.selectedRowsUUIDs}
                    />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: { xs: '15px', lg: '0' } }} >
                    <CustomButton id={'cancelar-btn'}
                        onClick={onCancelar}
                        variant="contained"
                        color="error"
                        startIcon={
                            <Icon
                                className='fa-solid fa-xmark'
                            />
                        }
                    >
                        Cancelar
                    </CustomButton>

                    <CustomButton id={'avancar-btn'}
                        onClick={onAvancar}
                        variant="contained"
                        color="success"
                        startIcon={
                            <Icon
                                className='fa-solid fa-arrow-right'
                            />
                        }
                    >
                        Avancar
                    </CustomButton>
                </Grid>
            </Grid>
        </Container >
    );
};

export default ValePresenteBuscaAvancada;


export function CustomDataGridFooter(
    props: NonNullable<GridSlotsComponentsProps['footer']>,
) {
    return (
        <Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ flex: 4, pl: '10px', fontWeight: 700, fontSize: '15px', color: '#555' }}>Total selecionado</Typography>
            <Typography sx={{ flex: 1, textAlign: 'center', fontWeight: 700, fontSize: '20px', color: '#555' }}>{currencyOf(props.totalSelecionado)}</Typography>
        </Box>
    );
}

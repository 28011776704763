import { Box, DialogActions, DialogTitle, FormControl, Icon, Switch, TextField, Typography } from "@mui/material";
import CustomToolTips from "components/CustomToolTips";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import { useCallback, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { globalStyles } from "styles/global";
import { DescontoMaximo } from "./Configuracoes.model";

type TAutorizaDesconto = {
    onClose: () => void;
    descontoSolicitado: React.MutableRefObject<number | null>;
    onAutorizaDesconto: (username: string, password: string, switchBoolean: boolean) => void;
    descontoMaximo: DescontoMaximo;
};

const initialState = {
    username: "",
    password: "",
    switchBoolean: false,
    showSwitchInfo: false,
    showPassword: false
};

const AutorizaDesconto = (props: TAutorizaDesconto) => {
    const { descontoMaximoItem, descontoMaximoPedido } = useAppSelector(e => e.configuracoes.configuracaoDesconto);
    const [stateLocal, setStateLocal] = useState(initialState);
    const { addError } = useSnackbarCustom();

    const onChangeUsername = useCallback((username: string) => {
        setStateLocal(prevState => ({ ...prevState, username }));
    }, []);

    const onChangePassword = useCallback((password: string) => {
        setStateLocal(prevState => ({ ...prevState, password }));
    }, []);

    const toggleSwitch = () => {
        setStateLocal(prevState => ({ ...prevState, switchBoolean: !stateLocal.switchBoolean }));
    };

    const togglePasswordVisibility = () => {
        setStateLocal(prevState => ({ ...prevState, showPassword: !stateLocal.showPassword }));
    };

    const handleAutorization = () => {
        if (!stateLocal.username && !stateLocal.password) {
            return addError({ id: 'autoriza-desconto-nousername-nopassword-error', message: 'Usuário e senha são obrigatórios', preventDuplicate: false, closeable: true, persist: false });
        }
        props.onAutorizaDesconto(stateLocal.username, stateLocal.password, stateLocal.switchBoolean);
    };

    return (
        <CustomDialog
            sx={{ border: 0, margin: 0, padding: 0 }}
            id="desconto-fixo-dialog"
            open={true}
            onClose={props.onClose}
            fullWidth
        >
            <DialogTitle fontSize="24px" sx={{ fontWeight: 'bold' }} id="autoriza-desconto-item-title" textAlign={'left'}>
                Autorizar desconto
            </DialogTitle>

            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                flexDirection: "column",
                marginTop: '10px'
            }}>
                <Typography sx={{ paddingLeft: '60px', fontSize: '18px' }}>Desconto máximo no item do usuário: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {descontoMaximoItem.toFixed(2)}%</Typography>
                <Typography sx={{ paddingLeft: '60px', paddingTop: '10px', fontSize: '18px' }}>Desconto máximo no pedido do usuário: &nbsp;&nbsp;&nbsp; {descontoMaximoPedido.toFixed(2)}%</Typography>
                <Typography sx={{ paddingLeft: '60px', paddingTop: '10px', fontSize: '18px', color: globalStyles.RED }}>
                    Desconto solicitado no {props.descontoMaximo === DescontoMaximo.ITEM ? "item" : "rodapé"}:
                    {props.descontoMaximo === DescontoMaximo.ITEM ?
                        <span style={{ marginLeft: '125px' }} />
                        :
                        <span style={{ marginLeft: '100px' }} />
                    }
                    {props.descontoSolicitado.current!.toFixed(2)}%
                </Typography>

                <form autoComplete="off">
                    <FormControl sx={{ marginTop: '10px' }}>
                        <TextField
                            label="Usuário"
                            inputProps={{ id: 'autoriza-desconto-item-usuario' }}
                            sx={{ width: 'calc(90% - 45px)', marginLeft: '60px' }}
                            variant="standard"
                            value={stateLocal.username}
                            id="autoriza-desconto-item-usuario"
                            onChange={(e) => onChangeUsername(e.target.value)}
                        />
                    </FormControl>

                    <FormControl sx={{ marginTop: '10px' }} >
                        <TextField
                            label="Senha"
                            inputProps={{
                                id: 'autoriza-desconto-item-senha',

                            }}
                            sx={{ width: 'calc(90% - 45px)', marginLeft: '60px' }}
                            variant="standard"
                            value={stateLocal.password}
                            id="autoriza-desconto-item-senha"
                            onChange={(e) => onChangePassword(e.target.value)}
                            type={stateLocal.showPassword ? 'text' : 'password'}

                        />
                        <Icon
                            sx={{ cursor: 'pointer', position: 'absolute', right: "60px", top: '20px', fontSize: '18px', width: 'auto' }}
                            onClick={togglePasswordVisibility}
                            className={stateLocal.showPassword ? "fa-regular fa-eye" : "fa-solid fa-eye-slash"}
                        />
                    </FormControl>
                </form>
                {props.descontoMaximo === DescontoMaximo.ITEM &&
                    <Box sx={{ paddingLeft: '60px', marginTop: '40px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ width: '80%', fontSize: '18px' }}>Autorizar para todos itens deste pedido?</Typography>
                        <Switch color="warning" checked={stateLocal.switchBoolean} onClick={toggleSwitch} />
                        <Icon
                            sx={{
                                fontSize: '16px'
                            }}
                            onMouseOver={() => setStateLocal(prevState => ({ ...prevState, showSwitchInfo: true }))}
                            onMouseLeave={() => setStateLocal(prevState => ({ ...prevState, showSwitchInfo: false }))}
                            className="fa-solid fa-info"
                        />
                        {stateLocal.showSwitchInfo &&
                            <CustomToolTips
                                id="autoriza-desconto-info-tooltip"
                                position='absolute'
                                bottom='130px'
                                left='20px'
                                bgColor='#808080'
                                color='#FFF'
                                height='auto'
                                maxWidth={'575px'}
                                zIndex={100}
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                borderRadius='10px'
                                isVisible={true}
                                trueText='Se ativado o usuário poderá utilizar o desconto máximo autorizado para os demais itens deste pedido.'
                                falseText=''
                                autoCapitalize={false}

                            />
                        }
                    </Box>
                }
            </Box>
            <DialogActions
                sx={{
                    marginTop: '40px',
                    justifyContent: 'space-between'
                }}>
                <CustomButton onClick={props.onClose} variant="contained" color="error">
                    Não autorizar
                </CustomButton>

                <CustomButton onClick={handleAutorization} type="submit" variant="contained" color="success" >
                    Autorizar
                </CustomButton>
            </DialogActions>
        </CustomDialog >
    );
};

export default AutorizaDesconto;

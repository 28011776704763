import { IAbstractEntity, IAbstractModel } from "model/abstract.model";

export type Pessoa = IAbstractEntity & {
    tipoPessoa: string;
    status: 'ATIVO';
    dataCadastro: string;
    nomeRazaoSocial: string;
    nomeFantasia: string | null;
    nascimento: string | Date | null;
    identificacao: string;
    tipoContribuinte: string;
    inscricaoEstadual: string | null;
    inscricaoMunicipal: string | null;
    inscricaoSuframa: string | null;
    papeis: Array<string>;
    consumidorFinal: boolean;
    rg: string | null;

    enderecos: Array<Endereco>;
    contatos: Array<Contato>;
    observacoes: Array<Observacao>;
    impostosBeneficioSuframa: Array<TImpostosDeduzidos>;
    
    formaComissaoPrincipal: IAbstractModel;
    formasComissao: Array<IAbstractModel>;

    deduzIr: boolean;
    documentos: Array<never>;
    representantes: Array<never>;
    outrosComissionados: Array<never>;
};

export type Endereco = IAbstractEntity & {
    tipo: string;
    codigoPostal: string | null;
    logradouro: string;
    numero: string;
    complemento: string | null;
    bairro: string;
    municipio: IAbstractModel;
};

export type Contato = IAbstractEntity & {
    tipo: string;
    nome: string;
    telefones: Array<ContatoTelefone>;
    enderecoEletronicos: Array<ContatoEnderecoEletronico>;
};

export type ContatoTelefone = IAbstractEntity & {
    tipo: string;
    telefone: string;
};

export type ContatoEnderecoEletronico = IAbstractEntity & {
    tipo: string;
    endereco: string;
    enviaNfe: boolean;
};

export type Observacao = {
    observacao: string;
    usuario: string;
    dataHora: string;
};

export type PessoaKey = keyof Pessoa;

export type PartialPessoa = {
    [K in keyof Pessoa]?: Pessoa[K];
};

export enum TImpostosDeduzidos {
    ICMS = "ICMS",
    PIS = 'PIS',
    COFINS = 'COFINS'
}

import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from "@mui/material"
import { useCallback } from "react"
import { ISituacaoPedidoProps } from "../vendas.model"
import { useSnackbarCustom } from "hooks/useSnackCustom"

const SituacaoDocumentoFiscal = (props: ISituacaoPedidoProps) => {
    const { addError } = useSnackbarCustom();

    const handleChange = useCallback((e: any) => {
        const target = e.target.value
        if (!target.length) {
            addError({ message: "O campo Situação do documento fiscal deve ter pelo menos 1 valor selecionado", closeable: true })
            return
        }
        props.onChangeSituacao(target)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <FormControl variant="outlined"
            sx={{
                width: '30%', height: '55px'
            }}>
            <InputLabel variant="standard" id="labelTipo" >Situação do documento fiscal</InputLabel>
            <Select id="selectSituacaoDocumentoFiscal"
                disabled={props.isDisabled}
                multiple
                sx={{
                    width: '100%',
                    height: '100%'
                }}
                variant="standard"
                label="Tipo"
                value={props.situacaoValues}
                renderValue={(selected) => selected.join(', ')}
                onChange={handleChange}
            >
                {props.situacaoOptions.map((e, i) => {
                    return (
                        <MenuItem
                            key={i}
                            value={e}
                            id={`menu_item_situcao_documento_fiscal_vendas_${e}`}
                        >
                            <ListItemText primary={e} />
                            <Checkbox
                                sx={{ color: 'var(--laranja)' }}
                                checked={props.situacaoValues.indexOf(e) > -1}
                                color="warning"
                            />
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
}
export default SituacaoDocumentoFiscal
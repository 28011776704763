import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../axios/axios.config";

const MotivoService = () => {
    const path = '/motivo';
    const loginService = LoginService();

    return {
        getMotivosFinanceirosAc: (toSearch: string): Promise<AxiosResponse<any>> => {
            const nQuery = {
                first: 0,
                pageSize: 20,
                isAdvanced: true,
                advancedNQuery: {
                    logicalOperator: "AND",
                    alias: "obj",
                    subQuerys: [
                        {
                            logicalOperator: "SIMPLE",
                            criteria: {
                                field: "status",
                                comparisonOperator: "EQUAL",
                                value: "A"
                            },
                            alias: "obj"
                        },
                        {
                            logicalOperator: "AND",
                            alias: "obj",
                            subQuerys: [
                                {
                                    logicalOperator: "SIMPLE",
                                    criteria: {
                                        field: "nome",
                                        comparisonOperator: "EQUAL",
                                        value: "FINANCEIRO"
                                    },
                                    alias: "tipo"
                                }
                            ],
                            joins: [
                                {
                                    type: "INNER",
                                    table: "obj.tiposMotivo as tipo"
                                }
                            ]
                        },
                        {
                            logicalOperator: "SIMPLE",
                            criteria: {
                                field: "nome",
                                comparisonOperator: "CONTAINS",
                                value: toSearch,
                                fieldFn: "to_char(obj.nome)"
                            },
                            alias: "obj"
                        }
                    ]
                },
                orderBy: "nome ASC"
            };

            return axiosRequest.post(`${path}/acdescritivo`, nQuery, { headers: loginService.getHeaders() });
        }
    };
};

export default MotivoService;

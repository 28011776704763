import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deepReset } from "utils/util";


export enum Tipo {
    CIRCULAR = 'CIRCULAR',
    LINEAR = 'LINEAR'
}

type BlockUIState = { loading: boolean; tipo: Tipo, progress?: number, message?: string; zIndexCustom?: number; };

const initialState: BlockUIState = {
    loading: false,
    tipo: Tipo.LINEAR,
    progress: 0,
    message: 'Registrando pedido',
    zIndexCustom: 1
};

const blockuiSlice = createSlice({
    name: 'blockui',
    initialState,
    reducers: {
        loadingWithOpt: (s, { payload }: PayloadAction<BlockUIState>) => {
            s.loading = payload.loading;
            s.tipo = payload.tipo;
            s.message = payload.message;
            s.progress = payload.progress;
            s.zIndexCustom = payload.zIndexCustom;
        },
        loading: s => {
            s.loading = true;
        },
        completeLoading: s => {
            s.loading = false;
        },
        changeLinearFinalizaVenda: (s, { payload }: PayloadAction<any>) => {
            s.progress = payload.progress;
            s.message = payload.message ? payload.message : s.message;
        },
        resetLoading: (s) => {
            deepReset(s, initialState);
        }
    }
});

export const {
    loading,
    completeLoading,
    resetLoading,
    changeLinearFinalizaVenda,
    loadingWithOpt,
} = blockuiSlice.actions;

export const blockuiReducer = blockuiSlice.reducer;

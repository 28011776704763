import { Button, Typography } from "@mui/material";

export type TCustomGridOptionProps = {
    onClick: () => void;
    marginTop?: string;
    icon: React.ReactElement;
    title: string;
    disabled?: boolean
};

const CustomGridOption = (props: TCustomGridOptionProps) => {
    return (
        <Button onClick={props.onClick}
            sx={{
                width: '100%',
                bgcolor: props.disabled ? "lightgray" : '#FFF',
                height: '50px',
                marginTop: props.marginTop ?? "0",
                borderRadius: '10px',
                textTransform: 'none',
                display: 'flex',
                border: '1px solid #FF7F01',
                '&:hover': {
                    bgcolor: '#FFF',
                }

            }}>
            {props.icon}
            <Typography sx={{
                color: '#000',
                fontSize: '18px',
                textAlign: 'left',
                paddingLeft: '10px',
                width: '85%'
            }}
            >{props.title}</Typography>
        </Button>
    );
};

export default CustomGridOption;

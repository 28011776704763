import { Box, DialogActions, DialogTitle, Typography } from "@mui/material";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useAppSelector } from "redux/hooks";
import { globalStyles } from "styles/global";
import { DescontoMaximo } from "./Configuracoes.model";

type AutorizaDescontoItemErrorProps = {
    descontoSolicitado: React.MutableRefObject<number | null>;
    closeModalDescontoItemError: () => void;
    descontoMaximo: DescontoMaximo;
};

const AutorizaDescontoItemError = (props: AutorizaDescontoItemErrorProps) => {
    const { descontoMaximoItem, descontoMaximoPedido } = useAppSelector(e => e.configuracoes.configuracaoDesconto);

    return (
        <CustomDialog
            sx={{ border: 0, margin: 0, padding: 0 }}
            id="desconto-fixo-dialog"
            open={true}
            onClose={props.closeModalDescontoItemError}
            fullWidth
        >
            <DialogTitle fontSize="24px" sx={{ fontWeight: 'bold' }} id="autoriza-desconto-item-title" textAlign={'left'}>
                Desconto não autorizado!
            </DialogTitle>

            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                flexDirection: "column",
                marginTop: '10px'
            }}>

                <Typography sx={{ paddingLeft: '60px', fontSize: '18px' }}>Desconto máximo no item do usuário: <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> {descontoMaximoItem.toFixed(2)}%</Typography>
                <Typography sx={{ paddingLeft: '60px', paddingTop: '10px', fontSize: '18px' }}>Desconto máximo no pedido do usuário: <span>&nbsp;&nbsp;&nbsp;</span> {descontoMaximoPedido.toFixed(2)}%</Typography>
                <Typography sx={{ paddingLeft: '60px', paddingTop: '10px', fontSize: '18px', color: globalStyles.RED }}>
                    Desconto solicitado no {props.descontoMaximo === DescontoMaximo.ITEM ? "item" : "rodapé"}:
                    {props.descontoMaximo === DescontoMaximo.ITEM ?
                        <span style={{ marginLeft: '125px' }} />
                        :
                        <span style={{ marginLeft: '100px' }} />
                    }
                    {props.descontoSolicitado.current!.toFixed(2)}%
                </Typography>
            </Box>
            <DialogActions
                sx={{
                    marginTop: '40px',
                    justifyContent: 'flex-end'
                }}>
                <CustomButton onClick={props.closeModalDescontoItemError} variant="contained" color="error">
                    fechar
                </CustomButton>

            </DialogActions>
        </CustomDialog>
    );
};
export default AutorizaDescontoItemError;



import { Icon, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { TMenuItemRelatorioProps } from "../vendas.model";
import { useState } from "react";
import "./MenuItems.css";


const initialState = {
    showImprimir: false as boolean,
    showIrPara: false as boolean
}

const MenuItemAberto = (props: TMenuItemRelatorioProps) => {
    const [stateLocal, setStateLocal] = useState(initialState)
    return (
        <MenuList>
            <MenuItem key="menu-item-aberto-editar-pedido" onClick={props.onEditarVendedor}>
                <ListItemIcon>
                    <Icon className="fa-solid fa-pen" sx={{ fontSize: "16px" }} />
                </ListItemIcon>
                <ListItemText>Editar vendedor</ListItemText>
            </MenuItem>

            <MenuItem
                key="menu-item-faturado-imprimirA4-dropdown"
                id="menu-item-faturado-imprimirA4-dropdown"
                onClick={() => setStateLocal(prevState => ({ ...prevState, showImprimir: !prevState.showImprimir }))}
            >
                <ListItemIcon>
                    <Icon className="fa-solid fa-print" sx={{ fontSize: "18px" }} />
                </ListItemIcon>
                <ListItemText>Imprimir</ListItemText>
                <Icon
                    className={!stateLocal.showImprimir ? "fa-solid fa-angle-down" : "fa-solid fa-angle-up"}
                    sx={{ fontSize: "18px" }}
                />
            </MenuItem>

            <div className={`menu-items-container ${stateLocal.showImprimir ? 'opened' : 'closed'}`}
                id="dropdown-imprimir-menu-item-aberto"
            >
                <MenuItem
                    key="menu-item-faturado-imprimir-pre-venda"
                    id="menu-item-faturado-imprimir-pre-venda"
                    onClick={() => props.onImprimirPreVenda!(props.itemRef.current!)}
                >
                    <ListItemIcon />
                    <ListItemText>Pré-venda</ListItemText>
                </MenuItem>

                <MenuItem
                    key="menu-item-faturado-imprimir-pedido"
                    id="menu-item-faturado-imprimir-pedido"
                    onClick={() => props.onImprimirPedido!(props.itemRef.current!.uuidPedido)}
                >
                    <ListItemIcon />
                    <ListItemText>Pedido</ListItemText>
                </MenuItem>

                <MenuItem
                    key="menu-item-faturado-imprimirA4"
                    id="menu-item-faturado-imprimirA4"
                    onClick={() => props.onImprimirPedidoA4!(props.itemRef.current!.uuidPedido)}
                >
                    <ListItemIcon />
                    <ListItemText>Pedido A4</ListItemText>
                </MenuItem>
            </div>

            <MenuItem
                key="menu-item-faturado-imprimir-dropdown"
                id="menu-item-faturado-imprimir-dropdown"
                onClick={() => setStateLocal(prevState => ({ ...prevState, showIrPara: !prevState.showIrPara }))}
            >
                <ListItemIcon>
                    <Icon className="fa-solid fa-arrow-right" sx={{ fontSize: "18px" }} />
                </ListItemIcon>
                <ListItemText>Ir para</ListItemText>
                <Icon
                    className={!stateLocal.showIrPara ? "fa-solid fa-angle-down" : "fa-solid fa-angle-up"}
                    sx={{ fontSize: "18px" }}
                />
            </MenuItem>

            <div className={`menu-items-container ${stateLocal.showIrPara ? 'opened' : 'closed'}`}
                id="dropdown-ir-para-menu-item-aberto"
            >
                <MenuItem
                    key="menu-item-aberto-navega-pre-venda"
                    id="menu-item-aberto-navega-pre-venda"
                    onClick={() => props.onNavigatePreVenda!(props.itemRef.current!.uuidPedido, props.itemRef.current!.numeroPedido)}>
                    <ListItemText sx={{ paddingLeft: '37px' }}>Pré-venda</ListItemText>
                </MenuItem>

                <MenuItem key="menu-item-aberto-ir-pdv" onClick={() => props.onNavigatePDV!(props.itemRef.current!.uuidPedido, props.itemRef.current!.numeroPedido)}>
                    <ListItemText sx={{ paddingLeft: '37px' }}  >PDV</ListItemText>
                </MenuItem>
            </div>

            <MenuItem
                key="menu-item-aberto-visualizar-pedido"
                id="menu-item-aberto-visualizar-pedido"
                onClick={() => props.onVisualizar(props.itemRef.current!.uuidPedido, props.itemRef.current!.numeroPedido)}
            >
                <ListItemIcon>
                    <Icon className="fa-solid fa-eye" sx={{ fontSize: "18px", width: '20px' }} />
                </ListItemIcon>
                <ListItemText>Visualizar</ListItemText>
            </MenuItem>

            <MenuItem
                key="menu-item-aberto-cancelar-pedido"
                id="menu-item-aberto-cancelar-pedido"
                onClick={props.onCancelar}
            >
                <ListItemIcon>
                    <Icon className="fa-solid fa-xmark" sx={{ fontSize: "18px" }} />
                </ListItemIcon>
                <ListItemText>Cancelar Pedido</ListItemText>
            </MenuItem>

        </MenuList>
    );
};

export default MenuItemAberto;

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Document, Font, Image, Line, PDFViewer, Page, StyleSheet, Svg, Text } from '@react-pdf/renderer';
import { showOnlyDate } from 'utils/util';

Font.register({
    family: "Anton",
    src: "/assets/fonts/anton.ttf",
});

type Props = {
    onClose: () => void;
    numero: string;
    cliente: string;
    vendedor: string;
    emissao: Date | string;
    valorTotalLiquido: string;

};

const PreVendaPdf = (props: Props) => {

    return (
        <>
            <IconButton
                sx={{
                    zIndex: 100000,
                    position: 'absolute',
                    bottom: 20,
                    right: 30,
                    backgroundColor: 'var(--laranja)',
                    ':hover': {
                        backgroundColor: 'var(--laranja)',
                        filter: 'grayscale(.2) brightness(.8) contrast(1.5)'
                    }
                }}
                onClick={props.onClose}
            >
                <CloseIcon sx={{ color: '#fff', fontSize: 45 }} />
            </IconButton>
            <PDFViewer
                style={s.pdfView}
                showToolbar={true}
            >
                <Document
                    title='Pré-venda'
                    author='André Franco'
                    language='pt-BR'
                >
                    <Page
                        size={'B7'}
                        orientation='landscape'
                        style={{ ...s.page, backgroundColor: '#F99F46' }}

                    >
                        <Image
                            style={s.img2}
                            source={'assets/shopCar.png'}
                        />

                        <Text style={s.page2title}>Pedido #{props.numero}</Text>

                        <Text style={[s.page2label, {
                            marginTop: "8px"

                        }]}>Cliente</Text>
                        <Text style={s.page2value}>{props.cliente.substring(0, 27)}{props.cliente.length >= 27 && "..."} </Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>

                        <Text style={s.page2label}>Vendedor</Text>
                        <Text style={s.page2value}>{props.vendedor.substring(0, 27)}{props.vendedor.length >= 27 && "..."}</Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>

                        <Text style={s.page2label}>Emissão</Text>
                        <Text style={s.page2value}>{props.emissao ? showOnlyDate(props.emissao.toString()) : showOnlyDate(new Date().toString())}</Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>

                        <Text style={s.page2label}>Total</Text>
                        <Text style={s.page2value}>{props.valorTotalLiquido}</Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );
};

const s = StyleSheet.create({
    pdfView: {
        position: 'absolute',
        width: '100%',
        height: '100vh',
        border: 0,
        top: 0,
        left: 0,
        zIndex: 1000
    },
    page: {
        position: 'relative',
    },
    img2: {
        position: 'absolute',
        minWidth: '50%',
        minHeight: '50%',
        display: 'flex',
        height: '150px',
        width: '96px',
        bottom: '30px',
        left: '16px',
        objectFit: 'cover'
    },

    mainText: {
        fontSize: '70px',
        padding: '0',
        color: '#4B4B4B',
        marginTop: '0',
        marginLeft: '20px',
        fontFamily: "Anton",
        fontWeight: 'extrabold',
    },
    valor: {
        fontSize: '20px',
        padding: '0',
        color: '#4B4B4B',
        marginLeft: '20px',
        fontFamily: "Anton",
        fontWeight: 'extrabold',
    },
    svg: {
        position: 'absolute',
    },
    page2title: {
        fontSize: '30px',
        padding: '0',
        color: '#4B4B4B',
        marginTop: '8px',
        fontFamily: "Anton",
        fontWeight: 'extrabold',
        textAlign: 'center',
        borderTop: "1px",
        borderBottom: "1px"
    },
    page2label: {
        fontSize: '15px',
        height: '35px',
        width: '60%',
        color: '#000',
        fontWeight: 'extralight',
        padding: '0px',
        marginTop: '0px',
        marginLeft: '120px',
    },
    page2value: {
        fontSize: '12px',
        height: '35px',
        width: '60%',
        color: '#000',
        fontWeight: 'normal',
        padding: '0px',
        marginTop: '-16px',
        marginLeft: '120px',
        paddingLeft: '1px'
    }
});

export default PreVendaPdf;

import { AxiosHeaders, AxiosResponse } from "axios";
import { IToken } from "model/token.model";
import { useNavigate } from "react-router-dom";
import { resetToken } from "redux/features/tokenSlice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { axiosRequest } from "../../axios/axios.config";

const LoginService = () => {
    const token = useAppSelector((state) => state.token);
    const estabelecimentoSelecionado = useAppSelector((state) => state.estabelecimentoSelecionado);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { token: tokenAutorizador } = useAppSelector(e => e.configuracoes.configuracaoDescontoAutorizador);

    return ({
        loga: (login: { user: string, password: string; }): Promise<AxiosResponse<IToken>> => {
            return axiosRequest.post('/login', login);
        },
        logout: () => {
            dispatch(resetToken());
            navigate('/login');
        },
        getRoles: () => {
            return token.NEXUS_ROLES;
        },
        getBaseUrl: () => {
            return window.location.origin.replace('3000', '8080') + '/spring/api/v0';
        },
        getEstabelecimentos: () => {
            return token.ESTABELECIMENTOS;
        },
        getHeaders: (): AxiosHeaders => {
            const headers = new AxiosHeaders();
            headers.set('access_token', token.access_token);
            headers.set('es', estabelecimentoSelecionado.uuid);
            return headers;
        },
        getHeadersWithAutorizadorToken: (): AxiosHeaders => {
            const headers = new AxiosHeaders();
            headers.set('access_token', token.access_token);
            headers.set('es', estabelecimentoSelecionado.uuid);
            headers.set('access_token_autorizador', tokenAutorizador);
            return headers;
        }
    });
};

export default LoginService;

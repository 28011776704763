import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

type TCustomIFrameProps = {
    url: string;
    onClose?: () => void;
};

const CustomIFrame = (props: TCustomIFrameProps) => {

    return (
        <>
            <Box
                sx={{
                    zIndex: 100000,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100vh',
                    width: '100%',
                    boxSizing: "border-box",
                }}
            >

                <iframe
                    src={props.url}
                    title='frame-danfe-title'
                    style={{
                        border: 'none',
                        height: '100vh',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                />
                {props.onClose &&
                    <IconButton
                        sx={{
                            position: 'absolute',
                            bottom: 20,
                            right: 30,
                            backgroundColor: 'var(--laranja)',
                            ':hover': {
                                backgroundColor: 'var(--laranja)',
                                filter: 'grayscale(.2) brightness(.8) contrast(1.5)'
                            }
                        }}
                        onClick={props.onClose}
                    >
                        <CloseIcon sx={{ color: '#fff', fontSize: 45 }} />
                    </IconButton>}
            </Box>

        </>
    );
};

export default CustomIFrame;

import {  Chip, Icon, Tooltip } from "@mui/material";
import { IAbstractModel } from "model/abstract.model";
import { memo } from "react";

type CredenciadoraCardProps = {
    credenciadora: IAbstractModel,
    onSelectCredenciadora: (uuid: string) => void;
    credenciadoraSelecionada: IAbstractModel | null
}

const CredenciadoraCard = (props: CredenciadoraCardProps) => {
    return (
        <Tooltip title={props.credenciadora?.descritivo} key={props.credenciadora?.uuid}>
        <Chip sx={{
            margin: '5px',
            bgcolor: 'transparent',
            border: '1px solid var(--laranja)',
            height: "50px",
            minWidth: '240px',
            padding: '10px',
            pointerEvents: props.credenciadoraSelecionada ? 'none' : 'auto',
        }} 
        label={props.credenciadora?.descritivo}
        key={props.credenciadora?.uuid}
        onClick={() => !props.credenciadoraSelecionada && props.onSelectCredenciadora(props.credenciadora.uuid)}
        icon={<Icon sx={{
            fontSize: '18px',
            width: '25px',
            padding: '3px',
            pointerEvents: props.credenciadoraSelecionada ? 'none' : 'auto' 
        }}
                className="fa-regular fa-credit-card"
                color="action"
                />
            }
        />
        </Tooltip>
    )
}

export default memo(CredenciadoraCard);
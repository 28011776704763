import { Box, Icon } from "@mui/material";
import { IIconsProps } from "./icons.model";

const MiniMoneyPlus = (props: IIconsProps) => {
    return (
        <Box sx={{ position: 'relative', left: props.left ? props.left : '-20px' }}>
            <Icon className="fa-solid fa-money-bill-1-wave" style={{ fontSize: props.fontSize ?? 50, height: '20px', color: props.color, width: '30px' }} />
            <Icon className="fa-solid fa-circle-minus" style={{ fontSize: 15, color: props.color, position: 'absolute', right: '-5px', bottom: '5px' }} />
        </Box>
    );
};

export default MiniMoneyPlus;

import { Box, TextField, Typography } from "@mui/material"

const DialogUser = (props: any) => {
    return (
        <Box sx={{ ...props.style }}>
            <Typography sx={{ fontSize: '16px' }}>Usuário</Typography>
            <TextField fullWidth variant="standard" value={props.user} disabled id="username" />
        </Box>
    )
}

export default DialogUser
import { AxiosHeaders, AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import { ConfiguracaoDescontoTO, DescontoMaximo, UsuarioTO, rowGrid } from "./Configuracoes.model";
import { IAbstractModel } from "model/abstract.model";

const ConfiguracaoDescontoService = () => {
    const path = '/configuracaodesconto';
    const loginService = LoginService();

    return {
        getUsuarios(): Promise<AxiosResponse<Array<UsuarioTO>>> {
            return axiosRequest.get(`usuario/usuarios`, { headers: loginService.getHeaders() });
        },
        getConfiguracoes(): Promise<AxiosResponse<Array<rowGrid>>> {
            return axiosRequest.get(`${path}`, { headers: loginService.getHeaders() });
        },
        saveAllConfiguracoes(listaConfiguracoesDescontoTO: Array<rowGrid>): Promise<AxiosResponse<Array<rowGrid>>> {
            return axiosRequest.post(`${path}/all`, listaConfiguracoesDescontoTO, { headers: loginService.getHeaders() });
        },
        updateConfiguracao(configuracaoDescontoTO: rowGrid): Promise<AxiosResponse<rowGrid>> {
            return axiosRequest.put(`${path}`, configuracaoDescontoTO, { headers: loginService.getHeaders() });
        },
        deleteConfiguracao(uuid: string): Promise<AxiosResponse<rowGrid>> {
            return axiosRequest.put(`${path}/delete/${uuid}`, {}, { headers: loginService.getHeaders() });
        },
        findByUserName(nomeusuario: string): Promise<AxiosResponse<ConfiguracaoDescontoTO>> {
            return axiosRequest.get(`${path}/nome/${nomeusuario}`, { headers: loginService.getHeaders() });
        },
        validaDescontoSolicitado(descontoSolicitado: number, descontoMaximo: DescontoMaximo): Promise<AxiosResponse<boolean>> {
            return axiosRequest.get(`${path}/validatedesconto/${descontoSolicitado}/${descontoMaximo}`, { headers: loginService.getHeaders() });
        },
        getConfiguracaoDescontoAutorizador(username: string, tokenAutorizador: string, estabelecimentoUUID: string): Promise<AxiosResponse<ConfiguracaoDescontoTO>> {
            const headers = new AxiosHeaders();
            headers.set('access_token', tokenAutorizador);
            headers.set('es', estabelecimentoUUID);
            return axiosRequest.get(`${path}/nome/${username}`, { headers: headers });
        },
        getCobrancas(): Promise<AxiosResponse<Array<IAbstractModel>>> {
            return axiosRequest.get(`flow/cobrancas`, { headers: loginService.getHeaders() });
        },
        updateCobranca(cobrancaTO: IAbstractModel): Promise<AxiosResponse<IAbstractModel>> {
            return axiosRequest.put(`configuracoes/pedidobalcao/updatecobranca`, cobrancaTO, { headers: loginService.getHeaders() });
        },
        getCobrancaPadrao(): Promise<AxiosResponse<IAbstractModel>> {
            return axiosRequest.get(`configuracoes/pedidobalcao/cobrancapadrao`, { headers: loginService.getHeaders() });
        }
    };
};

export default ConfiguracaoDescontoService;

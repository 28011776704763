import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { Defs, Document, Font, Image, Line, LinearGradient, PDFViewer, Page, Rect, Stop, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';

Font.register({
    family: "Anton",
    src: "/assets/fonts/anton.ttf",
});

const s = StyleSheet.create({
    pdfView: {
        position: 'absolute',
        width: '100%',
        height: '100vh',
        border: 0,
        top: 0,
        left: 0,
        zIndex: 1000
    },
    page: {
        position: 'relative',
    },
    img1: {
        position: 'absolute',
        minWidth: '50%',
        minHeight: '50%',
        display: 'flex',
        height: '65%',
        width: '75%',
        top: '30px',
        right: '-40px'
    },
    img2: {
        position: 'absolute',
        minWidth: '50%',
        minHeight: '50%',
        display: 'flex',
        height: '150px',
        width: '96px',
        bottom: '0px',
        left: '16px'
    },
    estrela: {
        position: 'absolute',
        minWidth: '20%',
        minHeight: '20%',
        display: 'flex',
    },
    infoEstabelecimento: {
        fontSize: '10px',
        padding: '0',
        height: '40px',
        width: '60%',
        textAlign: 'center',
        color: '#4B4B4B',
        marginTop: '5px',
        marginLeft: '20px',
        fontWeight: 'extralight',
    },
    mainText: {
        fontSize: '70px',
        padding: '0',
        color: '#4B4B4B',
        marginTop: '0',
        marginLeft: '20px',
        fontFamily: "Anton",
        fontWeight: 'extrabold',
    },
    valor: {
        fontSize: '20px',
        padding: '0',
        color: '#4B4B4B',
        marginTop: '-10px',
        marginLeft: '20px',
        fontFamily: "Anton",
        fontWeight: 'extrabold',
    },
    barcode: {
        height: '62px',
        width: '250px',
        position: 'absolute',
        right: '-87px',
        top: '94px',
        transform: 'rotate(-90deg)',
        backgroundColor: 'white',
        padding: '5px',
        paddingBottom: '2px'
    },
    code: {
        fontSize: '8px',
        textAlign: 'center'
    },
    svg: {
        position: 'absolute',
    },
    page2title: {
        fontSize: '40px',
        padding: '0',
        color: '#4B4B4B',
        marginTop: '10px',
        marginLeft: '120px',
        fontFamily: "Anton",
        fontWeight: 'extrabold',
    },
    page2label: {
        fontSize: '15px',
        height: '40px',
        width: '60%',
        color: '#000',
        fontWeight: 'extralight',
        padding: '0px',
        marginTop: '0px',
        marginLeft: '120px',
    },
    page2value: {
        fontSize: '12px',
        height: '40px',
        width: '60%',
        color: '#4B4B4B',
        fontWeight: 'extralight',
        padding: '0px',
        marginTop: '-20px',
        marginLeft: '120px',
    }
});

type Props = {
    barCodeUrl: string;
    onClose: () => void;
    dados: {
        code: string;
        valor: string;
        estabelecimento: string;
        cliente: string;
        destinatario: string;
        validade: string;
    };
};

const ValePresentePdf = (props: Props) => {

    return (
        <>
            <IconButton
                sx={{
                    zIndex: 100000,
                    position: 'absolute',
                    bottom: 20,
                    right: 30,
                    backgroundColor: 'var(--laranja)',
                    ':hover': {
                        backgroundColor: 'var(--laranja)',
                        filter: 'grayscale(.2) brightness(.8) contrast(1.5)'
                    }
                }}
                onClick={props.onClose}
            >
                <CloseIcon sx={{ color: '#fff', fontSize: 45 }} />
            </IconButton>
            <PDFViewer
                style={s.pdfView}
                showToolbar={true}
            >
                <Document
                    title='Vale presente'
                    author={''}
                    language='pt-BR'
                >
                    <Page
                        size={'B7'}
                        orientation='landscape'
                        style={{ ...s.page, backgroundColor: '#FAA757' }}
                    >

                        <Svg viewBox="0 0 220 150" style={s.svg}>
                            <Rect
                                transform='translate(-10px, -60px)'
                                x="120"
                                rx="50"
                                ry="50"
                                width="100"
                                height="200"
                                fill="#BDA593"
                            />
                        </Svg>

                        <Image
                            style={s.img1}
                            source={'assets/gift.png'}
                        />

                        <Image
                            style={{
                                ...s.estrela,
                                height: '40px',
                                width: '40px',
                                top: '50px',
                                left: '150px'
                            }}
                            source={'assets/estrela.png'}
                        />

                        <Image
                            style={{
                                ...s.estrela,
                                height: '60px',
                                width: '60px',
                                top: '185px',
                                left: '200px'
                            }}
                            source={'assets/estrela.png'}
                        />

                        <Text style={{ ...s.infoEstabelecimento, }}>{props.dados.estabelecimento}</Text>
                        <Text style={{ ...s.mainText, marginTop: '-10px' }} >VALE</Text>
                        <Text style={{ ...s.mainText, marginTop: '-30px' }}>PRESENTE</Text>

                        <Text style={{ ...s.valor }}>{props.dados.valor}</Text>

                        <View style={s.barcode}>
                            <Image
                                source={props.barCodeUrl}
                            />
                            <Text style={s.code}>{props.dados.code}</Text>
                        </View>

                    </Page>

                    <Page
                        size={'B7'}
                        orientation='landscape'
                        style={{ ...s.page, backgroundColor: '#F99F46' }}

                    >

                        <Svg viewBox="0 0 220 150" style={{ ...s.svg, bottom: 0 }}>
                            <Defs>
                                <LinearGradient
                                    x1={0}
                                    x2={5}
                                    y1={0}
                                    y2={2}
                                    id="myLinearGradient">
                                    <Stop offset="10%" stopColor="#BDA593" />
                                    <Stop offset="95%" stopColor="#444" />
                                </LinearGradient>

                            </Defs>
                            <Rect
                                transform='translate(-10px, 10px)'
                                x="20"
                                rx="30"
                                ry="30"
                                width="60"
                                height="200"
                                fill="url('#myLinearGradient')"
                            />
                        </Svg>

                        <Image
                            style={s.img2}
                            source={'assets/gifthands.png'}
                        />

                        <Text style={s.page2title}>Vale presente</Text>

                        <Text style={s.page2label}>PARA</Text>
                        <Text style={s.page2value}>{props.dados.destinatario}</Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>

                        <Text style={s.page2label}>DE</Text>
                        <Text style={s.page2value}>{props.dados.cliente}</Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>

                        <Text style={s.page2label}>EXPIRA</Text>
                        <Text style={s.page2value}>{props.dados.validade}</Text>
                        <Svg
                            height="10"
                            width="335"
                            style={{ marginTop: '-20px' }}>
                            <Line
                                x1="118"
                                y1="0"
                                x2="335"
                                y2="0"
                                strokeWidth={3}
                                stroke="#555"
                            />
                        </Svg>
                    </Page>
                </Document>
            </PDFViewer>
        </>
    );
};

export default ValePresentePdf;

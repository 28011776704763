
import { IIconsProps } from "components/Icons/icons.model"

const CustomPercent = (props: IIconsProps) => {
    return (
        <i id={props.id} className="fa-solid fa-percent" style={{ fontSize: props.fontSize, color: props.color, ...props.style }} />
    )
}

export default CustomPercent

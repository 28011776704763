import { Icon, Typography } from "@mui/material";
import CustomToolTips from "components/CustomToolTips";
import { memo, useState } from "react";
import { IconOpt, TooltipPosition } from "utils/models";

type StatusPedidoCircleDisplayProps = {
    content: string;
    index: number;
    isIcon?: boolean;
    iconOpt?: IconOpt;
    tooltipPosition?: TooltipPosition;
};

const initialState = {
    isFocused: false
};

const TableCellWithTooltip = (props: StatusPedidoCircleDisplayProps) => {

    const getTooltipPosition = () => {
        let x = '0';
        let y = '0';
        switch (props.tooltipPosition) {
            case 'right':
                x = '100%';
                break;
            case 'left':
                x = '-100%';
                break;
            case 'top':
                x = '-20%';
                y = 'calc(-100% + -5px)';
                break;
            case 'bottom':
                x = '-20%';
                y = '45px';
                break;
            case 'center':
                break;
            default:
                break;
        }

        return `translate(${x}, ${y})`;
    };


    const [stateLocal, setStateLocal] = useState(initialState);
    return (
        <Typography
            onMouseOver={() => setStateLocal(prevState => ({ ...prevState, isFocused: true }))}
            onMouseLeave={() => setStateLocal(prevState => ({ ...prevState, isFocused: false }))}
            sx={{ fontSize: '0.875rem', width: '100%' }}>
            {props.isIcon
                ? <Icon
                    className={props.iconOpt?.className}
                    sx={{
                        marginTop: '3px',
                        marginBottom: '0',
                        fontSize: `${props.iconOpt!.sizePx ?? 18}px`,
                        color: props.iconOpt?.color,
                        width: 'fit-content'
                    }} />
                : `${props.content.substring(0, 15)}${props.content.length >= 15 ? '...' : ''}`
            }
            {stateLocal.isFocused && props.content &&
                <CustomToolTips
                    position='absolute'
                    top='0px'
                    left='0px'
                    bgColor='#808080'
                    color='#FFF'
                    height='auto'
                    width="150%"
                    zIndex={100}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    borderRadius='10px'
                    isVisible={true}
                    trueText={props.content}
                    falseText=""
                    autoCapitalize={false}
                    id={`tablecell-tooltip-item-${props.index}`}
                    transform={getTooltipPosition()}
                />
            }


        </Typography>
    );
};

export default memo(TableCellWithTooltip);

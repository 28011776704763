import { Autocomplete, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Icon, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import dayjs from 'dayjs';
import { useFormValidator, useValidators } from 'hooks/useFormValidator';
import { IAbstractModel } from 'model/abstract.model';
import ConfiguracaoValePresenteService from 'pages/Configuracoes/configuracaovalepresente/configuracaovalepresente.service';
import RegistraVendaService from 'pages/registra-venda/registra-venda.service';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { resetValePresente, setValePresente } from 'redux/features/valePresenteSlice';
import { useAppDispatch } from 'redux/hooks';
import { equalsObj } from 'utils/util';
import { ValePresenteDialogFormState, ValePresenteDialogProps, ValePresenteState } from './vale-presente.model';

const initialState: ValePresenteState = {
    consumidoresOptions: [],
    submitted: false,
    configValePresente: null,
};

const ValePresenteDialog = (props: ValePresenteDialogProps) => {
    const registraVendaService = RegistraVendaService();
    const configValePresenteService = ConfiguracaoValePresenteService();
    const clienteRef = useRef<HTMLInputElement>(null);
    const valorRef = useRef<HTMLInputElement>(null);
    const [stateLocal, setStateLocal] = useState(initialState);
    const { required } = useValidators();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const getValidadeValue = (validadeDias: number = 90) => {
        let toReturn = dayjs(new Date());
        return toReturn.add(validadeDias > 0 ? validadeDias : 90, 'day').toISOString();
    };

    const {
        getRawValue,
        setControlValue,
        getControlAllErrors,
        disableControl,
        isDisabledControl,
        resetForm,
        formIsValid,
    } = useFormValidator<ValePresenteDialogFormState>({
        controls: [
            { inputName: 'cliente', value: null, validators: [required] },
            { inputName: 'destinatario', value: null },
            { inputName: 'valor', value: 0, validators: [required] },
            { inputName: 'validade', value: getValidadeValue(), validators: [required] },
        ]
    });

    useEffect(() => {
        if (props.open) {
            getConsumidoresAc();
            getConfiguracaoValePresente();
        }
        //eslint-disable-next-line
    }, [props.open]);

    useEffect(() => {
        if (props.open) {
            clienteRef.current && clienteRef.current.focus();
        }
        //eslint-disable-next-line
    }, [clienteRef.current]);

    const getConfiguracaoValePresente = () => {
        configValePresenteService.getConfiguracao()
            .then(({ data }) => {
                setStateLocal(prev => ({ ...prev, configValePresente: data }));
                if (data.validadeDias && data.validadeDias > 0) {
                    disableControl('validade');
                    setControlValue('validade', getValidadeValue(data.validadeDias));
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const getConsumidoresAc = (toSearch: string = '') => {
        registraVendaService.getConsumidoresAc(toSearch)
            .then(({ data }) => {
                setStateLocal(prev => ({ ...prev, consumidoresOptions: data }));
            }).catch(err => {
                console.log(err);
            });
    };

    const onInputChangeConsumidoresAc = (e: unknown, value: string) => {
        getConsumidoresAc(value);
    };

    const onBlurConsumidores = () => {
        if (!getRawValue('cliente')?.uuid) {
            getConsumidoresAc();
        }
    };

    const onSelectConsumidor = (e: unknown, value: IAbstractModel | null) => {
        setControlValue('cliente', value);
    };

    const onFocusValor = () => {
        if (getRawValue('valor') === 0) {
            return valorRef.current?.setSelectionRange(4, 4);
        }
    };

    const onClose = () => {
        props.setOpen(false);
        setTimeout(() => {
            resetForm();
            setStateLocal(prev => ({ ...prev, submitted: false }));
            dispatch(resetValePresente());
        }, 200);
    };

    const onConfirm = (e: FormEvent) => {
        e.preventDefault();
        setStateLocal(prev => ({ ...prev, submitted: true }));
        if (formIsValid()) {
            dispatch(setValePresente(getRawValue() as ValePresenteDialogFormState));
            navigate('/vale-presente');
        }
    };

    const hasError = (field: keyof ValePresenteDialogFormState) => {
        return stateLocal.submitted && !equalsObj(getControlAllErrors(field), {});
    };

    return (
        <CustomDialog
            open={props.open}
            maxWidth={'xs'}
            fullWidth
            onClose={onClose}
        >

            <form onSubmit={onConfirm} style={{ gap: 0 }}>
                <DialogTitle id="dialog-title" textAlign={'center'}>
                    <Typography fontSize={30}>Vale presente</Typography>
                </DialogTitle>

                <DialogContent id="dialog-content" sx={{ display: 'flex', flexDirection: 'column', gap: '15px', alignItems: 'center' }}>

                    <FormControl fullWidth variant="standard" error={hasError('cliente')}>
                        <Autocomplete
                            onBlur={onBlurConsumidores}
                            onInputChange={onInputChangeConsumidoresAc}
                            onChange={onSelectConsumidor}
                            value={getRawValue('cliente')}
                            fullWidth
                            id="ac-cliente"
                            options={stateLocal.consumidoresOptions}
                            getOptionLabel={(option) => option.descritivo}
                            filterOptions={(x) => x}
                            noOptionsText={'Nenhum resultado encontrado'}
                            isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        error={hasError('cliente')}
                                        label="Cliente *"
                                        variant="standard"
                                        inputRef={clienteRef}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (params.InputProps.endAdornment)
                                        }}
                                    />
                            }
                        />
                        {hasError('cliente') &&
                            <FormHelperText id={`ac-cliente-helper-text`} color={'error'}>Este campo é obrigatório</FormHelperText>
                        }
                    </FormControl>

                    <FormControl fullWidth variant="standard">
                        <TextField
                            id={`destinatario-input`}
                            inputProps={{ maxLength: 60 }}
                            fullWidth={true}
                            variant="standard"
                            label='Vale presente para'
                            onChange={(e) => {
                                const value = e.target.value;
                                setControlValue('destinatario', value !== '' ? value : null);
                            }}
                            value={getRawValue('destinatario') ?? ''}
                        />
                    </FormControl>

                    <FormControl fullWidth variant="standard" error={hasError('valor')}>
                        <NumericFormat
                            id="valor-input"
                            error={hasError('valor')}
                            onFocus={onFocusValor}
                            decimalScale={2}
                            label='Valor *'
                            inputRef={valorRef}
                            fixedDecimalScale
                            allowedDecimalSeparators={[',']}
                            customInput={TextField}
                            decimalSeparator=","
                            prefix={'R$ '}
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            variant="standard"
                            value={getRawValue('valor') ?? 0}
                            allowNegative={false}
                            onValueChange={({ floatValue }) => {
                                setControlValue('valor', floatValue);
                            }}
                        />
                        {hasError('valor') &&
                            <FormHelperText id={`ac-cliente-helper-text`} color={'error'}>Valor deve ser maior que R$ 0,00</FormHelperText>
                        }
                    </FormControl>


                    <FormControl fullWidth variant="standard">
                        <DatePicker
                            minDate={dayjs(new Date())}
                            disabled={isDisabledControl('validade')}
                            slotProps={{
                                textField: {
                                    id: `validade-input`,
                                    variant: 'standard',
                                    onKeyDown: (e) => { e.preventDefault(); },
                                }
                            }}
                            label='Valido até *'
                            format='DD/MM/YYYY'
                            sx={{
                                width: '100%',
                            }}
                            value={dayjs(getRawValue('validade'))}
                            onChange={(e: any) => setControlValue('validade', e.$d.toISOString())}
                        />
                    </FormControl >
                </DialogContent>

                <DialogActions
                    sx={{
                        justifyContent: 'space-between'
                    }}>
                    <CustomButton onClick={onClose} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                        Cancelar
                    </CustomButton>
                    <CustomButton type="submit" variant="contained" color="success" startIcon={<Icon className='fa-solid fa-arrow-right' />}>
                        Avançar
                    </CustomButton>
                </DialogActions>
            </form>


        </CustomDialog>
    );
};

export default ValePresenteDialog;

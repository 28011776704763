import { Autocomplete, Box, TextField } from "@mui/material"
import { SyntheticEvent, useEffect, useRef, useState } from "react"
import CaixaService from "./caixa.service"
import { IAbstractModel } from "model/abstract.model"
import { TipoDialog, TipoGrupoConta } from "./caixa.model"

const initialState: { suggestionsConta: Array<IAbstractModel> } = {
    suggestionsConta: []
}

const ContaAutoComplete = (props: { contaSelecionada: IAbstractModel | null, onsetContaSelecionada: (conta: IAbstractModel | null) => void, title: string }) => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const service = CaixaService();
    const contaRef = useRef();

    const getTipoGrupoConta = () => {
        if (props.title === TipoDialog.REFORCAR) {
            return [TipoGrupoConta.RECEITA, TipoGrupoConta.OUTRAS]
        }
        return [TipoGrupoConta.DESPESA, TipoGrupoConta.OUTRAS]
    }

    const getContaAc = (toSearch: string = '') => {
        service.getContas(toSearch, getTipoGrupoConta())
            .then(({ data }) => {
                setStateLocal(prev => ({ ...prev, suggestionsConta: data }))
            })
            .catch(err => console.log(err))
    };

    useEffect(() => {
        getContaAc()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.contaSelecionada])


    const onInputChangeContaAc = (e: SyntheticEvent<Element, Event>) => {
        const input = e?.nativeEvent.target as HTMLInputElement;
        getContaAc(input?.value);
    };

    const onSelectConta = (e: SyntheticEvent<Element, Event>, value: IAbstractModel | null) => {
        props.onsetContaSelecionada(value)
    };

    const inputLabelProps = {
        sx: {
            fontSize: '20px'
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Autocomplete
                onBlur={() => props.contaSelecionada && getContaAc()}
                value={props.contaSelecionada}
                id="ac_conta"
                options={stateLocal.suggestionsConta}
                getOptionLabel={(option) => option.descritivo}
                onInputChange={onInputChangeContaAc}
                filterOptions={(x) => x}
                noOptionsText={'Nenhum resultado encontrado'}
                onChange={onSelectConta}
                isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                renderInput={
                    (params) => <TextField {...params}
                        InputLabelProps={inputLabelProps}
                        label="Conta"
                        variant="standard"
                        inputRef={contaRef}
                    />}
            />
        </Box>
    )
}

export default ContaAutoComplete
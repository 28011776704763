import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { CustomButton } from 'components/roundedbutton/CustomButton';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { useEffect, useRef, useState } from 'react';
import { setCpfNota, stateSlice } from 'redux/features/stateSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

interface Props {
  open: boolean;
  onClose: () => void;
  data?: ICpfNota | null;
}

export interface ICpfNota {
  identificacao?: string;
  nome?: string;
}

const defaultValue: ICpfNota = { identificacao: '', nome: '' };

export default function CpfNota(props: Props) {
  const { open, onClose } = props;
  const [cpfNotaLocal, setCpfNotaLocal] = useState<ICpfNota>(defaultValue);
  const dispatch = useAppDispatch();
  const cpfNotaSelector = useAppSelector(state => state.state.pedido.cpfNota);
  const { addError } = useSnackbarCustom();
  const cpfNotaRef = useRef<HTMLInputElement>();
  const cliente = useAppSelector(e => e.state.pedido.cliente);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        cpfNotaRef.current?.focus();
      });
    }
  }, [open]);


  useEffect(() => {
    if (props.data) {
      const identificacao = mascaraCpfCnpj(props.data.identificacao ?? '');
      Object.assign({}, props.data.identificacao, { identificacao });
      setCpfNotaLocal(props.data);
    } else {
      setCpfNotaLocal(defaultValue);
    }
    if (cpfNotaSelector) {
      setCpfNotaLocal(cpfNotaSelector);
    }
  }, [props.data, cpfNotaSelector]);

  const handleChangeIdentificacao = (value: string) => {
    if (cpfCnpjValidate(value)) {
      setCpfNotaLocal(prevCpfNota => ({ ...prevCpfNota, identificacao: value }));
    }
  };

  const handleChangeNome = (nome: string) => {
    setCpfNotaLocal(prevCpfNota => ({ ...prevCpfNota, nome }));
  };
  // COMENTADO ATÉ QUE SEJA NECESSÁRIO
  // const handleChangeEmail = (email: string) => {
  //   setCpfNotaLocal(prevCpfNota => ({ ...prevCpfNota, email }));
  // };

  const handleResetCPF = () => {
    dispatch(setCpfNota(defaultValue));
    setCpfNotaLocal(defaultValue);
  };

  const onSave = () => {
    if (cliente && (!cpfNotaLocal.identificacao && !cpfNotaLocal.nome)) {
      dispatch(stateSlice.actions.setCpfNota({
        identificacao: props.data?.identificacao,
        nome: props.data?.nome
      }));
      return onClose();
    }

    if (cpfNotaLocal.identificacao === null || cpfNotaLocal.identificacao === undefined || cpfNotaLocal.identificacao === "") {
      dispatch(stateSlice.actions.setCpfNota(cpfNotaLocal));
      onClose();
    } else if (!cpfCnpjValidate(cpfNotaLocal.identificacao).cpfCnpjInvalid) {
      dispatch(stateSlice.actions.setCpfNota(cpfNotaLocal));
      onClose();
    } else {
      addError({ message: 'CPF ou CNPJ inválido', closeable: true, hideDuration: 5000 });
    }
  };

  const handleCancel = () => {
    setCpfNotaLocal(defaultValue);
    dispatch(setCpfNota({ nome: "", identificacao: "" }));
    onClose();
  };

  return (
    <form>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle style={{ fontWeight: 800, textAlign: "center", fontSize: 40 }}>CPF na nota?</DialogTitle>
        <DialogContent>
          <TextField
            inputRef={cpfNotaRef}
            margin="dense"
            id="identificacaoCpfaa"
            label="CPF / CNPJ"
            value={mascaraCpfCnpj(cpfNotaLocal.identificacao ?? '') ?? ''}
            type="text"
            fullWidth
            variant="standard"
            onChange={e => { handleChangeIdentificacao(e.target.value); }}
            error={cpfNotaLocal.identificacao ? cpfCnpjValidate(cpfNotaLocal.identificacao)?.cpfCnpjInvalid : false}
            helperText={
              cpfNotaLocal.identificacao ? cpfCnpjValidate(cpfNotaLocal.identificacao)?.msg : ''
            }
          />

          <TextField
            margin="dense"
            id="nomeCpf"
            label="Nome"
            value={cpfNotaLocal?.nome ?? ''}
            type="text"
            fullWidth
            onChange={e => { handleChangeNome(e.target.value); }}
            variant="standard"
          />

          {/* 
          COMENTADO ATÉ QUE SEJA NECESSÁRIO
          <TextField
            margin="dense"
            id="emailCpf"
            label="E-mail"
            value={cpfNotaLocal?.email ?? ''}
            onChange={(e) => handleChangeEmail(e.target.value)}
            type="email"
            fullWidth
            variant="standard"
          /> */}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'space-around', pb: 2
          }}>
          {cliente === null &&
            <CustomButton onClick={handleCancel} variant="contained" color="error" >
              Cancelar
            </CustomButton>
          }
          <CustomButton onClick={handleResetCPF} variant="contained" color="warning" >
            Limpar
          </CustomButton>

          <CustomButton onClick={onSave} type="submit" variant="contained" color="success" >
            Salvar
          </CustomButton>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export const mascaraCpfCnpj = (value: string) => {
  const normalizado = normalizaNumericos(value);
  if (normalizado.length <= 11) {
    return normalizado
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{2})$/, '$1-$2');
  } else {
    return normalizado
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d{2})$/, '$1-$2');
  }
};

export const normalizaNumericos = (value: string) => {
  return value.replace(/\D/g, '');
};

export const cpfCnpjValidate = (value: string) => {
  const normalized = normalizaNumericos(value ?? '');
  const numberDigits = normalized.split('').map(Number);
  const regexAllEqual = /^(\d)\1+$/;

  const calculateDigit = (numberPlace: number) => {
    const total = numberDigits
      .slice(0, numberPlace - 1)
      .map((digit, index) => {
        let multiplier = numberPlace - index;
        if (numberPlace > 11 && index < numberPlace - 9) {
          multiplier -= 8;
        }
        return digit * multiplier;
      })
      .reduce((acc, value) => acc + value, 0);
    const remainder = total % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  if (normalized.length === 11) { // cpf
    if (regexAllEqual.test(normalized)) {
      return { cpfCnpjInvalid: true, msg: 'Tamanho inválido (CPF deve ser 11 dígitos.)' };
    }

    const first = calculateDigit(10);
    const second = calculateDigit(11);

    if (first !== numberDigits[9] || second !== numberDigits[10]) {
      return { cpfCnpjInvalid: true, msg: 'CPF inválido.' };
    }
    return { cpfCnpjInvalid: false };
  } else if (normalized.length === 14) { // cnpj
    if (regexAllEqual.test(normalized)) {
      return { cpfCnpjInvalid: true, msg: 'Tamanho inválido (CNPJ deve ser 14 dígitos.)' };
    }
    const first = calculateDigit(13);
    const second = calculateDigit(14);

    if (first !== numberDigits[12] || second !== numberDigits[13]) {
      return { cpfCnpjInvalid: true, msg: 'CNPJ inválido.' };
    }
    return { cpfCnpjInvalid: false };
  } else {
    return { cpfCnpjInvalid: true, msg: 'Tamanho inválido' };
  }
};

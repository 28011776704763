import { Autocomplete,  Box,  Card,   Container, DialogActions, DialogTitle, FormControl, FormHelperText, Icon, InputLabel, MenuItem, Select,  TextField     } from "@mui/material"
import { FormaPagamentoDialogContentProps } from "../formapagamento.model"
import {  useCallback, useEffect,  useState } from "react"
import { currencyOf, extrairNumeroInfoAdicionalDescritivo, roundHalfToEven } from "utils/util";
import { useAppSelector } from "redux/hooks";
import { IAbstractModel } from "model/abstract.model";
import CredenciadoraCard from "components/CredenciadoraCard";
import BandeiraCard from "components/BandeiraCard";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { NumericFormat } from "react-number-format";
import { BandeiraCartaoTO, CredenciadoraTaxaTO, FormaPagamentoName, TDadosCartao, TipoInformacaoAdicional } from "pages/pagamento/pagamento.model";
import NsuNumControle from "pages/pagamento/NsuNumControle";


const initialState = {
    telas: {
        credenciadora: true as boolean,
        bandeira: false as boolean,
        modoPagamento: false as boolean
    },
    credenciadoraSelecionada:  null as  IAbstractModel | null,
    bandeiraSelecionada:  null as BandeiraCartaoTO | null,
    modoPagamentoSelecionado: null as CredenciadoraTaxaTO | null,
    filtroModoPagamento: "" as string,
    nsu: "" as string,
    numControle: "" as string
}

const CartoesDialogContent: React.FC<FormaPagamentoDialogContentProps & {
     handleGenericFormaPagamento: (title: FormaPagamentoName, handle: boolean) => void, dadosPagamentoCartao: TDadosCartao}> =
      ({ isNew, error, valorRecebido, onClose, handleGenericFormaPagamento, dadosPagamentoCartao }) => {

    const { dadosPagamento, setDadosPagamento  } = valorRecebido;
    const [valueDiff, setValueDiff] = useState(dadosPagamento.valorRecebido); // setValueDiff
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const [stateLocal, setStateLocal] = useState(initialState);
    const { hasError, setHasError } = error;

    useEffect(() => {
        if (isNew) {
            setDadosPagamento(prev => ({ valorRecebido: faltaReceber, infoAdicional: undefined }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (valueDiff !== dadosPagamento.valorRecebido)
            setValueDiff(dadosPagamento.valorRecebido);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosPagamento.valorRecebido]);

    const selectCredenciadora = useCallback((uuid: string) => {
        const credenciadora =  dadosPagamentoCartao.credenciadoras.find(e => e.uuid === uuid);
        if (credenciadora) {
            setStateLocal(prevState => ({...prevState,
                 credenciadoraSelecionada: credenciadora,
                 telas: {
                    credenciadora: false,
                    bandeira: true,
                    modoPagamento: false
                 }
            }))
            setDadosPagamento(prev => ({ ...prev,
                infoAdicional: {
                    ...prev.infoAdicional!,
                    dadosCartao: {
                        ...prev.infoAdicional?.dadosCartao!,
                        credenciadora: credenciadora
                    }
                }
             }));
        }
          //eslint-disable-next-line
    },[])

    const selectBandeira = useCallback((bandeiraCartaoTO: BandeiraCartaoTO) => {
        const bandeira = dadosPagamentoCartao.bandeiras
        .filter(e => e.credenciadoraUUID === stateLocal.credenciadoraSelecionada?.uuid)[0]?.bandeiras
        .find(e => e === bandeiraCartaoTO);

        if (bandeira) {
            setStateLocal(prevState => ({...prevState,
                bandeiraSelecionada: bandeira,
                telas: {
                   credenciadora: false,
                   bandeira: false,
                   modoPagamento: true
                }
           }))
           setDadosPagamento(prev => ({ ...prev,
            infoAdicional: {
                ...prev.infoAdicional!,
                dadosCartao: {
                    ...prev.infoAdicional?.dadosCartao!,
                    bandeiraCartao: bandeira
                }
            }
         }));
        }
        //eslint-disable-next-line
    },[stateLocal.credenciadoraSelecionada])

    const getDisabled = () => {
        return !(stateLocal.credenciadoraSelecionada && stateLocal.bandeiraSelecionada && stateLocal.modoPagamentoSelecionado);
    }

    const onGoBack = () => {
        if (stateLocal.telas.credenciadora && onClose) {
            onClose("CARTOES");
        }
        if (stateLocal.telas.bandeira) {
            setStateLocal(prevState => ({...prevState, 
                credenciadoraSelecionada: null,
                telas: {
                    credenciadora: true,
                    bandeira:false,
                    modoPagamento:false
                }
            }))
        }
        if (stateLocal.telas.modoPagamento) {
            setStateLocal(prevState => ({...prevState, 
                bandeiraSelecionada: null,
                telas: {
                    credenciadora: false,
                    bandeira:true,
                    modoPagamento:false
                },
                modoPagamentoSelecionado: null,
                filtroModoPagamento: "",
                nsu: '',
                numControle: ''
            }))
        }
    }

    const getModoPagamentos = () => {
        const modosPagamento = dadosPagamentoCartao.modosPagamento
        .find(e => e.credenciadoraUUID === stateLocal.credenciadoraSelecionada?.uuid
            && e.bandeiraUUID === stateLocal.bandeiraSelecionada?.uuidDadoCredenciadora
        )?.modosPagamentos

        return (modosPagamento && modosPagamento?.filter(e => e.modoPagamento.toUpperCase().includes(stateLocal.filtroModoPagamento.toUpperCase()))) ?? []
        //eslint-disable-next-line
    }

    const isCreditoParcelado = useCallback((modoPagamento?: string) : boolean => {
        if (!modoPagamento || modoPagamento === "DÉBITO" || modoPagamento === "CRÉDITO A VISTA" || modoPagamento === "PIX") return false
        return true
    },[])

    const onChangeValorRecebido = (value: number = 0) => {
            setDadosPagamento(prev => ({ ...prev, 
            valorRecebido: value,
            infoAdicional: {
                ...prev.infoAdicional!,
                numeroParcelas: extrairNumeroInfoAdicionalDescritivo(prev.infoAdicional!.dadosCartao?.modoPagamento?.modoPagamento ?? null) ?? 1,
                tipo: prev.infoAdicional?.tipo ?? TipoInformacaoAdicional.CARTAO,
                descritivo: isCreditoParcelado(prev.infoAdicional?.dadosCartao?.modoPagamento?.modoPagamento) ? getParcelasDescritivo(
                    extrairNumeroInfoAdicionalDescritivo(
                        prev.infoAdicional?.dadosCartao?.modoPagamento?.modoPagamento ?? null
                    ) ?? 1, 
                    value
                ) : "",
            }
         }));
         if (roundHalfToEven(value, 2) > roundHalfToEven(faltaReceber, 2)) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    };

    const getParcelasValue = (): number | '' => {
        const parcelas = dadosPagamento.infoAdicional?.numeroParcelas ?? 1;
        return parcelas;
    };

    const onChangePrazos = (modoPagamento: CredenciadoraTaxaTO | null) => {
        if (!modoPagamento) return;
        
        setDadosPagamento(prev => ({ ...prev,
            infoAdicional: {
                    ...prev.infoAdicional!,
                    numeroParcelas: extrairNumeroInfoAdicionalDescritivo(modoPagamento.modoPagamento) ?? 1,
                    tipo: prev.infoAdicional?.tipo!,
                    descritivo: isCreditoParcelado(modoPagamento.modoPagamento) ? 
                    getParcelasDescritivo(extrairNumeroInfoAdicionalDescritivo(modoPagamento.modoPagamento) ?? 1, dadosPagamento.valorRecebido) 
                    : "",
                    dadosCartao: {
                        ...prev.infoAdicional?.dadosCartao!,
                        modoPagamento: modoPagamento,
                    }
                }
            }));
        //eslint-disable-next-line
    }

    const getParcelasDescritivo = useCallback((parcela: number, valorRecebido: number) => {
        return `${parcela}x - ${currencyOf(valorRecebido / parcela)}`;
    },[]);
    
    const changeNsu = useCallback((value: string) => {
        setStateLocal(prevState => ({ ...prevState, nsu: value }))
    }, [])

    const changeNumControle = useCallback((value: string) => {
        setStateLocal(prevState => ({ ...prevState, numControle: value }))
    }, [])

    useEffect(() => {
        setDadosPagamento(prev => ({ ...prev,
            infoAdicional: {
                descritivo: prev.infoAdicional?.descritivo ?? "",
                numeroParcelas: prev.infoAdicional?.numeroParcelas ?? 1,
                tipo: prev.infoAdicional?.tipo ?? TipoInformacaoAdicional.CARTAO,
                dadosCartao: {
                    ...prev.infoAdicional?.dadosCartao!,
                    nsu: stateLocal.nsu,
                    numeroControle: stateLocal.numControle
                }
            } })
        );
        //eslint-disable-next-line
    },[stateLocal.nsu, stateLocal.numControle])
   

    useEffect(() => {
        if (!stateLocal.modoPagamentoSelecionado) {
            setDadosPagamento(prev => ({ ...prev,
                infoAdicional: {
                    descritivo: prev.infoAdicional?.descritivo ?? "",
                    numeroParcelas: prev.infoAdicional?.numeroParcelas ?? 1,
                    tipo: TipoInformacaoAdicional.CARTAO,
                    dadosCartao: prev.infoAdicional?.dadosCartao
                } })
            );
        }
        //eslint-disable-next-line
    },[stateLocal.modoPagamentoSelecionado])

    useEffect(() => {
        if (isNew) {
           return setDadosPagamento(prev => ({
                valorRecebido: faltaReceber,
                infoAdicional: {
                    numeroParcelas: 1,
                    descritivo: '',
                    tipo: TipoInformacaoAdicional.CARTAO,
                    dadosCartao: { 
                        nsu: null,
                        numeroControle: null,
                        bandeiraCartao: null,
                        credenciadora: null,
                        modoPagamento: null,
                    }
                }
            }));
        }
        return setStateLocal(prevState => ({
            ...prevState,
            nsu: dadosPagamento.infoAdicional?.dadosCartao?.nsu ?? "",
            numControle: dadosPagamento.infoAdicional?.dadosCartao?.numeroControle ?? "",
            credenciadoraSelecionada: dadosPagamento.infoAdicional?.dadosCartao?.credenciadora ?? null,
            bandeiraSelecionada: dadosPagamento.infoAdicional?.dadosCartao?.bandeiraCartao ?? null,
            modoPagamentoSelecionado: dadosPagamento.infoAdicional?.dadosCartao?.modoPagamento ?? null,
            telas: {
                credenciadora: false,
                bandeira: false,
                modoPagamento: true
            },
        }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getScreenTitle = useCallback(() => {
        return stateLocal.telas.credenciadora && !dadosPagamentoCartao.credenciadoras.length ? "Sem credenciadoras ativas" 
            : stateLocal.telas.credenciadora ? " Selecione a credenciadora" 
            : stateLocal.telas.bandeira ? " Selecione a bandeira" 
            : stateLocal.telas.modoPagamento && " Selecione a forma de pagamento"
            //eslint-disable-next-line
    },[stateLocal.telas])

    return (
            <>
             <DialogTitle
                sx={{
                    whiteSpace: 'nowrap',
                    fontSize: "24px",
                    fontWeight: 'bold',
                    color: '#666666',
                    userSelect: "none"
                }}
            >{getScreenTitle()}
            </DialogTitle>
                <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ width: '50rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    error={false}
                >
                    {stateLocal.telas.credenciadora && 
                    <Container sx={{
                        display: 'grid',
                        gridTemplateColumns: "repeat(3,1fr)",
                        maxHeight: '35vh',
                        overflow: 'auto'
                    }}>
                       {dadosPagamentoCartao.credenciadoras.map((credenciadora) => {
                        return (
                        <CredenciadoraCard 
                            key={credenciadora.uuid}
                            credenciadora={credenciadora}
                            onSelectCredenciadora={selectCredenciadora}
                            credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                            />
                        );
                    })} 
                    </Container>
                    }
                    {stateLocal.telas.bandeira && 
                    <Container sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'     
                    }}>
                     <CredenciadoraCard 
                            key={stateLocal.credenciadoraSelecionada?.uuid}
                            credenciadora={stateLocal.credenciadoraSelecionada!}
                            onSelectCredenciadora={selectCredenciadora}
                            credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                        />
                        <Icon className="fa-solid fa-chevron-right" sx={{
                            fontSize: '50px',
                            color:  "lightgray",
                            position: 'absolute',
                            left: '45%'
                        }}/>
                        <BandeiraCard 
                            key={stateLocal.bandeiraSelecionada?.uuidDadoCredenciadora}
                            credenciadoraSelecionada={stateLocal.credenciadoraSelecionada!}
                            bandeiras={dadosPagamentoCartao.bandeiras}
                            bandeiraSelecionada={stateLocal.bandeiraSelecionada}
                            onSelectBandeira={selectBandeira}
                        />
                    </Container>
                    }
                    
                    {stateLocal.telas.modoPagamento && 
                    <>
                        <Container sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            
                        <CredenciadoraCard 
                            key={stateLocal.credenciadoraSelecionada?.uuid}
                            credenciadora={stateLocal.credenciadoraSelecionada!}
                            onSelectCredenciadora={selectCredenciadora}
                            credenciadoraSelecionada={stateLocal.credenciadoraSelecionada}
                        />
                          <Icon className="fa-solid fa-chevron-right" 
                          sx={{
                            color:  "lightgray",
                            fontSize: '40px',
                            paddingLeft: '10px',
                            paddingRight: '10px'
                        }}/>
                        <BandeiraCard 
                            key={stateLocal.bandeiraSelecionada?.uuidDadoCredenciadora}
                            credenciadoraSelecionada={stateLocal.credenciadoraSelecionada!}
                            bandeiras={dadosPagamentoCartao.bandeiras}
                            bandeiraSelecionada={stateLocal.bandeiraSelecionada}
                            onSelectBandeira={selectBandeira}
                        />
                        </Container>
                        <FormHelperText sx={{
                            fontSize: '24px',
                            marginTop: '20px'
                        }}>Valor</FormHelperText>
                        <NumericFormat 
                            id="generic_forma_pagamento_div"
                            value={dadosPagamento.valorRecebido}
                            onValueChange={({ floatValue }) => onChangeValorRecebido(floatValue)}
                            error={hasError}
                            decimalScale={2}
                            fixedDecimalScale
                            defaultValue={0}
                            allowedDecimalSeparators={[',']}
                            customInput={TextField}
                            decimalSeparator=","
                            prefix={'R$ '}
                            thousandsGroupStyle="thousand"
                            thousandSeparator="."
                            variant="standard"
                            allowNegative={false}
                            inputProps={{
                                id: "generic_forma_pagamento_value",
                                style: {
                                    textAlign: 'center',
                                    fontSize: '24px',
                                    color: hasError ? 'var(--error)' : '#df6e0b',
                                    fontWeight: 700,
                                    fontFamily: 'Work Sans, sans-serif'
                                }
                            }}
                    />
                    {hasError &&
                    <FormHelperText
                        sx={{
                            whiteSpace: 'nowrap',
                            color:  'var(--error)' 
                        }}
                    >
                        O valor não pode ser maior que a venda :(
                    </FormHelperText>}

                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                        marginTop: '40px'
                    }}>
                    <Autocomplete sx={{
                            width: '40%'
                        }}
                        value={stateLocal.modoPagamentoSelecionado}
                        id="acModoPagamento"
                        options={getModoPagamentos()}
                        getOptionLabel={(option) => option.modoPagamento}
                        onInputChange={(e, value) => {
                            setStateLocal(prevState => ({...prevState,
                                filtroModoPagamento: value
                            }))
                        }}
                        filterOptions={(x) => x}
                        noOptionsText={'Nenhum resultado encontrado'}
                        onChange={(e, modoPagamento) => {
                            onChangePrazos(modoPagamento)
                            setStateLocal(prevState => ({...prevState,
                                modoPagamentoSelecionado: modoPagamento
                            }))
                        }}
                        isOptionEqualToValue={(option, value) => option.uuidCredenciadoraTaxa === value.uuidCredenciadoraTaxa && option.modoPagamento === value.modoPagamento}
                        renderInput={
                            (params) => <TextField
                                {...params}
                                label={`Modo pagamento *`}
                                variant="standard"
                            />}
                    />
                      <FormControl fullWidth variant="standard" sx={{ width: '15rem' }} error={false}>
                      <InputLabel id="prazos-label">Parcelas</InputLabel>
                      <Select
                          labelId="demo-simple-select-label"
                          id="parcelas_select"
                          value={getParcelasValue()}
                          disabled
                          MenuProps={{
                              PaperProps: {
                                  style: {
                                      maxHeight: 48 * 4.5 + 8,
                                      width: 100
                                  },
                              },
                          }}
                      >
                          {Array.from([].constructor(12)).map((s, index) => {
                              const parcela = ++index;
                              return (
                                  <MenuItem
                                      id={`menu_item_outros_${index}`}
                                      key={index}
                                      value={parcela}
                                  >
                                      {getParcelasDescritivo(parcela, dadosPagamento.valorRecebido)}
                                  </MenuItem>
                              );
                          })}
                      </Select>
                  </FormControl>
                    </Box>
                    <FormControl fullWidth variant="standard" sx={{ width: '100%' }} error={false}>
                <NsuNumControle
                    nsu={stateLocal.nsu}
                    numControle={stateLocal.numControle}
                    onChangeNsu={changeNsu}
                    onChangeNumControle={changeNumControle}
                />
            </FormControl>
                    </>
                    }
                </FormControl>
    
                <DialogActions
                sx={{
                    justifyContent: 'space-between',
                    width: '90%'
                }}>
                <CustomButton onClick={onGoBack} variant="contained" color="error" startIcon={<Icon className={!stateLocal.credenciadoraSelecionada ? "fa-solid fa-close" : "fa-solid fa-arrow-left"} />}>
                    {!stateLocal.credenciadoraSelecionada ? "Cancelar" : "Voltar"}
                </CustomButton>
                {stateLocal.telas.modoPagamento && 
                    <Card sx={{ borderRadius: 5, textAlign: 'center', height: '100px', aspectRatio: '7/3', padding: '0 !important' }} className="card-totalizador-pedido">
                        <h2 id="cartao_credito_falta_receber_value" className="valor-totalizador">{valueDiff <= faltaReceber ? currencyOf(faltaReceber - valueDiff) : currencyOf(faltaReceber)}</h2>
                        <h4 id="catao_credito_falta_receber_titulo" className="label-totalizador">Falta receber</h4>
                    </Card>
                }
                <CustomButton  type="submit" disabled={getDisabled()} variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                    Confirmar
                </CustomButton>
            </DialogActions>
            </>
    )
}

export default CartoesDialogContent
import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import { GenericModel } from './../../model/generic.model';

export type TConsultaEstoque = {
    estabelecimento: string,
    tabelaPreco: string | null;
    sku: {
        uuid: string;
        descritivo: string;
        preco: number | null;
        estoque: {
            uuidDeposito: string;
            nomeDeposito: string;
            saldoEstoque: number;
        };
    };
};

const ConsultaEstoqueService = () => {
    const path = '/flow';
    const loginService = LoginService();

    return {
        getConsultaEstoque(skuUuid: string, tabelaPrecoUuid: string, estabelecimentosUuid: Array<String>): Promise<AxiosResponse<TConsultaEstoque[]>> {
            return axiosRequest.get(`${path}/estoque/consulta/${skuUuid}`,
                {
                    headers: loginService.getHeaders(),
                    params: {
                        tabelaPrecoUuid: tabelaPrecoUuid,
                        estabelecimentosUuids: estabelecimentosUuid.join(','),
                    },
                });
        },
        getTabelaPreco(toSearch: string): Promise<AxiosResponse<Array<GenericModel>>> {
            return axiosRequest.get(`tabelapreco/configuracaopedidobalcao`,
                {
                    headers: loginService.getHeaders(),
                    params: {
                        nome: toSearch,
                    }
                });
        },

    };

};

export default ConsultaEstoqueService;

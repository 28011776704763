import { Box } from "@mui/material"
import { IIconsProps } from "./icons.model"

const MoneyTriple = (props: IIconsProps) => {
    return (
        <Box sx={{ height: '70px', position: 'relative', left: props.left ?? '-20px' }}>
            {Array.from([].constructor(3)).map((s, i) => (
                <i key={i} id={`${props.id}_${i}`} className="fa-solid fa-money-bill-1-wave" style={{ position: 'absolute', fontSize: '50px', color: props.color, top: `${20 * (i - 1)}px`, left: `${40 * (i - 1)}px` }} />
            ))}
        </Box>
    )
}

export default MoneyTriple
import axios from "axios";

const jsf: string = '/spring/api/v0';
const localHost: string = 'localhost';
const nipio: string = '.nip.io';
const nipio_interno: string = '127.0.0.1.nip.io';
const flowUrl: string = '.flow.peon.moda';
const nexusUrl: string = '.nexus.ind.br';

export const toNexus = (url: string, hostname: string) => {
    let minhaUrl: string = '';
    if (url.startsWith(localHost)) {
        minhaUrl = 'http://' + localHost + ':8080' + jsf;
    } else {
        if (hostname.endsWith(nipio_interno)) {
            minhaUrl = 'http://' + hostname + ':8080' + jsf;
        } else if (hostname.endsWith(nipio)) {
            minhaUrl = 'https://' + url.split(flowUrl)[0] + nexusUrl + jsf;
        } else {
            minhaUrl = 'https://' + url.replace(flowUrl, nexusUrl) + jsf;
        }
    }
    return minhaUrl;
};
export const axiosRequest = axios.create({
    baseURL: `${toNexus(window.location.host, window.location.hostname)}`,
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
    }
});

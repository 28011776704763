import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Icon, SpeedDial, SpeedDialAction, SpeedDialIcon, SxProps, Theme } from '@mui/material';
import { useGenerateUuid } from 'hooks/useGenerateUuid';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/hooks';
import useCustomLocation from 'utils/useCustomLocation';

export type ActionProps = {
    id?: string;
    label: string;
    icon: string;
    onClick: () => void;
};

export type FloatingActionsProps = {
    id?: string;
    actions: Array<ActionProps>;
    mainButttonSx?: SxProps<Theme>;

    backdropProps?: {
        invisible?: boolean;
        zIndex?: number;
    };
    isVisualizar?: boolean
};

const FloatingActions = (props: FloatingActionsProps) => {

    const [randomUuid] = useGenerateUuid();
    const [open, setOpen] = useState(false);
    const { cliente } = useAppSelector(s => s.state.pedido);
    const { isTelaRegistraVenda } = useCustomLocation();

    const isDisabled = useCallback((a: ActionProps) => {
        if (!cliente?.descritivo && a.label === "Finalizar venda NF-e") {
            return true;
        }
        return false;
    }, [cliente]);

    return (
        <>
            <Backdrop
                onClick={() => setOpen(false)}
                invisible={props.backdropProps?.invisible ?? false}
                open={open}
                sx={{ zIndex: props.backdropProps?.zIndex ?? 10 }}
            />
            <SpeedDial
                id={props.id ? props.id : randomUuid()}
                ariaLabel="Floating actions"
                sx={props.mainButttonSx ? props.mainButttonSx : { marginBottom: '-3px', zIndex: 10 }}
                icon={<SpeedDialIcon icon={<Icon baseClassName={'fa-solid fa-notes-medical'} />} openIcon={<CloseIcon />} />}
                onClick={() => !props.isVisualizar && setOpen(prev => !prev)}
                open={open}
                FabProps={{
                    size: 'medium',
                    sx: {
                        backgroundColor: props.isVisualizar ? "#9F9F9F" : 'var(--laranja)', '&:hover': { backgroundColor: props.isVisualizar ? "#9F9F9F" : 'var(--laranja)', filter: 'grayscale(.2) brightness(.8) contrast(1.5)' },

                    }
                }}
            >
                {props.actions.map(a => (
                    !isTelaRegistraVenda() && a.label === "Brinde (Alt + B)" ? <></>
                        : <SpeedDialAction
                            id={a.id ? a.id : randomUuid({ prefix: 'action-' })}
                            key={randomUuid()}
                            sx={{
                                '& .MuiSpeedDialAction-staticTooltipLabel': {
                                    whiteSpace: 'nowrap'
                                },
                                '.MuiSpeedDialAction-staticTooltipLabel': {
                                    backgroundColor: isDisabled(a) ? '#707070' : '#FFF',
                                },
                                cursor: "pointer"
                            }}
                            icon={<i className={a.icon} style={{ fontSize: '20px', color: 'var(--laranja)' }} />}
                            FabProps={{ disabled: isDisabled(a) }}
                            tooltipTitle={a.label}
                            tooltipOpen
                            onClick={!isDisabled(a) ? a.onClick : () => { }}
                        />
                ))}
            </SpeedDial >
        </>
    );
};

export default FloatingActions;

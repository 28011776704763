import BaseLayout from 'layout/base-layout/BaseLayout';
import Caixa from 'pages/Caixa/Caixa';
import Configuracoes from 'pages/Configuracoes/Configuracoes';
import RelatorioFechamento from 'pages/RelatorioFechamento/RelatorioFechamento';
import Venda from 'pages/Venda/Venda';
import Login from 'pages/login/login';
import Pagamento from 'pages/pagamento/pagamento';
import ValePresenteTelaPagamento from 'pages/vale-presente/ValePresenteTelaPagamento';
import { Navigate, createBrowserRouter } from "react-router-dom";
import Guard from "./auth/Guard";
import RegistraVenda from "./pages/registra-venda/registra-venda";
import ValePresenteBuscaAvancada from 'pages/vale-presente/ValePresenteBuscaAvancada';

export const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Guard allowedRoles={['ROLE_PEDIDO_BALCAO']}><BaseLayout /></Guard>,
            children: [
                {
                    path: '',
                    index: true,
                    element:
                        <Navigate
                            replace={true}
                            to={{ pathname: '/caixa' }}
                        />,
                },
                { path: 'home', element: <h1>Home</h1> },
                { path: "registra-venda", element: <RegistraVenda key="registra-venda" /> },
                { path: "pagamento", element: <Pagamento /> },
                { path: "caixa", element: <Caixa /> },
                { path: "venda", element: <Venda /> },
                { path: "configuracoes", element: <Configuracoes /> },
                { path: "relatorio/caixa/fechamento", element: <RelatorioFechamento /> },
                { path: "vale-presente", element: <ValePresenteTelaPagamento /> },
                { path: "vale-presente/busca-avancada", element: <ValePresenteBuscaAvancada /> },
                { path: "pre-venda", element: <RegistraVenda key="pre-venda" /> },
            ],
        },
        { path: 'login', element: <Login /> },
    ]
);

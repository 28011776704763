import { MenuItem, Select, Typography } from "@mui/material";

type CustomSelectRecord<T> = Record<keyof T, string | number | null>;

export type CustomSelectProps<T extends CustomSelectRecord<T>> = {
    id: string;
    label: string;
    value?: T[keyof T];
    onSelect: (e: string | number) => void;
    options: Array<Partial<T>>;
    optionLabel: keyof T;
    optionValue: keyof T;
    marginBottom?: string;
};

export const CustomSelect = <T extends CustomSelectRecord<T>>(props: CustomSelectProps<T>) => {
    return <>
        <Typography
            id={`${props.id}-select-label`}
            color='#666'
        >
            {props.label}
        </Typography>
        <Select
            id={`${props.id}-select`}
            sx={{
                width: '100%',
                marginBottom: props.marginBottom ? props.marginBottom : '15px',
            }}
            value={props.value}
            variant='standard'
            onChange={e => props.onSelect(e.target.value ?? '')}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 100,
                    },
                },
            }}
        >
            {props.options.map((item, index) => {
                return (
                    <MenuItem
                        id={`${props.id}-item-${index}`}
                        key={index}
                        value={item[props.optionValue] ?? ''}
                    >
                        {item[props.optionLabel]}
                    </MenuItem>
                );
            })}
        </Select>
    </>;
};

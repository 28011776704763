import { Box, TextField } from "@mui/material"

const NsuNumControle = (props: { nsu: string, numControle: string, onChangeNsu: (value: string) => void, onChangeNumControle: (value: string) => void }) => {
    return (
        <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly', marginTop: '25px', marginBottom: '25px', width: '100%'}}>
            <TextField
                multiline
                id="nsu-textField"
                variant="standard"
                value={props.nsu}
                onChange={(e) => props.onChangeNsu(e.target.value)}
                label="NSU"
                inputProps={{ maxLength: 30 }}
            />

            <TextField
                multiline
                id="numero-controle-textField"
                variant="standard"
                value={props.numControle}
                onChange={(e) => props.onChangeNumControle(e.target.value)}
                label="Número de controle"
                inputProps={{ maxLength: 30 }}
            />
        </Box>
    )
}

export default NsuNumControle
import { Button, Grid, Icon, Typography } from '@mui/material';
import CashRegister from 'components/Icons/CashRegister';
import DialogContext from 'components/confirm-dialog-context';
import CustomSettings from 'pages/Venda/CustomSettings';
import ShoppingCar from 'pages/Venda/ShoppingCar';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetDescontoAutorizador, setDeposito, setTabelaPreco } from 'redux/features/configuracoes';
import { resetDesconto } from 'redux/features/descontoFixoSlice';
import { resetState } from 'redux/features/stateSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import "./sidemenu.style.css";
import PreVendaIcon from 'components/Icons/PreVendaIcon';
import { resetPagamento } from 'redux/features/pagamentoSlice';

const SideMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dialog = useContext(DialogContext);
    const pedido = useAppSelector(s => s.state.pedido);
    const { token: tokenAutorizador } = useAppSelector(e => e.configuracoes.configuracaoDescontoAutorizador);
    const configuracaoBalcao = useAppSelector(s => s.configuracoes.configuracaoPedidoBalcao);

    const limpaTudo = () => {
        dispatch(resetState());
        dispatch(resetDesconto());
        dispatch(resetPagamento())
        if (tokenAutorizador) {
            dispatch(resetDescontoAutorizador());
        }
        dispatch(setDeposito(configuracaoBalcao.depositoPadrao));
        dispatch(setTabelaPreco(configuracaoBalcao.tabelaPrecoPadrao));

    };

    const handleNavigate = (page: string) => {
        if (tokenAutorizador.length > 0 && !pedido.itens.length && !pedido.itensTroca.length) {
            dispatch(resetDescontoAutorizador());
            return navigate(`/${page}`);
        }
        if (pedido.itens.length || pedido.itensTroca.length) {
            dialog.confirm({
                title: 'Atenção',
                message: 'Você possui um pedido em aberto. Ao retornar, todo o processo será perdido. Deseja continuar? ',
                onConfirm: () => {
                    limpaTudo();
                    return navigate(`/${page}`);
                }
            });
        }
        else {
            return navigate(`/${page}`);
        }
    };

    const onNavigatePreVenda = () => {
        if (pedido.itens.length || pedido.itensTroca.length) {
            dialog.confirm({
                title: 'Atenção',
                message: 'Você possui um pedido em aberto. Ao retornar, todo o processo será perdido. Deseja continuar? ',
                onConfirm: () => {
                    limpaTudo();
                    return navigate(`/pre-venda`);
                }
            });
        }
        return navigate(`/pre-venda`);
    };

    const iconColor = '#FFEBDA';

    return (
        <div
            className={`helper-container ${isOpen ? 'open' : ''}`}
            onMouseLeave={() => { setIsOpen(false); }}
        >
            <div className="helper-content">
                <Grid id="side_menu_actions_grid" sx={{ flexDirection: 'column' }} style={{ width: '100%', alignItems: 'center', display: 'flex', marginBottom: '180px' }}>
                    <Button id="side_menu_caixa" sx={{ flexDirection: 'column', paddingLeft: '50px', paddingRight: '50px' }} onClick={handleNavigate.bind(null, "caixa")}>
                        <CashRegister id="side_menu_cashRegister_caixa" color={iconColor} fontSize='45px' />
                        <Typography id="side_menu_cashRegister_caixa_titulo" sx={{ fontSize: '18px', color: iconColor, fontFamily: 'Work Sans' }}>Caixa</Typography>
                    </Button>

                    <Button sx={{ flexDirection: 'column', marginTop: '20px', }} onClick={onNavigatePreVenda}>
                        <PreVendaIcon id="side_menu_pre_venda_icon" color={iconColor} fontSize='45px' />
                        <Typography sx={{ fontSize: '16px', color: iconColor, fontFamily: 'Work Sans' }}>Pré-venda</Typography>
                    </Button>

                    <Button sx={{ flexDirection: 'column', marginTop: '20px', paddingLeft: '40px', paddingRight: '40px' }} onClick={handleNavigate.bind(null, "venda")}>
                        <ShoppingCar id="sideMenuShoppingCar" color={iconColor} fontSize='45px' />
                        <Typography sx={{ fontSize: '18px', color: iconColor, fontFamily: 'Work Sans' }}>Vendas</Typography>
                    </Button>
                    <Button sx={{ flexDirection: 'column', marginTop: '20px', position: 'absolute', bottom: '70px' }} onClick={handleNavigate.bind(null, "configuracoes")}>
                        <CustomSettings id="sideMenuShoppingCar" color={iconColor} fontSize='45px' />
                        <Typography sx={{ fontSize: '16px', color: iconColor, fontFamily: 'Work Sans' }}>Configurações</Typography>
                    </Button>
                </Grid>
            </div>
            <button
                id="open_side_menu_btn"
                className='btn'
                onMouseEnter={() => { setIsOpen(true); }}
                onClick={() => {
                    setIsOpen(false);
                }}
            >
                <Icon id="opened_side_menu_icon" className="fa-solid fa-chevron-left icon"></Icon>
                <Icon id="closed_side_menu_icon" className="fa-regular fa-lightbulb icon"></Icon>
            </button>
        </div >
    );
};

export default SideMenu;

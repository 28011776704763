import { Box, TextField, Typography } from "@mui/material"

interface IMotivoObservacao {
    title: string,
    motivoObservacao: string | undefined,
    onSetMotivoObservacao: (motivoObservacao: string) => void
}

const MotivoObservacao = (props: IMotivoObservacao) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: '20px' }}>{props.title}</Typography>
            <TextField variant="standard" sx={{ width: '100%' }}
                inputProps={{ maxLength: 200, id: "motivo_observacao" }}
                multiline
                value={props.motivoObservacao} //setStateLocal(prevState => ({ ...prevState, value: e.target.value }))
                onChange={e => props.onSetMotivoObservacao(e.target.value)}
            />
        </Box>
    )
}

export default MotivoObservacao
import { Box, TextField, Typography } from "@mui/material"
import { convertDate } from "utils/util"

const DataAbertura = (props: { date: string | undefined }) => {

    return (
        <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: '16px' }}>Data e Hora da Abertura</Typography>
            <TextField fullWidth variant="standard" disabled value={convertDate(props.date!)} id="data_abertura" />
        </Box>
    )
}

export default DataAbertura
import React, { useState } from 'react';

export interface DialogConfirm {
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel?: () => void;
  error?: boolean
}


const defaultValue = {
  confirm: (config: DialogConfirm) => { },
  alert: (config: DialogConfirm) => { },
  open: false,
  setOpen: (open: boolean) => { },
  config: {} as DialogConfirm,
}

export type DialogContextProps = typeof defaultValue;

const DialogContext = React.createContext(defaultValue);


const DialogProvider = (props: any) => {
  const [config, setConfig] = useState<DialogConfirm>({} as DialogConfirm);
  const [open, setOpen] = useState(false);

  const confirm = (config: DialogConfirm) => {
    setOpen(true);
    setConfig(config);
  };

  const alert = (config: DialogConfirm) => {
    // @TODO - implementar alert  
  }

  return (
    <DialogContext.Provider value={{ config, open, confirm, alert, setOpen }}>
      {props.children}
    </DialogContext.Provider>
  );
};

export { DialogProvider };
export default DialogContext;

import { IIconsProps } from "components/Icons/icons.model";

const CustomDadosTransportadora = (props: IIconsProps) => {
    return (
        <i id={props.id} className="fa-solid fa-truck-arrow-right" style={{ fontSize: props.fontSize, color: props.color }} />
    );
};

export default CustomDadosTransportadora;

import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import CaixaService from "./caixa.service"
import { useAppSelector } from "redux/hooks"
import { ICaixasDisponivel, TipoDialog } from "./caixa.model"

export type SelectCaixaProps = {
    caixaTitle: string,
    caixa: ICaixasDisponivel | null,
    onSetSelecionaCaixa: (caixa: ICaixasDisponivel) => void,
    open: boolean,
    title: string
}

const initialState: { suggestionsCaixa: Array<ICaixasDisponivel>, error: boolean } = {
    suggestionsCaixa: [],
    error: false
}

const SelectCaixa = (props: SelectCaixaProps) => {
    const [stateLocal, setStateLocal] = useState(initialState)
    const service = CaixaService();
    const { caixa } = useAppSelector(e => e.caixa.caixaAtual)

    useEffect(() => {
        if (stateLocal.suggestionsCaixa.length === 1) {
            props.onSetSelecionaCaixa(stateLocal.suggestionsCaixa[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateLocal.suggestionsCaixa])

    useEffect(() => {
        if (!caixa?.uuid && !caixa?.descritivo) {
            service.getCaixas()
                .then(({ data }) => {
                    setStateLocal(prev => ({ ...prev, suggestionsCaixa: data }))
                })
                .catch(err => {
                    setStateLocal(prevState => ({ ...prevState, error: true }))
                })
        }
        else {
            setStateLocal(prev => ({ ...prev, suggestionsCaixa: [caixa] }))
            props.onSetSelecionaCaixa(caixa)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectChange = (event: SelectChangeEvent) => {
        const caixaParsiado: ICaixasDisponivel = JSON.parse(event.target.value)
        props.onSetSelecionaCaixa({
            uuid: caixaParsiado.uuid,
            descritivo: caixaParsiado.descritivo,
            caixaAberto: caixaParsiado.caixaAberto,
            operadorAtual: caixaParsiado.operadorAtual,
            saldo: caixaParsiado.saldo
        })
    }

    return (
        <Box sx={{
            width: '100%'
        }}>
            <FormControl fullWidth variant="standard" sx={{ width: '100%' }} error={stateLocal.error}>
                <InputLabel id="caixa-title-label" sx={{ fontSize: '16px' }}>{props.caixaTitle} {props.title === TipoDialog.ABERTURA ? '*' : ''}</InputLabel>
                <Select variant="standard"
                    inputProps={{ id: 'select_caixa' }}
                    disabled={!!caixa?.uuid || stateLocal.suggestionsCaixa.length === 0}
                    id="SelectCaixa"
                    onChange={handleSelectChange}
                    value={props.caixa ? JSON.stringify(props.caixa) : ''}
                >
                    {stateLocal.suggestionsCaixa && stateLocal.suggestionsCaixa.map((e, i) => {
                        return (
                            <MenuItem value={JSON.stringify(e)} key={e.uuid} >
                                {e.descritivo}
                            </MenuItem>
                        )
                    })}
                </Select>
                {stateLocal.error &&
                    <FormHelperText sx={{ fontSize: '16px', color: 'var(--error)' }}>Não há caixas cadastrados</FormHelperText>
                }
            </FormControl>
        </Box >
    )
}

export default SelectCaixa

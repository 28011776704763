import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPagamentoRow } from "pages/pagamento/pagamento.model";
import { deepReset, roundHalfToEven } from "utils/util";

type IPagamento = {
    faltaReceber: number,
    valorDesconto: number,
    valorTotalLiquido: number;
    valorTotalBruto: number;
    tableRows: Array<IPagamentoRow>,
    tableRowsDevolucao: Array<IPagamentoRow>,
    selectedRow: SelectedRow;
    valorBrindes: number;
};

type SelectedRow = { row: IPagamentoRow, index: number; } | null;

const initialState: IPagamento = {
    faltaReceber: 0,
    valorDesconto: 0,
    valorTotalBruto: 0,
    valorTotalLiquido: 0,
    tableRows: [],
    tableRowsDevolucao: [],
    selectedRow: null,
    valorBrindes: 0
};

const pagamentoSlice = createSlice({
    name: 'pagamento',
    initialState,
    reducers: {
        setFaltaReceber: (s, { payload }: PayloadAction<number>) => {
            s.faltaReceber += payload;
        },
        resetFaltaReceber: (s) => {
            updateFaltaReceber(s);
        },
        changeTotalLiquido: (s, { payload }: PayloadAction<number>) => {
            s.valorTotalLiquido = payload;
            updateFaltaReceber(s);
        },
        addRow: (s, { payload }: PayloadAction<IPagamentoRow>) => {
            const find = s.tableRows.find(d => d.name === 'DINHEIRO');
            if (payload.name === 'DINHEIRO' && find) {
                find.valor += payload.valor;
            } else {
                s.tableRows.push(payload);
            }
        },
        addRowDevolucao: (s, { payload }: PayloadAction<IPagamentoRow>) => {
            s.tableRowsDevolucao.push(payload);
            updateFaltaReceber(s);
        },
        editRow: (s, { payload }: PayloadAction<{ row: IPagamentoRow, index: number; } | null>) => {
            if (payload) {
                s.tableRows[payload.index] = { ...s.tableRows[payload.index], ...payload.row };
                updateFaltaReceber(s);
                s.selectedRow = null;
            }
        },
        removeRow: (s, { payload }: PayloadAction<number | undefined>) => {
            if (payload || payload === 0) {
                s.tableRows.splice(payload, 1);
                updateFaltaReceber(s);
                s.selectedRow = null;
            }
        },
        resetRows: s => {
            s.tableRows = [];
            s.tableRowsDevolucao = [];
            s.selectedRow = null;
            updateFaltaReceber(s);
        },
        resetPagamento: (s) => {
            deepReset(s, initialState);
        },
        addMultiRows: (s, { payload }: PayloadAction<Array<IPagamentoRow>>) => {
            s.tableRows = payload;
            updateFaltaReceber(s);
        },
        setvalorDescontoPagamento: (s, { payload }: PayloadAction<{ valorTotalBruto: number, valorDesconto: number; }>) => {
            s.valorDesconto = payload.valorDesconto;
            s.valorTotalBruto = payload.valorTotalBruto;
        },
        setSelectedRow: (s, { payload }: PayloadAction<SelectedRow>) => {
            s.selectedRow = payload;
        },
        setFaltaReceberSemSoma: (s, { payload }: PayloadAction<number>) => {
            s.faltaReceber = payload;
            updateFaltaReceber(s);
        },
        setValorBrinde: (s, { payload }: PayloadAction<number>) => {
            s.valorBrindes = payload;
        }
    }
});

export const {
    setFaltaReceber,
    resetFaltaReceber,
    changeTotalLiquido,
    addRow,
    addRowDevolucao,
    addMultiRows,
    editRow,
    removeRow,
    resetRows,
    resetPagamento,
    setvalorDescontoPagamento,
    setSelectedRow,
    setFaltaReceberSemSoma,
    setValorBrinde
} = pagamentoSlice.actions;

export const pagamentoReducer = pagamentoSlice.reducer;

const updateFaltaReceber = (s: IPagamento) => {
    const valorTotalBruto = s.valorTotalBruto;
    const valorTableRows = (s.tableRows.filter(e => e.formaPagamento.descritivo !== 'Nota de crédito' && e.formaPagamento.descritivo !== "OUTROS DEVOLUÇÃO").reduce((prev, current) => prev += current.valor, 0));
    const valorTotalLiquidoTroca = s.tableRowsDevolucao.map(e => e.valor).reduce((prev, next) => prev + next, 0);
    const valorDesconto = (s.valorTotalBruto - s.valorTotalLiquido);
    const valorBrindes = s.valorBrindes;
    const faltaReceber = valorTotalBruto - (valorTotalLiquidoTroca + valorDesconto + valorTableRows + valorBrindes);
    const faltaReceberFixado = roundHalfToEven(faltaReceber, 2);
    return s.faltaReceber = faltaReceberFixado;
};

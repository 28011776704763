import { Card, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useAppSelector } from 'redux/hooks';
import { currencyOf } from 'utils/util';
import { FormaPagamentoDialogContentProps } from '../formapagamento.model';
import { TipoInformacaoAdicional } from 'pages/pagamento/pagamento.model';

const OutrosDialogContent = ({ isNew, error, valorRecebido }: FormaPagamentoDialogContentProps) => {
    const { hasError, setHasError } = error;
    const { dadosPagamento, setDadosPagamento } = valorRecebido;
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const [valueDiff, setValueDiff] = useState(dadosPagamento.valorRecebido);

    const tipo = TipoInformacaoAdicional.OUTROS;

    useEffect(() => {
        if (isNew) {
            setDadosPagamento(prev => ({ valorRecebido: faltaReceber, infoAdicional: { numeroParcelas: 1, descritivo: '', tipo } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (valueDiff !== dadosPagamento.valorRecebido)
            setValueDiff(dadosPagamento.valorRecebido);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosPagamento.valorRecebido]);

    useEffect(() => {
        setDadosPagamento(prev => ({
            ...prev,
            infoAdicional: {
                ...prev.infoAdicional!,
                descritivo: getParcelasDescritivo(prev.infoAdicional!.numeroParcelas, prev.valorRecebido)
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosPagamento.valorRecebido]);


    const onChangeValorRecebido = (value: number = 0) => {
        if (value > faltaReceber) {
            setHasError(prev => true);
        } else {
            setHasError(prev => false);
        }
        setDadosPagamento(prev => ({ ...prev, valorRecebido: value }));
    };

    const onChangePrazos = (e: SelectChangeEvent<number>, child: ReactNode) => {
        const p = (child as any).props;
        setDadosPagamento(prev => ({ ...prev, infoAdicional: { descritivo: p.children, numeroParcelas: p.value, tipo } }));
    };

    const getParcelasValue = (): number | '' => {
        const parcelas = dadosPagamento.infoAdicional?.numeroParcelas ?? 1;
        return parcelas;
    };

    const getParcelasDescritivo = (parcela: number, valorRecebido: number) => {
        return `${parcela}x - ${currencyOf(valorRecebido / parcela)}`;
    };

    return (
        <>
            <FormControl
                fullWidth
                variant="standard"
                sx={{ width: '15rem' }}
                error={hasError}
            >
                <NumericFormat
                    id="outros_div"
                    // inputRef={descontoPedidoRef}
                    value={dadosPagamento.valorRecebido}
                    onValueChange={({ floatValue }) => onChangeValorRecebido(floatValue)}
                    error={hasError}
                    decimalScale={2}
                    fixedDecimalScale
                    allowedDecimalSeparators={[',']}
                    customInput={TextField}
                    decimalSeparator=","
                    prefix={'R$ '}
                    thousandsGroupStyle="thousand"
                    thousandSeparator="."
                    variant="standard"
                    allowNegative={false}
                    inputProps={{
                        id: "outros_input_value",
                        style: {
                            textAlign: 'center',
                            fontSize: '24px',
                            color: hasError ? 'var(--error)' : '#df6e0b',
                            fontWeight: 700,
                            fontFamily: 'Work Sans, sans-serif'
                        }
                    }}
                />
                {hasError &&
                    <FormHelperText
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        O valor não pode ser maior que a venda :(
                    </FormHelperText>}
                {!hasError && <FormHelperText> </FormHelperText>}
            </FormControl>

            <FormControl fullWidth variant="standard" sx={{ width: '15rem' }} error={false}>
                <InputLabel id="prazos-label">Parcelas</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={getParcelasValue()}
                    onChange={onChangePrazos}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 48 * 4.5 + 8,
                                width: 100
                            },
                        },
                    }}
                >
                    {Array.from([].constructor(12)).map((s, index) => {
                        const parcela = ++index;
                        return (
                            <MenuItem
                                id={`menu_item_outros_${index}`}
                                key={index}
                                value={parcela}
                            >
                                {getParcelasDescritivo(parcela, dadosPagamento.valorRecebido)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <Card sx={{ borderRadius: 5, textAlign: 'center', height: '100px', aspectRatio: '7/3', padding: '0 !important' }} className="card-totalizador-pedido">
                <h2 id="outros_falta_receber_value" className="valor-totalizador">{valueDiff <= faltaReceber ? currencyOf(faltaReceber - valueDiff) : currencyOf(faltaReceber)}</h2>
                <h4 id="outros_falta_receber_titulo" className="label-totalizador">Falta receber</h4>
            </Card>
        </>
    );
};

export default OutrosDialogContent;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deepReset } from "utils/util";

const initialState = {
    percentual: 0,
    percentualTemp: 0
};

const descontoFixoSlice = createSlice({
    name: 'descontoFixo',
    initialState,
    reducers: {
        setDescontoFixoTemp: (s, { payload }: PayloadAction<number>) => {
            s.percentualTemp = payload;
        },
        persistDescontoFixo: s => {
            s.percentual = s.percentualTemp;
        },
        roolBackDescontoFixo: s => {
            s.percentualTemp = s.percentual;
        },
        resetDesconto: (s) => {
            deepReset(s, initialState);
        }
    }
});

export const {
    setDescontoFixoTemp,
    persistDescontoFixo,
    roolBackDescontoFixo,
    resetDesconto
} = descontoFixoSlice.actions;
export const descontoFixoReducer = descontoFixoSlice.reducer;

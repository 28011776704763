import { FormControl, TextField } from "@mui/material"

export interface IPesquisaVendaProps {
    pesquisaValue: string,
    onChangePesquisa: (value: string) => void
}
const PesquisaVenda = (props: IPesquisaVendaProps) => {
    return (
        <FormControl sx={{ width: '40%', marginTop: '5px' }}>
            <TextField
                id="inputPesquisaVenda"
                sx={{ width: '100%', height: '100%', }}
                variant="standard"
                label="Número do Pedido ou Cliente"
                value={props.pesquisaValue}
                onChange={(e) => props.onChangePesquisa(e.target.value)}
            />
        </FormControl >
    )
}
export default PesquisaVenda
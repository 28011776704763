import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import { ConfiguracaoPedidoBalcaoTO } from "./topBar.model";
import { IProduto } from "pages/registra-venda/registra-venda.model";

const TopBarService = () => {
    const path = '/configuracoes/pedidobalcao';
    const loginService = LoginService();
    const flowPath = "/flow"

    return {
        getConfiguracao(): Promise<AxiosResponse<ConfiguracaoPedidoBalcaoTO>> {
            return axiosRequest.get(`${path}`, { headers: loginService.getHeaders() });
        },
        setConfiguracao(configuracao: ConfiguracaoPedidoBalcaoTO): Promise<AxiosResponse<ConfiguracaoPedidoBalcaoTO>> {
            return axiosRequest.put(`${path}`, configuracao, { headers: loginService.getHeaders() })
        },
        revalidaSkus(skusUUIDS: Array<string>, tabelaPrecoUUID: string): Promise<AxiosResponse<Array<IProduto>>> {
            return axiosRequest.post(`${flowPath}/revalidaskus`, { skusUUIDS, tabelaPrecoUUID }, { headers: loginService.getHeaders() })
        }
    };
};

export default TopBarService;

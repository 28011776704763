import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, AutocompleteRenderOptionState, Box, Checkbox, Chip, DialogActions, DialogTitle, FormControl, Icon, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import CustomDialog from 'components/custom/CustomDialog';
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useGenerateUuid } from "hooks/useGenerateUuid";
import { useCallback, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { UsuarioTO, rowGrid } from "./Configuracoes.model";

type ConfiguracaoDescontoModalProps = {
    closeAddConfiguracao: () => void;
    onConfirm: () => void;
    usuarios: Array<UsuarioTO>;
    usuariosSelecionados: Array<UsuarioTO>;
    onAddUsuario: (newArray: Array<UsuarioTO>) => void;
    onAddAll: () => void;
    onRemoveAll: () => void;
    descontoMaximoNoItem: number;
    onSetMaxDescItem: (maxDesc: number) => void;
    descontoMaximoPedido: number,
    onSetDescontoMaximoPedido: (maxDesc: number) => void;
    autorizaDesconto: boolean;
    autorizaDescontoOptions: Array<boolean>;
    onChangeAutorizaDesconto: (option: string) => void;
    rows: Array<rowGrid>;
};


const ConfiguracaoDescontoModal = (props: ConfiguracaoDescontoModalProps) => {
    const [randomUuid] = useGenerateUuid();
    const descontoMaxItemRef = useRef<HTMLInputElement | null>(null);
    const descontoMaxRodapeRef = useRef<HTMLInputElement | null>(null);

    const onFocusDescontoMaxItem = useCallback(() => {
        if (props.descontoMaximoNoItem) {
            return descontoMaxItemRef.current?.setSelectionRange(4, 4);
        }
        return descontoMaxItemRef.current?.setSelectionRange(1, 1);
    }, [props.descontoMaximoNoItem]);

    const onFocusDescontoMaxRodape = useCallback(() => {
        if (props.descontoMaximoNoItem) {
            return descontoMaxItemRef.current?.setSelectionRange(4, 4);
        }
        return descontoMaxItemRef.current?.setSelectionRange(1, 1);
    }, [props.descontoMaximoNoItem]);

    const getSimNaoOfBoolean = useCallback((e: boolean) => {
        if (e) { return "SIM"; }
        return "NÃO";
    }, []);

    const handleMenuItemClick = (username: string) => {
        const selectedUser = props.usuarios.find(e => e.username === username);

        if (selectedUser) {
            const updatedUsuarios = props.usuariosSelecionados.includes(selectedUser)
                ? props.usuariosSelecionados.filter(e => e.username !== username)
                : [...props.usuariosSelecionados, selectedUser];

            props.onAddUsuario(updatedUsuarios);
        }
    };

    const renderOptions = (option: UsuarioTO, state: AutocompleteRenderOptionState) => {
        return (
            <MenuItem
                onClick={handleMenuItemClick.bind(null, option.username)}
                key={randomUuid()}
                value={option.username}>
                <Checkbox
                    id={`item-${option.username?.toLowerCase()}-input`}
                    sx={{
                        '&.MuiCheckbox-root.Mui-checked': {
                            color: 'var(--laranja)'
                        }
                    }}
                    checked={props.usuariosSelecionados.some(e => e.username === option.username)}
                />
                <ListItemText primary={option.username} />
            </MenuItem>
        );
    };



    return (
        <CustomDialog open={true} fullWidth onClose={props.closeAddConfiguracao}>
            <DialogTitle sx={{ border: 0, margin: 0, padding: 0 }} align='left'>
                Configuração de desconto
            </DialogTitle>
            <FormControl style={{ marginTop: '40px' }}>
                <Autocomplete
                    sx={{ maxHeight: '200px', overflowX: 'hidden', overflowY: 'scroll' }}
                    multiple
                    filterSelectedOptions
                    value={props.usuariosSelecionados}
                    options={props.usuarios.filter(e => !props.rows.some(el => e.username === el.usuario))}
                    getOptionLabel={(option) => option.username}
                    onInputChange={(e) => {
                        if (e && e.type === "click") { props.onRemoveAll(); }
                    }}
                    noOptionsText="Nenhum valor encontrado"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Usuários"
                        />)}
                    renderOption={(_props, option, state, _ownerState) => renderOptions(option, state)}
                    renderTags={(p) => p.map(e => {
                        return (
                            <Box
                                sx={{ backgroundColor: 'var(--laranja)', color: '#fff', borderRadius: '50px', marginRight: '5px', marginTop: '2px', marginBottom: '2px' }}
                            >
                                <Chip
                                    key={e.username}
                                    sx={{ height: '1.5rem', backgroundColor: 'var(--laranja)', color: '#fff' }}
                                    label={e.username}
                                />
                                <IconButton
                                    onClick={handleMenuItemClick.bind(null, e.username)}
                                    sx={{
                                        color: '#FFF',
                                        padding: 0,
                                        paddingRight: "2px",
                                        fontSize: '15px'
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        );
                    })}
                />
            </FormControl>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '30px' }}>
                <FormControl>
                    <Typography sx={{ fontSize: '12px', color: 'grey' }}>Desconto máximo no item</Typography>
                    <NumericFormat
                        inputRef={descontoMaxItemRef}
                        onFocus={onFocusDescontoMaxItem}
                        style={{ width: '98%' }}
                        id="desconto_maximo_item_numeric_format"
                        decimalScale={2}
                        fixedDecimalScale
                        allowedDecimalSeparators={[',']}
                        customInput={TextField}
                        decimalSeparator=","
                        suffix={" %"}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        variant="standard"
                        allowNegative={false}
                        inputProps={{ style: { textAlign: 'left', fontSize: '24px', color: "var(--laranja)", fontWeight: 700, fontFamily: 'Work Sans, sans-serif' } }}
                        isAllowed={({ floatValue }) => {
                            if (!floatValue) {
                                return true;
                            }
                            if (floatValue > 100) {
                                return false;
                            }
                            return true;
                        }}

                        value={props.descontoMaximoNoItem}
                        onValueChange={({ floatValue }) => props.onSetMaxDescItem(floatValue ?? 0)}
                    />
                </FormControl>

                <FormControl>
                    <Typography sx={{ fontSize: '12px', color: 'grey' }}>Desconto máximo no rodapé</Typography>
                    <NumericFormat
                        inputRef={descontoMaxRodapeRef}
                        onFocus={onFocusDescontoMaxRodape}
                        style={{ width: '98%' }}
                        id="desconto_maximo_rodape_numeric_format"
                        decimalScale={2}
                        fixedDecimalScale
                        allowedDecimalSeparators={[',']}
                        customInput={TextField}
                        decimalSeparator=","
                        suffix={" %"}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        variant="standard"
                        allowNegative={false}
                        inputProps={{ style: { textAlign: 'left', fontSize: '24px', color: "var(--laranja)", fontWeight: 700, fontFamily: 'Work Sans, sans-serif' } }}
                        isAllowed={({ floatValue }) => {
                            if (!floatValue) {
                                return true;
                            }
                            if (floatValue > 100) {
                                return false;
                            }
                            return true;
                        }}

                        value={props.descontoMaximoPedido}
                        onValueChange={({ floatValue }) => props.onSetDescontoMaximoPedido(floatValue ?? 0)}
                    />
                </FormControl>

            </Box>



            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '30px' }}>

                <Typography sx={{ fontSize: '14px' }}></Typography>
            </Box>

            <FormControl>
                <InputLabel variant="standard" id="autoriza_desconto_title" style={{ fontSize: '16px' }}>Autoriza desconto?</InputLabel>
                <Select variant="standard"
                    inputProps={{ id: 'select_autoriza_desconto' }}
                    id="autoriza_deconto_boolean"
                    onChange={(e) => props.onChangeAutorizaDesconto(e.target.value)}
                    value={getSimNaoOfBoolean(props.autorizaDesconto)}
                >
                    {props.autorizaDescontoOptions.map((e, i) => {
                        return (
                            <MenuItem value={getSimNaoOfBoolean(e)} key={getSimNaoOfBoolean(e)} >
                                {getSimNaoOfBoolean(e)}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>

            <DialogActions
                sx={{
                    justifyContent: 'space-between', marginTop: '30px'
                }}>
                <CustomButton onClick={props.closeAddConfiguracao} variant="contained" color="error" startIcon={<Icon className='fa-solid fa-xmark' />}>
                    Cancelar
                </CustomButton>
                <CustomButton onClick={props.onConfirm} disabled={!props.usuariosSelecionados.length} id="confirm_button_configuracao_desconto" type="submit" variant="contained" color="success" startIcon={<Icon className='fa-solid fa-check' />}>
                    Confirmar
                </CustomButton>
            </DialogActions>
        </CustomDialog >
    );
};

export default ConfiguracaoDescontoModal;

import { Box, LinearProgress, Typography, linearProgressClasses, styled } from "@mui/material"
import { useEffect, useState } from "react";
import './LinearFinalizaVenda.css'

interface ILinearFinalizaVendaProps {
    progress: number,
    message: string
}

const LinearFinalizaVenda = (props: ILinearFinalizaVendaProps) => {
    const [stateLocal, setStateLocal] = useState({
        message: props.message,
        progress: props.progress,
    })

    useEffect(() => {
        setStateLocal(prevState => ({ ...prevState, message: props.message, progress: props.progress }))
    }, [props])

    return (
        <Box sx={{ width: '80%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <BorderLinearProgress
                variant="determinate"
                value={stateLocal.progress}
            />
            <Typography className="LinearFinalizaVenda" sx={{ color: '#FFF', textAlign: 'center', paddingTop: '20px', fontSize: '20px' }}>{stateLocal.message}
                <span id="dotdotdot"></span>
            </Typography>
        </Box>
    )

}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#785434',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'var(--laranja)'
    },
}));

export default LinearFinalizaVenda
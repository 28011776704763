import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IEstabelecimento } from "model/estabelecimento.model";
import { deepReset } from "utils/util";

const initialState: IEstabelecimento = { uuid: '', descritivo: '', hash: '' };

const esSelecionadoSlice = createSlice({
    name: 'estabelecimentoSelecionado',
    initialState,
    reducers: {
        setEsSelecionado: (state, action: PayloadAction<IEstabelecimento>) => {
            Object.assign(state, action.payload);
        },
        resetEsSelecionado: (s) => {
            deepReset(s, initialState);
        }
    }
});

export const { setEsSelecionado, resetEsSelecionado } = esSelecionadoSlice.actions;
export const esSelecionadoReducer = esSelecionadoSlice.reducer;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { store } from 'redux/store';
import MessageDialog from './components/confirm-dialog';
import { DialogProvider } from './components/confirm-dialog-context';
import { router } from './routes';

import { Slide, SlideProps } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BlockUI from 'components/blockui/BlockUI';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import { SnackbarProvider } from 'notistack';

dayjs.extend(isLeapYear);
dayjs.locale('pt-br');

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs} >
        <SnackbarProvider
          dense={false}
          maxSnack={7}
          anchorOrigin={{ vertical: 'top', horizontal: "right" }}
          TransitionComponent={(props: SlideProps) => (<Slide {...props} direction="left" />)}
          className='snack'
        >
          <DialogProvider>
            <BlockUI />
            <MessageDialog />
            <RouterProvider router={router} />
          </DialogProvider>
        </SnackbarProvider>
      </LocalizationProvider>

    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StepperProps } from "layout/stepper/StepperComponent";
import { deepReset } from "utils/util";

const stepCaixa = ['Movimento de Caixa', 'Registrar venda'];
const stepRegistraVenda = ['Registrar venda', 'Finalizar venda'];
const initialState: StepperProps = {
    stepsLabel: window.location.pathname === '/caixa' ? stepCaixa : stepRegistraVenda,
    activeStep: 0,
};

const stepperSlice = createSlice({
    name: 'stepper',
    initialState,
    reducers: {
        setActiveStep: (s, { payload: p }: PayloadAction<number>) => {
            s.stepsLabel = window.location.pathname === '/caixa' ? stepCaixa : stepRegistraVenda;
            s.activeStep = p;
        },
        resetStepper: (s) => {
            deepReset(s, initialState);
        }
    }
});

export const {
    setActiveStep,
    resetStepper
} = stepperSlice.actions;

export const stepperReducer = stepperSlice.reducer;

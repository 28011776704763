import { StyleSheet } from "@react-pdf/renderer"
const branco = "#FFF";

const s = StyleSheet.create({
    pdfView: {
        zIndex: 10000,
        width: '100%',
        height: '100vh',
        border: 0,
        position: 'absolute',
        top: 0,
        left: 0
    },
    page: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        padding: '30px 0',
    },
    wrapLogo: {
        width: '100px',
        height: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FF7F01',
        paddingHorizontal: 5,
        borderRadius: '5px'
    },
    wrapPageNumber: {
        width: '95%',
        position: 'absolute',
        bottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '10'
    },
    pageNumber: {
        fontSize: 10,
        color: 'grey',
    },
    tableRow: {
        width: '97%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: '3px',
        paddingVertical: '5px',
    },
    tableText: {
        width: '100%',
        fontSize: '8px',
        textAlign: 'center',
    },
    title: {
        fontSize: 28,
        marginBottom: '10px'
    },
    info: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '95%',
        marginBottom: '10px',
        gap: '5px'
    },
    wrapInfoItem: {
        width: '24%',
        marginTop: '6px'
    },
    infoItem: {
        fontSize: '8px'
    },
    containerFormaPgt: {
        width: '95%',
        marginTop: '20px'
    },
    wrapFormaPgt: {
        alignSelf: 'flex-end',
        width: '40%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    formaPgt: {
        fontSize: '8px'
    }
});

export { s, branco };
import { Check } from '@mui/icons-material';
import { Container, StepConnector, StepIconProps, Typography, stepConnectorClasses, styled, Icon as Icone } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import PreVendaIcon from 'components/Icons/PreVendaIcon';
import ShoppingCar from 'pages/Venda/ShoppingCar';
import { useAppSelector } from 'redux/hooks';
import useCustomLocation from 'utils/useCustomLocation';

export interface StepperProps {
    stepsLabel: Array<string>;
    activeStep: number;
}

const StepperComponent = ({ stepsLabel, activeStep }: StepperProps) => {
    const {
        isTelaRegistraVenda,
        isTelaPagamento,
        isTelaVenda,
        isTelaConfiguracoes,
        isTelaCaixa,
        isTelaValePresente,
        isTelaBuscaAvancadaValePresente,
        isTelaRelatorioFechamentoCaixa,
        isTelaPreVenda
    } = useCustomLocation();
    const valePresente = useAppSelector(s => s.valePresente.valePresente);
    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: '20px',
            }}>
            {(!isTelaConfiguracoes() && !isTelaCaixa()) &&
                <Box sx={{
                    width: '100%',
                    borderTop: '1px solid rgba(0, 0, 0, .1)',
                    borderBottom: '1px solid rgba(0, 0, 0, .1)',
                    padding: '15px 0',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    {(isTelaRegistraVenda() || isTelaPagamento()) &&
                        <Stepper
                            activeStep={activeStep}
                            sx={{
                                width: '60%',
                            }}
                            connector={<Connector />}
                        >
                            {stepsLabel.map((label, index) => {
                                return (
                                    <Step key={label + index} id={`${label}_${index}`}>
                                        <StepLabel slotProps={{
                                            label: { id: `label_${label}_${index}` }
                                        }} id={`steplabel_${label}_${index}`} StepIconComponent={Icon}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    }

                    {isTelaVenda() &&
                        <Box id='vendaScreenBox' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', maxHeight: "45px" }}>
                            <ShoppingCar id='vendaShoppingCarIcon' color='#666666' fontSize='45px' />
                            <Typography id='vendaScreenTitle' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: '#666666', userSelect: 'none' }}>Vendas</Typography>
                        </Box>
                    }

                    {isTelaValePresente() &&
                        <Box id='vale-presente-box' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                            <Typography id='title-vale-presente' sx={{ color: '#666666', userSelect: 'none' }}>Recebimento de vale presente</Typography>
                            <Typography id='cliente-vale-presente' sx={{ color: '#666666', fontSize: '12px', userSelect: 'none', maxWidth: '400px' }}>Cliente: {valePresente?.cliente.descritivo}</Typography>
                        </Box>
                    }

                    {isTelaBuscaAvancadaValePresente() &&
                        <Box id='vale-presente-box' sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography id='title-busca-vale-presente' sx={{ color: '#666666', userSelect: 'none', fontSize: '30px' }}>Busca avançada - Crédito do cliente</Typography>
                        </Box>
                    }
                    {isTelaRelatorioFechamentoCaixa() &&
                        <Box id='relatorio-fechamento-caixa-box' sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Icone className="fa-regular fa-file-lines" sx={{ color: '#666666', fontSize: '45px' }} />
                            <Typography id='configurar-desconto-title' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: '#666666', userSelect: 'none' }}> Relatório de fechamento de caixa</Typography>
                        </Box>
                    }
                    {isTelaPreVenda() &&
                        <Box id='relatorio-fechamento-caixa-box' sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: "35px" }}>
                            <PreVendaIcon id="side_menu_pre_venda_icon" color="#666666" fontSize='45px' />
                            <Typography id='configurar-desconto-title' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: '#666666', userSelect: 'none' }}>Pré-venda</Typography>
                        </Box>
                    }


                </Box>
            }
        </Container >
    );
};

export default StepperComponent;

const Connector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: 'var(--laranja)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#ff942b38',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const IconRoot = styled('div')<{ ownerState: { active?: boolean; }; }>(
    ({ theme, ownerState }) => ({
        '& .stepIcon': {
            color: '#999',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 30,
            aspectRatio: '1/1',
            borderRadius: 50,
            backgroundColor: '#eaeaf0',
            fontSize: 20,
            ...(ownerState.active && {
                color: '#FFF',
                backgroundColor: 'var(--laranja)',
            }),
            '&__completedIcon': {
                color: 'var(--laranja)',
                fontSize: 25,
            },
        },
    }),
);

function Icon({ active, completed, icon }: StepIconProps) {
    return (
        <IconRoot ownerState={{ active }}>
            {completed ? (
                <Check className="stepIcon__completedIcon" />
            ) : (
                <div className="stepIcon">
                    {icon}
                </div>
            )}
        </IconRoot>
    );
}

import { Box, Container, Icon, Typography } from "@mui/material";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import PdfComprovanteTemplate from "pages/Comprovante-nota-fiscal-outros/PdfComprovanteTemplate";
import { FlowVendaTO } from "pages/registra-venda/registra-venda.model";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { captalizeString, currencyOf } from "utils/util";
import CustomIFrame from "./Header/CustomIFrame";
import CustomLoading from "./Header/CustomLoading";
import Data from "./Header/Data";
import PesquisaVenda from "./Header/Pesquisavenda";
import SituacaoDocumentoFiscal from "./Header/SituacaoDocumentoFiscal";
import Status from "./Header/Status";
import VendasTable from "./VendasTable";
import VendaService from "./venda.service";
import { Assosicacao, ItemRelatorioVendas, StatusNotaFiscal, StatusPedido, TMotivo, TVenda, getAssociacao } from './vendas.model';

import CaixaService from "pages/Caixa/caixa.service";
import LoginService from "pages/login/login.service";
import { resetCaixa, setCaixa } from "redux/features/caixaSlice";
import { setDeposito, setTabelaPreco } from "redux/features/configuracoes";
import { axiosRequest } from '../../../src/axios/axios.config';
import ImprimirA4 from "./ImprimirA4";
import { setTransportador } from "redux/features/stateSlice";
import PreVendaPdf from "pages/registra-venda/PreVendaPdf";
import PdfComprovanteCrediario from "pages/Comprovante-nota-fiscal-outros/PdfComprovanteCrediarios";


const allStatusPedidoOptions = [StatusPedido.FATURADO, StatusPedido.ABERTO, StatusPedido.CANCELADO];

const situacaoNotaFiscalInitialState = [
    captalizeString(StatusNotaFiscal.AUTORIZADA),
    captalizeString(StatusNotaFiscal.PENDENTE),
    captalizeString(StatusNotaFiscal.REJEITADA)
];

const allSituacaoNotaFiscalOptions = [
    captalizeString(StatusNotaFiscal.AUTORIZADA),
    captalizeString(StatusNotaFiscal.PENDENTE),
    captalizeString(StatusNotaFiscal.REJEITADA),
    captalizeString(StatusNotaFiscal.DENEGADA),
    captalizeString(StatusNotaFiscal.CANCELADA),
];
const statusPedidoInitialState = [StatusPedido.FATURADO, StatusPedido.ABERTO];


const initialState: TVenda = {
    pesquisaVenda: '',
    statusPedido: statusPedidoInitialState,
    situacaoNotaFiscal: situacaoNotaFiscalInitialState,
    dataInicio: new Date().toString(),
    dataFim: new Date().toString(),
    situacaoOptions: allStatusPedidoOptions,
    totalizadores: [],
    totalizadoresError: false,
    dadosFooter: {
        quantidade: 0,
        totalPedido: 0,
        totalNota: 0
    },
    loading: true,
    showTooltip: false,
    showLoadingNavegandoPDV: false,
    showLoadingComprovanteNaoFiscal: false,
    showComprovanteFolhaA4: false,
    showComprovantePreVenda: false,
    showCrediario: false
};

type KeyListStatusPedido = Array<keyof typeof StatusPedido>;

type KeyListSituacaoNotaFiscal = Array<keyof typeof StatusNotaFiscal>;


export type TCancelapedido = {
    pedidoUUID: string,
    motivoUUID: string;
};

const getKeyOfStatuspedido = (status: Array<StatusPedido>): KeyListStatusPedido => {
    return status.map(s => Object.keys(StatusPedido).find(key => (StatusPedido as any)[key] === s)) as KeyListStatusPedido;
};

const getKeyOfSituacaoNotaFiscal = (situacao: Array<String>): KeyListSituacaoNotaFiscal => {
    return situacao.map(s => Object.keys(StatusNotaFiscal).find(key => (StatusNotaFiscal as any)[key] === s)) as KeyListSituacaoNotaFiscal;
};

let timer: NodeJS.Timeout;

const Venda = () => {
    const [stateLocal, setStateLocal] = useState(initialState);
    const [state, setState] = useState({
        showImprimirDFE: false,
        url: ""
    }
    );
    const navigate = useNavigate();
    const vendaService = VendaService();
    const { addError, addSuccess } = useSnackbarCustom();
    const { caixaAberto } = useAppSelector(e => e.caixa.caixaAtual);
    const estabelecimento = useAppSelector(e => e.estabelecimentoSelecionado);
    const estabelecimentoRef = useRef<string>(estabelecimento.uuid);
    const { descritivo } = useAppSelector(e => e.estabelecimentoSelecionado);
    const [showComprovanteOutros, setShowComprovanteOutros] = useState<{ open: boolean, pedido: FlowVendaTO | null; }>({
        open: false,
        pedido: null
    });
    const caixaService = CaixaService();
    const dispatch = useAppDispatch();
    const loginService = LoginService();
    const token = useAppSelector(e => e.token);

    const atualizaRelatorio = () => {
        if (!stateLocal.loading) {
            setStateLocal(prevState => ({ ...prevState, loading: true }));
        }
        const vendaStatusNameArray = getKeyOfStatuspedido(stateLocal.statusPedido);
        const vendaSituacaoNameArray = getKeyOfSituacaoNotaFiscal(stateLocal.situacaoNotaFiscal.map(e => e.toUpperCase()));
        const datas = [new Date(stateLocal.dataInicio).toISOString().split("T")[0], new Date(stateLocal.dataFim).toISOString().split("T")[0]];

        vendaService.getRelatorio(stateLocal.pesquisaVenda, datas, vendaStatusNameArray, vendaSituacaoNameArray)
            .then(({ data }) => {
                if (!data.length) {
                    return setStateLocal(prevState => ({ ...prevState, totalizadoresError: true, totalizadores: data, loading: false }));
                }

                const quantidade = data.length;
                const totalNota = data.filter(e => e.valorNota).reduce((prev, current) => prev += current.valorNota, 0);
                const totalPedido = data.filter(e => e.valorPedido).reduce((prev, current) => prev += current.valorPedido, 0);

                return setStateLocal(prevState => ({
                    ...prevState,
                    totalizadores: data,
                    totalizadoresError: false,
                    dadosFooter: {
                        quantidade,
                        totalNota,
                        totalPedido
                    },
                    loading: false
                }));
            })
            .catch(error => {
                if (error.code === "ERR_NETWORK") {
                    addError({ message: "Sistema temporariamente indisponivel. Se o erro persistir, contate o suporte", closeable: true, persist: true, id: "sistema-indisponivel-error", preventDuplicate: true });
                }
                setStateLocal(prevState => ({ ...prevState, loading: false }));
            });
    };

    useEffect(() => {
        if (estabelecimento.uuid !== estabelecimentoRef.current) {
            estabelecimentoRef.current = estabelecimento.uuid;
            axiosRequest.get(`/flow/existeconfiguracao`, { headers: loginService.getHeaders() })
                .then(e => {
                    setStateLocal(prevState => ({ ...prevState, error: false }));
                    caixaService.getCaixas()
                        .then(({ data }) => {
                            const caixa = data.find(e => e.caixaAberto && e.operadorAtual === token.USER_INFO?.username);
                            if (!!caixa) {
                                caixaService.getSaldoConta(caixa.uuid)
                                    .then(({ data }) => {
                                        dispatch(setCaixa({
                                            caixa: data.caixa,
                                            contaSelecionada: null,
                                            saldo: data.saldo,
                                            dataAbertura: data.dataAbertura,
                                            caixaAberto: data.caixaAberto
                                        }));
                                    })
                                    .catch(error => {
                                        addError({ message: 'Falha ao pegar o saldo da conta informada.', closeable: true });
                                    });
                            }
                            else {
                                dispatch(resetCaixa());
                            }
                        }).catch(error => {
                            addError({ message: 'Falha ao pegar caixas disponiveis.', closeable: true });
                        });
                })
                .catch((error: any) => {
                    setStateLocal(prevState => ({ ...prevState, error: true }));
                    dispatch(resetCaixa());
                    addError({ message: error.response.data.extra[0], closeable: true, persist: false });
                });
            atualizaRelatorio();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [estabelecimento.uuid]);


    useEffect(() => {
        atualizaRelatorio();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateLocal.dataInicio, stateLocal.dataFim, stateLocal.statusPedido, stateLocal.situacaoNotaFiscal]);

    useEffect(() => {
        if (stateLocal.totalizadores) {
            timer = setTimeout(() => {
                atualizaRelatorio();
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateLocal.pesquisaVenda]);

    useEffect(() => {
        if (!stateLocal.statusPedido.includes(StatusPedido.FATURADO)
            && (stateLocal.statusPedido.includes(StatusPedido.ABERTO) || stateLocal.statusPedido.includes(StatusPedido.CANCELADO))) {
            return setStateLocal(prevState => ({ ...prevState, situacaoNotaFiscal: [] }));
        }
        if (stateLocal.statusPedido.includes(StatusPedido.FATURADO) && !stateLocal.situacaoNotaFiscal.length) {
            return setStateLocal(prevState => ({ ...prevState, situacaoNotaFiscal: situacaoNotaFiscalInitialState }));
        }
        // eslint-disable-next-line
    }, [stateLocal.statusPedido]);



    const changePesquisavenda = useCallback((pesquisaVenda: string) => {
        if (timer) { clearTimeout(timer); }
        setStateLocal(prevState => ({ ...prevState, pesquisaVenda }));
    }, []);

    const showImprimirDFE = useCallback((url: string) => {
        setState(prevState => ({ ...prevState, showImprimirDFE: true, url }));
    }, []);

    const changeStatus = useCallback((statusPedido: any) => {
        setStateLocal(prevState => ({ ...prevState, statusPedido }));
    }, []);

    const changeSituacao = useCallback((situacaoNotaFiscal: any) => {
        setStateLocal(prevState => ({ ...prevState, situacaoNotaFiscal }));
    }, []);

    const changeData = useCallback((state: string, value: string) => {
        setStateLocal(prevState => ({ ...prevState, [state]: value }));
    }, []);


    const customIFrameClose = useCallback(() => {
        setState(prevState => ({ ...prevState, showImprimirDFE: false }));
    }, []);

    const cancelaPedido = (uuid: string, motivo: TMotivo) => {
        const toCancel: TCancelapedido = {
            motivoUUID: motivo.uuid,
            pedidoUUID: uuid
        };

        vendaService.cancelaPedido(toCancel)
            .then(() => {
                addSuccess({ message: "Pedido cancelado com sucesso", closeable: true, hideDuration: 4000, id: 'pedido-cancelado-alert', });
                atualizaRelatorio();
            })
            .catch(error => console.log(error));
    };

    const navegaPDV = (uuid: string, numeroPedido: number) => {
        setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: true }));
        if (!caixaAberto) {
            setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
            return addError({
                message: `Abra um caixa para movimentar o pedido ${numeroPedido}`,
                closeable: true
            });
        }
        return vendaService.carregaPedido(uuid)
            .then(({ data }: { data: FlowVendaTO; }) => {
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
                if (data.statusPedido === StatusPedido.ABERTO.toString().toUpperCase()) {
                    if (data.tabelaPreco) {
                        dispatch(setTabelaPreco(data.tabelaPreco));
                    }
                    if (data.deposito) {
                        dispatch(setDeposito(data.deposito));
                    }
                    if (data.transportador) {
                        dispatch(setTransportador(data.transportador));
                    }
                    data.itens.forEach(e => e.estoque = -1);
                    return navigate("/registra-venda", { state: data });
                }
                addError({
                    message: `O pedido ${numeroPedido} já foi faturado em outra transação`,
                    closeable: true
                });
                atualizaRelatorio();
            })
            .catch(error => {
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
                addError({ message: "Sistema temporariamente indisponivel. Se o erro persistir, contate o suporte", closeable: true, persist: true, id: "sistema-indisponivel-error", preventDuplicate: true });
                console.log(error);
            });
    };

    const onVisualizar = useCallback((uuid: string, numeroPedido: number) => {
        return vendaService.carregaPedido(uuid)
            .then(({ data }: { data: FlowVendaTO; }) => {
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
                if (data.tabelaPreco) {
                    dispatch(setTabelaPreco(data.tabelaPreco));
                }
                if (data.deposito) {
                    dispatch(setDeposito(data.deposito));
                }
                if (data.transportador) {
                    dispatch(setTransportador(data.transportador));
                }
                data.itens.forEach(e => e.estoque = -1);
                data.isVisualizar = true;
                return navigate("/registra-venda", { state: data });
            })
            .catch(error => {
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
                addError({ message: "Sistema temporariamente indisponivel. Se o erro persistir, contate o suporte", closeable: true, persist: true, id: "sistema-indisponivel-error", preventDuplicate: true });
                console.log(error);
            });
        //eslint-disable-next-line
    }, [])

    const navegaPreVenda = (uuid: string, numeroPedido: number) => {
        setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: true }));
        return vendaService.carregaPedido(uuid)
            .then(({ data }: { data: FlowVendaTO; }) => {
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
                if (data.statusPedido === StatusPedido.ABERTO.toString().toUpperCase()) {
                    if (data.tabelaPreco) {
                        dispatch(setTabelaPreco(data.tabelaPreco));
                    }
                    if (data.deposito) {
                        dispatch(setDeposito(data.deposito));
                    }
                    if (data.transportador) {
                        dispatch(setTransportador(data.transportador));
                    }
                    return navigate("/pre-venda", { state: data });
                }
                addError({
                    message: `O pedido ${numeroPedido} já foi faturado em outra transação`,
                    closeable: true
                });
                atualizaRelatorio();
            })
            .catch(error => {
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
                addError({ message: "Sistema temporariamente indisponivel. Se o erro persistir, contate o suporte", closeable: true, persist: true, id: "sistema-indisponivel-error", preventDuplicate: true });
            });
    };

    const showImprimirOutros = useCallback((uuid: string) => {
        setStateLocal(prevState => ({ ...prevState, showLoadingComprovanteNaoFiscal: true }));
        vendaService.carregaPedido(uuid)
            .then(({ data }: { data: FlowVendaTO; }) => {
                setShowComprovanteOutros(prevState => ({ ...prevState, open: true, pedido: data }));
            })
            .catch(error => {
                addError({ message: "Sistema temporariamente indisponivel. Se o erro persistir, contate o suporte", closeable: true, persist: true, id: "sistema-indisponivel-error", preventDuplicate: true });
                console.log(error);
            })
            .finally(() => {
                setStateLocal(prevState => ({ ...prevState, showLoadingComprovanteNaoFiscal: false }));
            });
        // eslint-disable-next-line
    }, []);

    const flowVendaTORef = useRef<FlowVendaTO | null>(null);

    const showImprimirA4 = (uuid: string) => {
        setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: true }));
        vendaService.carregaPedido(uuid)
            .then(({ data }) => {
                flowVendaTORef.current = data;
                setStateLocal(prevState => ({ ...prevState, showComprovanteFolhaA4: true, showLoadingNavegandoPDV: false }));
            })
            .catch((err) => {
                addError({ message: "Não foi possivel imprimir o pedido A4", closeable: true, id: "erro-impressao-A4", preventDuplicate: true });
                setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
            });
    };

    const emissaoNotaRef = useRef<Date | undefined>(undefined);

    const imprimirCrediario = (uuid: string, emissaoNota: Date | undefined) => {
        setStateLocal(prevState => ({ ...prevState, showLoadingComprovanteNaoFiscal: true }));
        vendaService.carregaPedido(uuid)
            .then(({ data }) => {
                const validateCrediario = data.itensPagamento.some(e => e.formaPagamento.descritivo === "CREDIÁRIO");
                if (!validateCrediario) {
                    setStateLocal(prevState => ({ ...prevState, showLoadingComprovanteNaoFiscal: false }))
                    return addError({
                        message: "Pedido não possui forma de pagamento crediário"
                        , closeable: true,
                        id: "erro-impressao-A4",
                        preventDuplicate: true
                    });
                }

                flowVendaTORef.current = data;
                if (emissaoNota) {
                    emissaoNotaRef.current = emissaoNota
                }

                setStateLocal(prevState => ({
                    ...prevState,
                    showCrediario: true,
                    showLoadingComprovanteNaoFiscal: false
                }));
            })
            .catch((err) => {
                addError({ message: "Não foi possivel imprimir o pedido A4", closeable: true, id: "erro-impressao-A4", preventDuplicate: true });
                setStateLocal(prevState => ({
                    ...prevState,
                    showLoadingComprovanteNaoFiscal: false,
                }));
            });
    }

    const comprovantePreVendaRef = useRef<ItemRelatorioVendas | null>(null);

    const imprimirPreVenda = (itemRelatorio: ItemRelatorioVendas) => {
        setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: true, showComprovantePreVenda: true }));
        comprovantePreVendaRef.current = itemRelatorio;
        setTimeout(() => {
            setStateLocal(prevState => ({ ...prevState, showLoadingNavegandoPDV: false }));
        }, 500);

    };

    const onCloseComprovantePreVenda = useCallback(() => {
        flowVendaTORef.current = null;
        setStateLocal(prevState => ({ ...prevState, showComprovantePreVenda: false }));
    }, []);

    const onCloseCrediario = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, showCrediario: false }))
    }, [])

    const goBackA4 = () => {
        flowVendaTORef.current = null;
        setStateLocal(prevState => ({ ...prevState, showComprovanteFolhaA4: false }));
    };

    const closeShowImprimirOutros = useCallback(() => {
        setShowComprovanteOutros(prevState => ({ ...prevState, open: false, pedido: null }));
    }, []);

    return (
        <Container
            maxWidth="xl"
            sx={{
                marginTop: '40px',
            }}>
            <Box
                maxHeight="xl"
                sx={{
                    height: 'calc(100vh - 224px)',
                    display: 'grid',
                    gridTemplateRows: { md: `50px 50px auto 100px 50px`, xs: `auto auto 450px auto auto` },
                    gap: '20px',
                }}>
                <Box sx={{
                    width: '100%',
                    height: '50px',
                    display: 'flex',
                    gap: '2%'
                }}>
                    <PesquisaVenda
                        pesquisaValue={stateLocal.pesquisaVenda}
                        onChangePesquisa={changePesquisavenda}
                    />
                    <Data
                        title="Data inicial"
                        dataValue={stateLocal.dataInicio}
                        onChangeData={changeData}
                        dataInicio={stateLocal.dataInicio}
                        dataFim={stateLocal.dataFim}
                        state='dataInicio'
                    />
                    <Data
                        title="Data final"
                        dataValue={stateLocal.dataFim}
                        onChangeData={changeData}
                        dataInicio={stateLocal.dataInicio}
                        dataFim={stateLocal.dataFim}
                        state='dataFim'
                    />
                    <Status
                        statusOptions={allStatusPedidoOptions}
                        statusValues={stateLocal.statusPedido}
                        onChangeStatus={changeStatus}
                    />
                    <SituacaoDocumentoFiscal
                        situacaoOptions={allSituacaoNotaFiscalOptions}
                        situacaoValues={stateLocal.situacaoNotaFiscal}
                        isDisabled={!stateLocal.statusPedido.includes(StatusPedido.FATURADO)}
                        onChangeSituacao={changeSituacao}
                    />
                </Box>
                <Box>
                    <VendasTable
                        totalizadores={stateLocal.totalizadores}
                        totalizadoresError={stateLocal.totalizadoresError}
                        dadosFooter={stateLocal.dadosFooter}
                        isLoading={stateLocal.loading!}
                        onCancelaPedido={cancelaPedido}
                        onNavegaPDV={navegaPDV}
                        onAtualizarRelatorio={atualizaRelatorio}
                        onShowImprimirDFE={showImprimirDFE}
                        onShowImprimirOutros={showImprimirOutros}
                        onShowImprimirA4={showImprimirA4}
                        onImprimirPreVenda={imprimirPreVenda}
                        onNavegaPreVenda={navegaPreVenda}
                        onVisualizar={onVisualizar}
                        onImprimirCrediario={imprimirCrediario}
                    />
                    <Box sx={{
                        width: '100%',
                        marginTop: '20px',
                        justifyContent: 'space-between',
                    }}>
                        <Typography sx={{ color: "#000" }}>{"Legenda Fiscal"}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', maxHeight: '60px' }}>
                            <Box sx={{ display: 'flex', marginTop: '10px' }}>
                                {Object.keys(Assosicacao).map((e, i) => {
                                    return (
                                        <Box key={`associacao_${i}`}>
                                            <Box sx={{
                                                height: '20px',
                                                width: '60px',
                                                bgcolor: getAssociacao(e).rgb,
                                                border: '1px solid grey',
                                                borderRadius: '5px'
                                            }} >
                                            </Box>
                                            <Typography sx={{
                                                marginLeft: '10px',
                                                marginRight: '10px'
                                            }}>
                                                {captalizeString(getAssociacao(e).statusNotaFiscal)}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'flex-end', marginBottom: '20px' }}>
                                <CustomButton
                                    sx={{ marginLeft: '10px', minWidth: '80px' }}
                                    id="vendas_irpdv_btn"
                                    disabled={!caixaAberto ? true : false}
                                    onClick={() => { navigate("/registra-venda"); }}
                                    variant="contained"
                                    color="success"
                                    startIcon={<Icon className='fa-solid fa-arrow-right' />}>
                                    Ir para o PDV
                                </CustomButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box >
            {state.showImprimirDFE &&
                <CustomIFrame
                    url={state.url}
                    onClose={customIFrameClose}
                />
            }
            {(stateLocal.showLoadingNavegandoPDV || stateLocal.showLoadingComprovanteNaoFiscal) &&
                <CustomLoading />
            }
            {showComprovanteOutros.open && showComprovanteOutros.pedido &&
                <PdfComprovanteTemplate
                    descritivo={descritivo}
                    flowVendaTO={showComprovanteOutros.pedido}
                    onCloseComprovante={closeShowImprimirOutros}
                    showPopUp={false}
                    isPedidoRecuperado
                />
            }
            {stateLocal.showComprovanteFolhaA4 && flowVendaTORef.current !== null &&
                <ImprimirA4
                    dados={flowVendaTORef.current}
                    onGoBack={goBackA4}
                />
            }
            {stateLocal.showComprovantePreVenda &&
                <PreVendaPdf
                    cliente={comprovantePreVendaRef.current!.cliente ?? "Consumidor"}
                    emissao={comprovantePreVendaRef.current!.emissao}
                    numero={comprovantePreVendaRef.current!.numeroPedido.toString()}
                    onClose={onCloseComprovantePreVenda}
                    valorTotalLiquido={currencyOf(comprovantePreVendaRef.current!.valorPedido)}
                    vendedor={comprovantePreVendaRef.current!.vendedor ?? ""}
                    key="pre-venda-pdf-tela-venda"

                />
            }
            {stateLocal.showCrediario && flowVendaTORef.current &&
                <PdfComprovanteCrediario
                    key="pdf-comprovante-crediario"
                    flowVendaTO={flowVendaTORef.current}
                    emissaoNota={emissaoNotaRef}
                    onCloseComprovante={onCloseCrediario}
                />
            }
        </Container >
    );
};
export default Venda;

import { configureStore } from '@reduxjs/toolkit';
import { blockuiReducer } from './features/blockuiSlice';
import { caixaReducer } from './features/caixaSlice';
import { configuracoesReducer } from './features/configuracoes';
import { descontoFixoReducer } from './features/descontoFixoSlice';
import { esSelecionadoReducer } from './features/esSelecionadoSlice';
import { pagamentoReducer } from './features/pagamentoSlice';
import { stateReducer } from './features/stateSlice';
import { stepperReducer } from './features/stepperSlice';
import { tokenReducer } from './features/tokenSlice';
import { valePresenteReducer } from './features/valePresenteSlice';

export const store = configureStore({
        reducer: {
                token: tokenReducer,
                estabelecimentoSelecionado: esSelecionadoReducer,
                state: stateReducer,
                descontoFixo: descontoFixoReducer,
                stepper: stepperReducer,
                pagamento: pagamentoReducer,
                blockui: blockuiReducer,
                caixa: caixaReducer,
                configuracoes: configuracoesReducer,
                valePresente: valePresenteReducer,
        },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { Grid, Typography, Divider, Icon } from "@mui/material";
import { CustomButton } from "components/roundedbutton/CustomButton";

type BaseLayoutConfiguracoesProps = {
    onGoBack: () => void;
    onClickSalvar: () => void;
    children?: React.ReactElement | React.ReactElement[];
    title: string;
    confirmTitle?: string;
    showCancelConfirm?: boolean;
};

const BaseLayoutConfiguracoes = (props: BaseLayoutConfiguracoesProps) => {
    return (
        <Grid
            container
            spacing={2}
            alignContent={'flex-start'}
            sx={{
                height: '85%',
                position: 'relative'
            }}
        >
            <Grid item xs={12} >
                <Typography
                    sx={{
                        fontSize: 22,
                        marginBottom: '10px'
                    }}
                >
                    {props.title}
                </Typography>
                <Divider />
            </Grid>
            <Grid item xs={12} md={6}>
                {props.children}
            </Grid>
            <Grid item xs={0} md={6} />

            <Grid item xs={0} md={6} />
            <Grid item
                sx={{
                    position: 'absolute',
                    bottom: '15px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                }}
            >
                {props.showCancelConfirm &&
                    <>
                        <CustomButton
                            sx={{ minWidth: '80px' }}
                            id="voltar-configuracao-vale-presente-btn"
                            onClick={props.onGoBack}
                            variant="contained"
                            color="error"
                            startIcon={<Icon className='fa-solid fa-arrow-left' />}>
                            Voltar
                        </CustomButton>
                        <CustomButton
                            sx={{ minWidth: '80px' }}
                            id="voltar-configuracao-vale-presente-btn"
                            onClick={props.onClickSalvar}
                            variant="contained"
                            color="success"
                            startIcon={<Icon className='fa-solid fa-check' />}>
                            {props.confirmTitle ?? "Salvar"}
                        </CustomButton>
                    </>
                }
            </Grid>
        </Grid>
    );
};

export default BaseLayoutConfiguracoes;

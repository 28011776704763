import { Box, Card, Grid, Icon, Typography } from "@mui/material";
import CustomDoubleLeft from "pages/Venda/CustomDoubleLeft";
import CustomHandHoldingDollar from "pages/Venda/CustomHandHoldingDollar";
import CustomPercent from "pages/Venda/CustomPercent";
import CustomSettings from "pages/Venda/CustomSettings";
import CustomTableDollar from "pages/Venda/CustomTableDollar";
import { memo } from "react";
import { ConfiguracoesTelas, ConfiguracoesTitle } from "./Configuracoes.model";
import { CustomButton } from "components/roundedbutton/CustomButton";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

type TConfiguracoesDefaultBodyProps = {
    hasAcesso: boolean;
    handleActiveScreen: (screen: ConfiguracoesTelas, isEnabled: boolean) => void;
    isReady: boolean;
};

const cinza = '#666666';
const laranja = '#FF7F01';

const ConfiguracoesDefaultBody = (props: TConfiguracoesDefaultBodyProps) => {
    const navigate = useNavigate();
    const { caixaAberto } = useAppSelector(e => e.caixa.caixaAtual);

    return (
        <>
            <Box id="configuracoes-body">
                <Box sx={{
                    width: '100%',
                    borderTop: '1px solid rgba(0, 0, 0, .1)',
                    borderBottom: '1px solid rgba(0, 0, 0, .1)',
                    padding: '15px 0',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <Box id='vendaScreenBox' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <CustomSettings fontSize="45px" color="#666666" id="configurar-desconto-icon" />
                        <Typography id='configurar-desconto-title' sx={{ paddingLeft: '25px', fontSize: '45px', fontWeight: '600', color: '#666666', userSelect: 'none' }}>Configurações</Typography>
                    </Box>
                </Box>
                <Grid container spacing={5} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <Grid item xl={3} md={6} sm={6} sx={{ justifyContent: 'center', marginTop: '50px' }}>
                        <Card onClick={props.handleActiveScreen.bind(null, ConfiguracoesTelas.CONFIGURAR_DESCONTOS, true)}
                            variant="outlined"
                            id="card_configurar_desconto_caixa_habilitado"
                            className="card" sx={{
                                borderWidth: '2px',
                                borderColor: laranja,
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '20px',
                                boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                cursor: 'pointer',
                            }}>
                            <CustomPercent id="custom_percent_icon_configuracoes" color={cinza} fontSize="60px" style={{ marginBottom: '10px' }} />
                            <Typography id="configuracoes_desconto_title" sx={{ fontSize: '30px', color: cinza, textAlign: 'center', padding: '0 10px 0 10px', userSelect: 'none' }}>{ConfiguracoesTitle.DESCONTOS}</Typography>
                        </Card>
                    </Grid>


                    <Grid item xl={3} md={6} sm={6} sx={{ justifyContent: 'center', marginTop: '50px' }}>
                        <Card onClick={() => props.isReady && props.handleActiveScreen.bind(null, ConfiguracoesTelas.CONFIGURAR_MOVIMENTACOES_FISCAIS, false)}
                            variant="outlined"
                            id={props.hasAcesso && props.isReady ? 'card_configurar_movimentacao_fiscal_caixa_habilitado' : 'card_configurarmovimentacao_fiscal_desabilitado'}
                            className="card" sx={{
                                borderWidth: props.hasAcesso && props.isReady ? '2px' : 0,
                                borderColor: props.hasAcesso && props.isReady ? laranja : cinza,
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '20px',
                                boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                cursor: props.hasAcesso && props.isReady ? 'pointer' : 'auto',
                                bgcolor: props.hasAcesso && props.isReady ? '' : 'rgba(0, 0,0,0.07)'
                            }}>
                            <CustomHandHoldingDollar id="custom_hand_holding_dollar_icon_configuracoes" color={props.hasAcesso && props.isReady ? cinza : 'rgba(0, 0,0,0.25)'} fontSize="60px" style={{ marginBottom: '5px' }} />
                            <Typography id="configuracoes_movimentacoes_fiscais_title" sx={{ fontSize: '30px', color: props.hasAcesso && props.isReady ? cinza : 'rgba(0, 0,0,0.25)', textAlign: 'center', padding: '0 10px 0 10px', lineHeight: '30px', userSelect: 'none' }}>{ConfiguracoesTitle.MOVIMENTACOES_FISCAIS}</Typography>
                        </Card>
                    </Grid>

                    <Grid item xl={3} md={6} sm={6} sx={{ justifyContent: 'center', marginTop: '50px' }}>
                        <Card onClick={props.handleActiveScreen.bind(null, ConfiguracoesTelas.CONFIGURAR_COBRANCA_TABELAS_PRECO_E_DEPOSITO, true)}
                            variant="outlined"
                            id={props.hasAcesso && props.isReady ? 'card_configurar_tabela_preco_deposito_habilitado' : 'card_configurar_tabela_preco_deposito_desabilitado'}
                            className="card" sx={{
                                borderWidth: '2px',
                                borderColor: laranja,
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '20px',
                                boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                cursor: 'pointer',
                            }}>
                            <CustomTableDollar id="custom_table_dollar_icon_configuracoes" color={cinza} fontSize="60px" style={{ marginBottom: '5px' }} />
                            <Typography id="configuracoes_tabela_preco_deposito_title" sx={{ fontSize: '30px', color: cinza, textAlign: 'center', padding: '0 10px 0 10px', lineHeight: '30px', userSelect: 'none' }}>{ConfiguracoesTitle.COBRANCA_TABELAS_PRECO_E_DEPOSITO}</Typography>
                        </Card>
                    </Grid>

                    <Grid item xl={3} md={6} sm={6} sx={{ justifyContent: 'center', marginTop: '50px' }}>
                        <Card onClick={() => props.handleActiveScreen(ConfiguracoesTelas.CONFIGURAR_POLITICA_DEVOLUCAO, true)}
                            variant="outlined"
                            id={props.hasAcesso ? 'card_configurar_desconto_caixa_habilitado' : 'card_configurar_desconto_caixa_desabilitado'}
                            className="card"
                            sx={{
                                borderWidth: props.hasAcesso ? '2px' : 0,
                                borderColor: props.hasAcesso ? laranja : cinza,
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '20px',
                                boxShadow: '5px 5px 10px 0 rgba(0,0,0,0.15)',
                                cursor: props.hasAcesso ? 'pointer' : 'auto',
                                bgcolor: props.hasAcesso ? '' : 'rgba(0, 0,0,0.07)'
                            }}>
                            <CustomDoubleLeft
                                id="custom_double_left_icon_configuracoes"
                                color={props.hasAcesso ? cinza : 'rgba(0, 0,0,0.25)'}
                                fontSize="60px"
                                style={{ marginBottom: '5px' }}
                            />
                            <Typography
                                id="configuracoes_politica_devolucao_icon"
                                sx={{
                                    fontSize: '30px',
                                    color: props.hasAcesso ? cinza : 'rgba(0, 0,0,0.25)',
                                    textAlign: 'center',
                                    padding: '0 10px 0 10px',

                                    lineHeight: '30px',
                                    userSelect: 'none'
                                }}
                            >
                                {ConfiguracoesTitle.POLITICA_DEVOLUCAO}
                            </Typography>
                        </Card>
                    </Grid>

                </Grid>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'flex-end', marginBottom: '20px', height: '40%' }}>
                <CustomButton
                    sx={{ marginLeft: '10px', minWidth: '80px' }}
                    id="vendas_irpdv_btn"
                    onClick={() => { navigate("/registra-venda"); }}
                    variant="contained"
                    color="success"
                    disabled={!caixaAberto ? true : false}
                    startIcon={<Icon className='fa-solid fa-arrow-right' />}>
                    Ir para o PDV
                </CustomButton>
            </Box>
        </>
    );
};

export default memo(ConfiguracoesDefaultBody);

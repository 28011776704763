import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { FlowVendaTO } from "pages/registra-venda/registra-venda.model";
import { axiosRequest } from "../../axios/axios.config";
import { TCancelapedido } from "./Venda";
import { ItemRelatorioVendas, StatusPedidoName, TPedidoDialog } from "./vendas.model";

const VendaService = () => {
    const path = '/flow';
    const loginService = LoginService();

    return {
        getRelatorio(search: string, emissao: Array<string>,
            statusPedido: Array<StatusPedidoName>, situacoesFiscalSefaz: Array<string>): Promise<AxiosResponse<Array<ItemRelatorioVendas>>> {
            const params = {
                emissao: emissao.join(","),
                statusPedido: statusPedido.join(","),
                search,
                situacoesFiscalSefaz: situacoesFiscalSefaz.join(",")
            };
            return axiosRequest.get(`${path}/relatorio/pedido`, { headers: loginService.getHeaders(), params });
        },
        getPedido(pedidoUUID: string): Promise<AxiosResponse<TPedidoDialog>> {
            return axiosRequest.get(`${path}/findPedido/${pedidoUUID}`, { headers: loginService.getHeaders() });
        },
        getMotivos(): Promise<AxiosResponse<Array<any>>> {
            return axiosRequest.get(`${path}/motivos`, { headers: loginService.getHeaders() });
        },
        cancelaPedido(data: TCancelapedido): Promise<AxiosResponse<any>> {
            return axiosRequest.put(`${path}/cancelapedido`, data, { headers: loginService.getHeaders() });
        },
        carregaPedido(uuid: string): Promise<AxiosResponse<FlowVendaTO>> {
            return axiosRequest.get(`${path}/carregapedido/${uuid}`, { headers: loginService.getHeaders() });
        },
        cancelarDFe(chaveAcesso: string, justificativa: string, tipoNotaFiscal: string): Promise<AxiosResponse<string>> {
            return axiosRequest.put(`${path}/cancelardfe`, { chaveAcesso, justificativa, tipoNotaFiscal }, { headers: loginService.getHeaders() });
        },
        excluirNota(chaveAcesso: string): Promise<AxiosResponse<string>> {
            return axiosRequest.put(`${path}/excluirnota`, { chaveAcesso }, { headers: loginService.getHeaders() });
        },
        baixarXML(chaveAcesso: string): Promise<AxiosResponse<string>> {
            return axiosRequest.get(`${path}/baixarxml/${chaveAcesso}`, { headers: loginService.getHeaders(), responseType: 'blob' });
        },
        getUrlDanfe(notaFiscalUUID: string): Promise<AxiosResponse<string>> {
            return axiosRequest.get(`${path}/imprimirdanfe/${notaFiscalUUID}`, { headers: loginService.getHeaders() });
        },
        editarVendedor(vendedorUUID: string, pedidoUUID: string): Promise<AxiosResponse<void>> {
            return axiosRequest.put(`${path}/editarvendedor`, { vendedorUUID, pedidoUUID }, { headers: loginService.getHeaders() });
        },
    };
};

export default VendaService;

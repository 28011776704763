import { TableRow, TableCell, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { memo } from "react";
import { NumericFormat } from "react-number-format";
import { currencyOf } from "utils/util";
import { TdadosGeneric } from "../pagamento.model";

type TLinhaDadosGeneric = {
    index: number;
    row: TdadosGeneric;
    hasError: boolean;
    handleChangeValor: (index: number, valor: number) => void;
    handleDatavencimento: (index: number, vencimentoDisplay: string) => void;
};


const LinhasDadosGeneric = (props: TLinhaDadosGeneric) => {
    return (
        <>
            <TableRow key={props.index} id={`linha-dado-boleto-${props.index}`}>
                <TableCell align="center" >
                    <NumericFormat
                        decimalScale={2}
                        fixedDecimalScale
                        defaultValue={0}
                        allowedDecimalSeparators={[',']}
                        customInput={TextField}
                        decimalSeparator=","
                        prefix={'R$ '}
                        thousandsGroupStyle="thousand"
                        thousandSeparator="."
                        variant="standard"
                        allowNegative={false}
                        value={currencyOf(props.row.valor)}
                        error={props.hasError}
                        onValueChange={({ floatValue }) => { props.handleChangeValor(props.index, floatValue ?? 0); }}
                        label='Valor'
                        inputProps={{
                            style: { color: props.hasError ? '#c62828' : '', },
                        }}
                        id={`valor_${props.index}`}
                    />
                </TableCell>

                <TableCell >
                    <DatePicker
                        slotProps={{
                            textField: {
                                id: `vencimento_${props.index}`,
                                variant: 'standard'
                            }
                        }}
                        label="Vencimento"
                        format='DD/MM/YYYY'
                        onChange={(e: any) => { props.handleDatavencimento(props.index, dayjs(e?.$d).toString() ?? new Date().toString()); }}
                        value={dayjs(props.row.vencimentoDisplay)}

                        minDate={dayjs(new Date())}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default memo(LinhasDadosGeneric);

import { Text, View } from "@react-pdf/renderer";
import { memo } from "react";
import { currencyOf, roundHalfToEven } from "utils/util";
import { IRenderComprovanteRow } from "./comprovante-nota-fiscal-outros.model";

const RenderComprovanteRow = (props: IRenderComprovanteRow) => {
    const produto = props.elemento.produto;
    return (
        <View key={`comprovante_row_${props.index}_${produto.codigoSku}}`}
            id={`comprovante_row_${props.index}_${produto.codigoSku}}`}
            style={{ marginTop: props.index !== 0 ? '3px' : '0px' }}>
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                <View style={{ display: 'flex', justifyContent: 'center', }}>
                    <Text style={{ fontSize: '8px', width: '100px', minWidth: '100px' }}>{produto.codigoSku}</Text>
                </View>
                <View style={{ width: '100%' }}>
                    <Text style={{ fontSize: '8px', width: '100%' }}>{produto.nome + ' / ' + produto.cor + ' / ' + produto.tamanho}</Text>
                </View>
            </View>
            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: '8px' }}>{props.elemento.quantidade.toFixed(4).replace('.', ',')}</Text>
                <Text style={{ fontSize: '8px', paddingLeft: '23px' }}>{currencyOf(produto.preco)}</Text>
                <Text style={{ fontSize: '8px' }}>{roundHalfToEven(props.elemento.itemDesconto.percentualDesconto, 2)}%</Text>
                <Text style={{ fontSize: '8px' }}>{currencyOf(props.elemento.valorTotal)} </Text>
            </View>
        </View>
    );
};

export default memo(RenderComprovanteRow);

import { AxiosResponse } from "axios";
import { IAbstractEnum, IAbstractModel } from "model/abstract.model";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";
import { Endereco, Pessoa } from "./pessoa.model";

export const CadastroPessoaService = () => {
    const path = '/pessoa';
    const loginService = LoginService();

    const getEnumAC = (enumname: string) => {
        return axiosRequest.get(`${path}/acenum/${enumname}`, { headers: loginService.getHeaders() });
    };

    return {
        getTipoPessoaAC(): Promise<AxiosResponse<Array<IAbstractEnum>>> {
            return getEnumAC('TipoPessoa');
        },
        getPapelAC(): Promise<AxiosResponse<Array<IAbstractEnum>>> {
            return getEnumAC('Papel');
        },
        getTipoContribuinte(): Promise<AxiosResponse<Array<IAbstractEnum>>> {
            return getEnumAC('TipoContribuinte');
        },
        getMunicipios(toSearch: string): Promise<AxiosResponse<any>> {
            const nQuery = {
                first: 0,
                pageSize: 50,
                status: "A",
                isAdvanced: true,
                advancedNQuery: {
                    logicalOperator: "OR",
                    alias: "obj",
                    subQuerys: [
                        {
                            logicalOperator: "SIMPLE",
                            criteria: {
                                field: "nome",
                                comparisonOperator: "CONTAINS",
                                value: toSearch,
                                fieldFn: "to_char(obj.nome)"
                            },
                            alias: null
                        },
                        {
                            logicalOperator: "SIMPLE",
                            criteria: {
                                field: "uf",
                                comparisonOperator: "CONTAINS",
                                value: toSearch,
                                fieldFn: "to_char(obj.uf)"
                            },
                            alias: null
                        }
                    ]
                },
                fieldsToSimpleMatch: [],
                simpleMatch: "",
                orderBy: "nome ASC"
            };
            return axiosRequest.post('/municipio/acdescritivo', nQuery, { headers: loginService.getHeaders() });
        },
        findCep(cep: string): Promise<AxiosResponse<{ endereco: Endereco, municipio: IAbstractModel; }>> {
            return axiosRequest.get(`/endereco/cep/${cep}`, { headers: loginService.getHeaders() });
        },
        searchCnpj(cnpj: string): Promise<AxiosResponse<Pessoa>> {
            return axiosRequest.get(`${path}/cnpj/${cnpj}`, { headers: loginService.getHeaders() });
        },
        getExistidentification(identify: string): Promise<AxiosResponse<boolean>> {
            return axiosRequest.get(`${path}/existecpfcnpj/${identify}`, { headers: loginService.getHeaders() });
        },
        save(pessoa: any): Promise<AxiosResponse<any>> {
            return axiosRequest.put(`${path}`, pessoa, { headers: loginService.getHeaders() });
        },
        getPessoa(uuid: string): Promise<AxiosResponse<Pessoa>> {
            return axiosRequest.get(`${path}/${uuid}`, { headers: loginService.getHeaders() });
        }
    };
};

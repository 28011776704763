import { useEffect, useState } from "react";
import { equalsObj } from "utils/util";

export type AbstractControl = {
    disabled?: boolean;
    value: any;
    errors?: {};
    validators?: Array<(control: AbstractControl) => {} | null>;
};

export type FormGroup<T> = {
    controls: Array<FormControl<T>>;
    validators?: Array<(control: AbstractControl) => {} | null>;
    errors?: {};
    disabled?: boolean;
};

export type FormControl<T> = {
    inputName: keyof T;
    value: any;
    nonNullable?: boolean;
    disabled?: boolean;
    validators?: Array<(control: AbstractControl) => {} | null>;
    errors?: {};
};

export const useFormValidator = <T extends {}>(initialState: FormGroup<T>) => {
    type K = keyof T;

    const [form, setState] = useState(initialState);

    useEffect(() => {
        validateAllControls(form);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        validateForm(form);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const getControl = (inputName?: K): FormControl<T> | FormGroup<T> | undefined => {
        if (inputName !== undefined) {
            return form.controls.find(s => s.inputName === inputName);
        }
        return form;
    };

    const getControlAllErrors = (inputName?: K): {} | undefined => {
        if (inputName !== undefined) {
            return getControl(inputName)?.errors;
        }
        return form.errors;
    };

    const hasError = (errorName: string, inputName?: K): boolean | undefined => {
        if (inputName !== undefined) {
            const control = getControl(inputName)?.errors;
            return control === undefined ? control : (control as any)[errorName];
        }

        return form.errors?.[errorName as keyof typeof form.errors];
    };

    const setControlValue = (inputName: K, value: any) => {
        setState(prev => {
            const controlsCopy = prev.controls.slice();
            const find = controlsCopy.find(s => s.inputName === inputName);
            if (!find) {
                return prev;
            }

            find.value = value;
            const newControls = controlsCopy.filter(s => s.inputName !== inputName);
            validateControl(find);
            newControls.push(find);
            return {
                ...prev,
                controls: newControls,
            };
        });
    };

    const setFormValue = (value: T) => {
        for (const key in value) {
            if (Object.prototype.hasOwnProperty.call(value, key)) {
                setControlValue(key, value[key]);
            }
        }
    };

    const getRawValue = (inputName?: K) => {
        if (inputName !== undefined) {
            return (getControl(inputName) as FormControl<T> | undefined)?.value;
        }

        const toReturn = {};
        form.controls.forEach(s => {
            (toReturn as any)[s.inputName] = s.value;
        });

        return toReturn;
    };

    const validateAllControls = (form: FormGroup<T>) => {
        form.controls.forEach(control => {
            validateControl(control);
        });
    };

    const validateControl = (control: AbstractControl) => {
        control.errors = {};
        control.validators?.forEach(validator => {
            const error = validator(control);
            if (error != null) {
                Object.keys(error)
                    .forEach(err => {
                        (control.errors as any)[err] = true;
                    });
            }
        });
    };

    const validateForm = (form: FormGroup<T>) => {
        form.errors = {};
        form.validators?.forEach(validator => {
            const abstractForm = { ...form, value: getRawValue() };
            const error = validator(abstractForm);
            if (error != null) {
                Object.keys(error)
                    .forEach(err => {
                        (form.errors as any)[err] = true;
                    });
            }
        });
    };

    const resetControl = (inputName: K, value?: T[K]): void => {
        setState(prev => {
            const controlsCopy = prev.controls.slice();
            const find = controlsCopy.find(s => s.inputName === inputName);
            if (!find) {
                return prev;
            }

            if (value) {
                find.value = value;
            } else if (find.nonNullable) {
                const initialStateFind =
                    initialState.controls.find(c => c.inputName === find.inputName);
                find.value = initialStateFind?.value ?? null;
            } else {
                find.value = null;
            }

            const newControls = controlsCopy.filter(s => s.inputName !== inputName);
            validateControl(find);
            newControls.push(find);
            return {
                ...prev,
                controls: newControls,
            };
        });
    };

    const resetForm = (): void => {
        setState(prev => {
            validateAllControls(initialState);
            return initialState;
        });
    };

    const isDisabledControl = (inputName?: K) => {
        return inputName ? getControl(inputName)?.disabled : form.disabled;
    };

    const disableControl = (inputName?: K) => {
        if (inputName) {
            changeControlDisabled(inputName, true);
        } else {
            setState(prev => ({ ...prev, disabled: true }));
        }
    };

    const enableControl = (inputName?: K) => {
        if (inputName) {
            changeControlDisabled(inputName, false);
        } else {
            setState(prev => ({ ...prev, disabled: false }));
        }
    };

    const changeControlDisabled = (inputName: K, value: boolean) => {
        setState(prev => {
            const controlsCopy = prev.controls.slice();
            const find = controlsCopy.find(s => s.inputName === inputName);
            if (!find) {
                return prev;
            }
            find.disabled = value;
            const newControls = controlsCopy.filter(s => s.inputName !== inputName);
            newControls.push(find);
            return {
                ...prev,
                controls: newControls,
            };
        });
    };

    const formIsValid = (): boolean => {
        const everyControlsIsValid = form.controls.every(s => equalsObj(s.errors, {}));
        const formIsValid = equalsObj(form.errors ?? {}, {});
        return everyControlsIsValid && formIsValid;
    };

    return {
        form,
        getControl,
        getRawValue,
        setFormValue,
        setControlValue,
        hasError,
        getControlAllErrors,
        resetControl,
        resetForm,
        formIsValid,
        isDisabledControl,
        disableControl,
        enableControl,
    };
};

export const useValidators = () => {
    const required = ({ value }: AbstractControl): {} | null => {
        if (value === null || value === undefined) {
            return { required: true };
        }
        let conditionValid = false;
        if (typeof value === 'number') {
            conditionValid = value > 0;
        } else if (value.uuid) {
            conditionValid = !!value.uuid;
        } else {
            conditionValid = value.length > 0;
        }
        return conditionValid ? null : { required: true };
    };

    const maxSize = (maxSize: number) => {
        return ({ value }: AbstractControl): {} | null => {
            const labelError = `maxSize${maxSize}`;
            return value?.length > maxSize ? { [labelError]: true } : null;
        };
    };

    const minSize = (minSize: number) => {
        return ({ value }: AbstractControl): {} | null => {
            const labelError = `minSize${minSize}`;
            return value?.length < minSize ? { [labelError]: true } : null;
        };
    };

    return { required, maxSize, minSize };
};

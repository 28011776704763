import { Backdrop, CircularProgress } from "@mui/material";
import LinearFinalizaVenda from "pages/pagamento/LinearFinalizaVenda";
import { Tipo } from "redux/features/blockuiSlice";
import { useAppSelector } from "redux/hooks";

const BlockUI = () => {
    const { loading, tipo, progress, message, zIndexCustom } = useAppSelector(s => s.blockui);
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + (zIndexCustom ?? 1) }}
            open={loading}
        >
            {tipo === Tipo.CIRCULAR &&
                <CircularProgress sx={{ color: 'var(--laranja)' }} />
            }
            {tipo === Tipo.LINEAR &&
                <LinearFinalizaVenda progress={progress!} message={message!} />
            }
        </Backdrop>
    );
};

export default BlockUI;

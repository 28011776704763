import { AxiosResponse } from "axios";
import LoginService from "pages/login/login.service";
import { axiosRequest } from "../../axios/axios.config";

const BarcodeService = () => {
    const path = '/generatebarcode';
    const loginService = LoginService();

    return {
        getBarcode: (code: String): Promise<AxiosResponse<any>> => {
            const configs = {
                code: code,
                width: 0,
                height: 100
            };
            return axiosRequest.post(`${path}/code128`, configs, { headers: loginService.getHeaders(), responseType: 'blob' });
        }
    };
};

export default BarcodeService;

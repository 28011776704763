import { Card, FormControl, FormHelperText, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useAppSelector } from 'redux/hooks';
import { currencyOf, roundHalfToEven } from 'utils/util';
import { FormaPagamentoDialogContentProps } from '../formapagamento.model';

const GenericDialogContent = ({ error, valorRecebido, isNew }: FormaPagamentoDialogContentProps) => {
    const { hasError, setHasError } = error;
    const { dadosPagamento, setDadosPagamento } = valorRecebido;
    const { faltaReceber } = useAppSelector(s => s.pagamento);
    const [valueDiff, setValueDiff] = useState(dadosPagamento.valorRecebido);

    useEffect(() => {
        if (isNew) {
            setDadosPagamento(prev => ({ valorRecebido: faltaReceber, infoAdicional: undefined }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (valueDiff !== dadosPagamento.valorRecebido)
            setValueDiff(dadosPagamento.valorRecebido);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dadosPagamento.valorRecebido]);

    const onChangeValorRecebido = (value: number = 0) => {
        if (roundHalfToEven(value, 2) > roundHalfToEven(faltaReceber, 2)) {
            setHasError(prev => true);
        } else {
            setHasError(prev => false);
        }
        setDadosPagamento(prev => ({ ...prev, valorRecebido: value }));
    };

    return (
        <>
            <FormControl
                fullWidth
                variant="standard"
                sx={{ width: '15rem' }}
                error={hasError}
            >

                <NumericFormat
                    id="generic_forma_pagamento_div"
                    value={dadosPagamento.valorRecebido}
                    onValueChange={({ floatValue }) => onChangeValorRecebido(floatValue)}
                    error={hasError}
                    decimalScale={2}
                    fixedDecimalScale
                    defaultValue={0}
                    allowedDecimalSeparators={[',']}
                    customInput={TextField}
                    decimalSeparator=","
                    prefix={'R$ '}
                    thousandsGroupStyle="thousand"
                    thousandSeparator="."
                    variant="standard"
                    allowNegative={false}
                    inputProps={{
                        id: "generic_forma_pagamento_value",
                        style: {
                            textAlign: 'center',
                            fontSize: '24px',
                            color: hasError ? 'var(--error)' : '#df6e0b',
                            fontWeight: 700,
                            fontFamily: 'Work Sans, sans-serif'
                        }
                    }}
                />

                {hasError &&
                    <FormHelperText
                        sx={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        O valor não pode ser maior que a venda :(
                    </FormHelperText>}
                {!hasError && <FormHelperText> </FormHelperText>}
            </FormControl>

            <Card sx={{ borderRadius: 5, textAlign: 'center', height: '100px', aspectRatio: '7/3', padding: '0 !important' }} className="card-totalizador-pedido">
                <h2 id="generic_forma_pagamento_value" className="valor-totalizador">{valueDiff <= faltaReceber ? currencyOf(faltaReceber - valueDiff) : currencyOf(faltaReceber)}</h2>
                <h4 id="generic_forma_pagamento_titulo" className="label-totalizador">Falta receber</h4>
            </Card>
        </>
    );
};

export default GenericDialogContent;

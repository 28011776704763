import BarcodeService from 'components/barcode/barcode.service';
import TelaFormasPagamentoComponent from 'components/telaformaspagamento/TelaFormasPagamentoComponent';
import { useSnackbarCustom } from 'hooks/useSnackCustom';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tipo, completeLoading, loadingWithOpt, resetLoading } from 'redux/features/blockuiSlice';
import { resetRows } from 'redux/features/pagamentoSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { currencyOf } from 'utils/util';
import ValePresentePdf from './ValePresentePdf';
import { ValePresenteTO } from './vale-presente.model';
import ValePresenteService from './vale-presente.service';

const ValePresenteTelaPagamento = () => {
    const service = ValePresenteService();

    const canEscape = useRef<boolean>(true);
    const faltaReceberRef = useRef<number>(0);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { addError, addStack } = useSnackbarCustom();
    const barcodeService = BarcodeService();

    const { valePresente } = useAppSelector(s => s.valePresente);
    const pagamento = useAppSelector(s => s.pagamento);

    const [valeState, setValeState] = useState({ url: '', open: false, dados: { code: '', valor: '', estabelecimento: '', cliente: '', destinatario: '', validade: '' } });
    useEffect(() => {
        return () => {
            if (valeState.url) {
                URL.revokeObjectURL(valeState.url);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const resetAll = (shouldNavigate: boolean) => {
        dispatch(resetRows());
        dispatch(completeLoading());
        if (shouldNavigate) {
            navigate('/registra-venda');
        }
    };

    const onClickConfirmar = () => {
        canEscape.current = false;
        if (faltaReceberRef.current > 0) {
            addError({ message: 'Existe saldo a receber, verifique!', closeable: true, persist: false });
            return;
        }

        dispatch(loadingWithOpt({ tipo: Tipo.CIRCULAR, loading: true }));

        const toSet: ValePresenteTO = {
            ...valePresente!,
            itensPagamento: pagamento.tableRows,
        };

        service.gerarValePresente(toSet)
            .then(({ data }) => {
                dispatch(completeLoading());
                const {
                    cliente,
                    code,
                    destinatario,
                    estabelecimento,
                    validade,
                    valor
                } = data;
                barcodeService.getBarcode(code)
                    .then(({ data }) => {
                        const url = URL.createObjectURL(data);
                        setValeState({
                            open: true,
                            url,
                            dados: {
                                code,
                                valor: currencyOf(valor),
                                estabelecimento,
                                cliente,
                                destinatario,
                                validade: new Date(validade).toLocaleDateString('pt-BR'),
                            }
                        });
                    });
            })
            .catch(err => {
                const data = err.response.data;
                console.log(err);
                if (data.code === "BusinessException" && data.extra) {
                    const erros = data.extra.map((e: string) => {
                        return {
                            message: e,
                            persist: true,
                            severity: 'error',
                            preventDuplicate: false,
                            closeable: true,
                        };
                    });
                    setTimeout(() => {
                        addStack(erros);
                    }, 200);
                } else {
                    setTimeout(() => {
                        addError({
                            message: 'Ocorreu um erro inesperado. Se o erro persistir, entre em contato com o suporte',
                            persist: true,
                            severity: 'error',
                            preventDuplicate: false,
                            closeable: true,
                        });
                    }, 200);
                }
            })
            .finally(() => {
                setTimeout(() => {
                    dispatch(resetLoading());
                    canEscape.current = true;
                }, 200);
            });
    };

    const onCloseValePresentePDF = () => {
        setValeState(prev => ({ ...prev, open: false }));
        resetAll(true);
    };

    return (
        <TelaFormasPagamentoComponent
            refs={{
                faltaReceberRef,
                canEscape,
            }}
            footer={{
                onClickAvancar: onClickConfirmar,
                onClickCancelar: () => resetAll(false)
            }}
            handleKeyPress={() => { }}
            valorTotalLiquido={valePresente?.valor ?? 0}
        >
            {valeState.open &&
                <ValePresentePdf
                    barCodeUrl={valeState.url}
                    onClose={onCloseValePresentePDF}
                    dados={valeState.dados}
                />
            }
        </TelaFormasPagamentoComponent >
    );
};

export default ValePresenteTelaPagamento;

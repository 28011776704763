import { Tooltip, Typography } from "@mui/material";

interface CustomToolTipsProps {
    id: string,
    title?: string,
    position: string,
    bottom?: string,
    top?: string,
    left?: string,
    right?: string,
    bgColor?: string,
    color: string,
    height: string,
    width?: string,
    maxWidth?: string,
    zIndex?: number,
    display?: string,
    justifyContent?: string,
    alignItems?: string,
    borderRadius?: string,
    isVisible: boolean;
    trueText: string,
    falseText: string,
    autoCapitalize: boolean,
    transform?: string;
    padding?: string;
}


const CustomToolTips = (props: CustomToolTipsProps) => {
    return (
        <Tooltip
            title={props.title}
            id={props.id}
            sx={{
                position: props.position,
                bottom: props.bottom ?? 'auto',
                left: props.left ?? 'auto',
                top: props.top ?? 'auto',
                bgcolor: props.bgColor ?? '#808080',
                color: props.color,
                height: props.height,
                width: props.width ?? "auto",
                maxWidth: props.maxWidth ?? "auto",
                zIndex: props.zIndex || 1,
                display: props.display || 'block',
                justifyContent: props.justifyContent || 'center',
                alignItems: props.alignItems || 'center',
                borderRadius: props.borderRadius || '0px',
                fontWeight: 'bold',
                padding: props.padding ?? '10px',
                transform: props.transform ?? 'none'
            }}>
            {props.isVisible
                ? <Typography style={{ fontSize: '14px', textTransform: props.autoCapitalize ? "capitalize" : 'none' }}>{props.trueText}</Typography>
                : <Typography style={{ fontSize: '14px' }}>{props.falseText}</Typography>}
        </Tooltip>
    );
};

export default CustomToolTips;

import { Container } from "@mui/material";
import { useCallback, useState } from "react";
import ConfiguracoesDefaultBody from "./ConfiguracoesDefaultBody";
import ConfigurarDescontos from "./ConfigurarDescontos";
import { ConfiguracoesTelas, ConfiguracoesTitle, TConfiguracoesInitialState } from "./Configuracoes.model";
import { useAppSelector } from "redux/hooks";
import { useSnackbarCustom } from "hooks/useSnackCustom";
import ConfigurarCobrancaTabelaDeposito from "./ConfigurarCobrancaTabelaDeposito";
import ConfigurarPoliticaDevolucaoValePresente from "./configuracaovalepresente/ConfigurarPoliticaDevolucaoValePresente";

const isReady = false;

const initialState: TConfiguracoesInitialState = {
    telaAtiva: ConfiguracoesTelas.TELA_PRINCIPAL
};


const Configuracoes = () => {
    const ROLES: Array<string> = useAppSelector(e => e.token.KEY_CLOAK_ROLES);
    const userHasAcesso: boolean = ROLES.includes("ROLE_SUPER") || ROLES.includes("ROLE_ADMIN");
    const { addError } = useSnackbarCustom();
    const [stateLocal, setStateLocal] = useState(initialState);


    const getScreenTitle = useCallback((screen: ConfiguracoesTelas) => {
        switch (screen) {
            case ConfiguracoesTelas.CONFIGURAR_DESCONTOS:
                return ConfiguracoesTitle.DESCONTOS;
            case ConfiguracoesTelas.CONFIGURAR_MOVIMENTACOES_FISCAIS:
                return ConfiguracoesTitle.MOVIMENTACOES_FISCAIS;
            case ConfiguracoesTelas.CONFIGURAR_COBRANCA_TABELAS_PRECO_E_DEPOSITO:
                return ConfiguracoesTitle.COBRANCA_TABELAS_PRECO_E_DEPOSITO;
            case ConfiguracoesTelas.CONFIGURAR_POLITICA_DEVOLUCAO:
                return ConfiguracoesTitle.POLITICA_DEVOLUCAO;
        }
    }, []);

    const handleNavigation = useCallback((screen: ConfiguracoesTelas, enabled: boolean) => {
        if (enabled && userHasAcesso) { return setStateLocal(prevState => ({ ...prevState, telaAtiva: screen })); }
        return addError({ message: `Usuário não possui acesso para ${getScreenTitle(screen)}`, closeable: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goBack = useCallback(() => {
        setStateLocal(prevState => ({ ...prevState, telaAtiva: ConfiguracoesTelas.TELA_PRINCIPAL }));
    }, []);

    return (
        <Container maxWidth="xl" id="configuracoes-container"
            sx={{ width: '100vw', height: 'calc(100vh - 84px)' }}
        >
            {stateLocal.telaAtiva === ConfiguracoesTelas.TELA_PRINCIPAL &&
                <ConfiguracoesDefaultBody
                    handleActiveScreen={handleNavigation}
                    hasAcesso={userHasAcesso}
                    isReady={isReady}
                />
            }
            {stateLocal.telaAtiva === ConfiguracoesTelas.CONFIGURAR_DESCONTOS &&
                <ConfigurarDescontos
                    onGoBack={goBack}
                />
            }
            {stateLocal.telaAtiva === ConfiguracoesTelas.CONFIGURAR_COBRANCA_TABELAS_PRECO_E_DEPOSITO &&
                <ConfigurarCobrancaTabelaDeposito
                    onGoBack={goBack}
                />
            }

            {stateLocal.telaAtiva === ConfiguracoesTelas.CONFIGURAR_POLITICA_DEVOLUCAO &&
                <ConfigurarPoliticaDevolucaoValePresente
                    onGoBack={goBack}
                />
            }
        </Container>
    );
};

export default Configuracoes;
